import React from 'react';
import { Fade, Dialog as MuiDialog } from '@mui/material';
import type { DialogProps } from '@mui/material';

/**
 * StableDialog Props
 * Extends MUI Dialog props with optional vertical position property
 */
export interface StableDialogProps extends DialogProps {
  /**
   * Control the vertical position of the dialog
   * @default '200px'
   */
  verticalPosition?: string | number;
}

/**
 * StableDialog Component
 *
 * A version of MUI Dialog that maintains stable positioning, particularly when virtual keyboards
 * appear on mobile devices in PWA contexts.
 *
 * This component addresses a common issue where dialogs can "jump around" when the mobile virtual keyboard
 * appears, making it difficult to interact with form elements. The solution involves:
 *
 * 1. Using fixed positioning to anchor the dialog at a specific vertical position
 * 2. Disabling scroll lock to prevent the dialog from moving with content
 * 3. Maintaining the vertical position when focus events occur (like activating an input)
 *
 * This is especially important for PWA installations where viewport adjustment behaviors differ
 * from regular in-browser experiences.
 *
 * @param props - Component properties extending MUI DialogProps
 * @returns React Element
 */
const StableDialog: React.FC<StableDialogProps> = ({ children, verticalPosition = '200px', ...props }) => {
  // Extract PaperProps to migrate them to slotProps.paper
  const { PaperProps, ...otherProps } = props;

  return (
    <MuiDialog
      {...otherProps}
      disableScrollLock={true}
      slots={{
        transition: Fade,
      }}
      slotProps={{
        transition: {
          timeout: 400,
        },
        paper: {
          ...PaperProps,
          sx: {
            ...(PaperProps?.sx || {}),
            borderRadius: 2,
            boxShadow: (theme) => theme.shadows[10],
          },
        },
      }}
      sx={{
        '& .MuiDialog-container > .MuiDialog-paper': {
          position: 'fixed',
          top: verticalPosition,
        },
        ...otherProps.sx,
      }}
    >
      {children}
    </MuiDialog>
  );
};

export default StableDialog;
