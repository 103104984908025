import React, { useState, useEffect, useRef, useMemo, useContext, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Button,
  Avatar,
  Stack,
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  type ButtonProps,
  DialogContent,
  DialogTitle,
  Dialog,
  CircularProgress,
  ToggleButton,
  Breadcrumbs,
  Link,
  DialogContentText,
  TextField,
  DialogActions,
  Checkbox,
  Tooltip,
  Zoom,
  Fade,
  LinearProgress,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Refresh as RefreshIcon,
  CloudOff as CloudOffIcon,
  Brightness6 as Brightness6Icon,
  LightMode as LightModeIcon,
  DarkMode as DarkModeIcon,
  ArrowUpward as ArrowUpwardIcon,
  CreateNewFolder as CreateNewFolderIcon,
  Home as HomeIcon,
  Category as CategoryIcon,
  Delete as DeleteIcon,
  DriveFileMove as DriveFileMoveIcon,
  Clear as ClearIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  SelectAll as SelectAllIcon,
} from '@mui/icons-material';
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, PrevButton, NextButton } from './CarouselButtons';
import {
  getBooks,
  updateBooksCache,
  logoutUser,
  fetchFollowing,
  getBookProgressAndNotes,
  getStreaks,
  aggregateWeeklyTime,
  addBookFromFile,
  replaceBookFile,
  deleteBook,
  upgrade,
  getTheme,
  getMisc,
  updateCurrentUser,
  updateFollowing,
  updateMisc,
  updateNotesStatus,
  getBook,
  resetBook,
  addTranslatedVersion,
  organizeBooks,
  updateBook,
  getCurrentUser,
} from '../utils/books';
import type { BookMeta, Group, GroupCollection } from '../types/book';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { saveFile, getDB } from '../utils/indexedDB';
import ReadingChart from './ReadingChart';
import dayjs from 'dayjs';
import { currentStoreVersion, LANGUAGES } from '../config';
import { debounce } from '../utils/core';
import { version } from '../../package.json';
import { AppContext } from '../context/AppContext';
import { Changelog } from './Changelog';
import localforage from 'localforage';
import { TranslationsContext, useTranslation } from '../utils/i18n';
import withTooltip from './withTooltip';
import {
  createGroup,
  updateGroup,
  deleteGroup,
  getAllGroupsSorted,
  migrateShelvesToGroups,
  getRootGroup,
  getAllBooksInGroup,
} from '../utils/groups';
import BookCover from './BookCover';
import GroupCover from './GroupCover';
import { defaultOrganizationInstruction } from '../utils/defaults';
import MoveToDialog from './MoveToDialog';
import StableDialog from './StableDialog';
import HelpTutorialButton from './HelpTutorialButton';

const AddBookButton = withTooltip<ButtonProps>(Button, 'ADD_BOOK_BUTTON_HELP');
const LogoutButton = withTooltip<ButtonProps>(Button, 'LOGOUT_BUTTON_HELP');
const BackupButton = withTooltip<ButtonProps>(Button, 'BACKUP_BUTTON_HELP');
const RestoreButton = withTooltip<ButtonProps>(Button, 'RESTORE_BUTTON_HELP');
const VersionButton = withTooltip<ButtonProps>(Button, 'VERSION_BUTTON_HELP');
const LanguageButton = withTooltip<ButtonProps>(Button, 'LANGUAGE_BUTTON_HELP');
const FeedbackButton = withTooltip<ButtonProps>(Button, 'FEEDBACK_BUTTON_TOOLTIP');
const HelpButton = withTooltip<ButtonProps>(HelpTutorialButton, 'HELP_BUTTON_TOOLTIP');

// Add back the memoized component reference
const MemoizedBookCover = memo(BookCover);

const BookList: React.FC = () => {
  const { currentLanguage, isLoading, setLanguage } = useContext(TranslationsContext);
  const t = useTranslation();

  const { setSnackbarMessage, checkAppVersion, isOnline, localForageLoaded, prefs, changePrefs, user, setUser } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [replaceFileId, setReplaceFileId] = useState<string | null>(null);
  const progressInfoRef = useRef<{ [hash: string]: any }>({});
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const translatedFileInputRef = useRef<HTMLInputElement | null>(null);
  const [changelogOpen, setChangelogOpen] = useState(false);
  const [activeGroup, setActiveGroup] = useState<Group | null>(null);
  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const [editGroupOpen, setEditGroupOpen] = useState(false);
  const [editingGroupId, setEditingGroupId] = useState<string | null>(null);
  const [books, setBooks] = useState<{ [id: string]: BookMeta }>({});
  const translatedVersionIdRef = useRef<string>('');
  const renameGroupInputRef = useRef<HTMLInputElement | null>(null);
  const createGroupInputRef = useRef<HTMLInputElement | null>(null);
  const organizationInstructionInputRef = useRef<HTMLInputElement | null>(null);

  // Embla Carousel setup
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [streaks, setStreaks] = useState<{
    [id: string]: { days: number; today: boolean };
  }>({});
  const [weeklyTime, setWeeklyTime] = useState<{ label: string; value: number }[]>([]);
  const [streakTime, setStreakTime] = useState<{ label: string; value: number }[]>([]);
  const [monthlyTime, setMonthlyTime] = useState<{ label: string; value: number }[]>([]);

  const { cmd } = useParams<{ cmd: string }>(); // Assuming dynamic routing based on book ID

  const [canScrollPrev, _setCanScrollPrev] = useState(false);
  const [canScrollNext, _setCanScrollNext] = useState(false);

  // For the language picker modal
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(currentLanguage);

  // Add this ref to track initialization status
  const isInitializing = useRef(false);

  const ThemeToggleButton = withTooltip(ToggleButton, 'THEME_TOGGLE_TOOLTIP');
  const [activeGroupId, setActiveGroupId] = useState<string>('');

  const [organizationDialogOpen, setOrganizationDialogOpen] = useState(false);
  const [isOrganizing, setIsOrganizing] = useState(false);

  const [moveToGroupOpen, setMoveToGroupOpen] = useState(false);
  const [groupToMove, setGroupToMove] = useState<string>('');
  const [bookToMove, setBookToMove] = useState<string>('');

  const [selectedBooks, setSelectedBooks] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [multiSelectMode, setMultiSelectMode] = useState<boolean>(false);

  const [uploadProgress, setUploadProgress] = useState<{ current: number; total: number }>({ current: 0, total: 0 });
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [currentFileName, setCurrentFileName] = useState<string>('');

  useEffect(() => {
    if (activeGroupId !== activeGroup?.id && prefs?.groups) {
      setActiveGroup(prefs.groups[activeGroupId] || null);
    }
  }, [activeGroupId]);

  useEffect(() => {
    if (prefs?.activeGroupId && prefs?.activeGroupId !== activeGroupId) {
      setActiveGroupId(prefs?.activeGroupId);
    }
  }, [prefs?.activeGroupId]);

  const charts = useMemo(
    () => [
      {
        id: 'weekly',
        label: t('WEEKLY_TIME'),
        component: <ReadingChart data={weeklyTime} isAnimationActive={true} animationDuration={3000} />,
      },
      {
        id: 'monthly',
        label: t('MONTHLY_TIME'),
        component: (
          <ReadingChart
            data={monthlyTime}
            isAnimationActive={true}
            dot={false}
            animationDuration={3000}
            movingAverageWindow={7}
          />
        ),
      },
      {
        id: 'streak',
        label: t('STREAK_TIME'),
        component: (
          <ReadingChart
            data={streakTime}
            dot={false}
            isAnimationActive={true}
            animationDuration={3000}
            movingAverageWindow={
              streakTime.length >= 180
                ? 30
                : streakTime.length >= 60
                ? 14
                : streakTime.length >= 30
                ? 7
                : streakTime.length >= 7
                ? 3
                : undefined
            }
          />
        ),
      },
    ],
    [weeklyTime, monthlyTime, streakTime, t]
  );

  const sortBooks = (books: BookMeta[]): BookMeta[] => {
    return books.sort((a, b) => {
      const timeDiff = (b.timestamp || 0) - (a.timestamp || 0);
      if (timeDiff !== 0) return timeDiff;
      return (a.title || '').localeCompare(b.title || '');
    });
  };

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  useEffect(() => {
    if (!localForageLoaded || isInitializing.current) return;

    const initializeBooks = async () => {
      // Set initializing flag
      isInitializing.current = true;

      try {
        const existingBooks = getBooks();
        setBooks(existingBooks);
      } catch (error) {
        console.error('Error initializing books:', error);
        setSnackbarMessage({
          text: t('ERROR_LOADING_BOOKS'),
          duration: 5000,
        });
      } finally {
        // Reset initializing flag
        isInitializing.current = false;
      }
    };

    initializeBooks();
  }, [localForageLoaded]);

  useEffect(() => {
    if (!localForageLoaded) return;
    const debouncedUpgrade = debounce(upgrade, 1000);
    setTimeout(() => {
      const oldStoreVersion = getMisc()?.storeVersion || currentStoreVersion;
      if (oldStoreVersion < currentStoreVersion) {
        console.debug('oldVersion', oldStoreVersion, 'current version', currentStoreVersion);
        setSnackbarMessage({ text: t('UPGRADING_INTERNALS_WAIT'), duration: null });

        debouncedUpgrade().then(() => {
          const storeVersion = getMisc()?.storeVersion || currentStoreVersion;
          console.log('version and old', storeVersion, oldStoreVersion);
          if (storeVersion !== oldStoreVersion) {
            console.log('upgraded to version', storeVersion);
            setSnackbarMessage({
              text: t('STORAGE_UPGRADED_RELOADING', { storeVersion: storeVersion }),
              duration: 5000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        });
      }
    }, 1000);
    return () => {
      debouncedUpgrade.cancel();
    };
  }, [localForageLoaded]);

  useEffect(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, []);

  useEffect(() => {
    if (cmd !== 'no-upgrade') {
      checkAppVersion();
    } else {
      console.log('not checking version');
      // change the apparent URL back to default
      const url = new URL(window.location.href);
      url.pathname = import.meta.env.BASE_URL;
      window.history.pushState({ path: url.href }, '', url.href);
    }
  }, [checkAppVersion, cmd]);

  const getStreakEmoji = (today: boolean): string => {
    if (today) return '🔥';

    return ['🕛', '🕐', '🕑', '🕒', '🕓', '🕔', '🕕', '🕖', '🕗', '🕘', '🕙', '🕚'][dayjs().hour() % 12];
  };

  /**
   * Sorts a book object by converting it to an array, sorting the array, and then converting it back to an object.
   * @param bookObject - The object containing book metadata, keyed by book ID.
   * @returns A new object with the books sorted by their metadata.
   */
  const sortBookObject = (bookObject: { [id: string]: BookMeta }): { [id: string]: BookMeta } => {
    const bookArray: BookMeta[] = Object.values(bookObject);
    const sortedBooks: BookMeta[] = sortBooks(bookArray);
    return sortedBooks.reduce((obj: { [id: string]: BookMeta }, book: BookMeta) => {
      obj[book.id] = book;
      return obj;
    }, {} as { [id: string]: BookMeta });
  };

  // Load progress info when books change
  useEffect(() => {
    const loadProgressAndNotes = async () => {
      const hashes = Object.values(books)
        .map((book) => book.hash)
        .filter((hash): hash is string => hash !== undefined);

      const progress = await getBookProgressAndNotes(hashes);
      progressInfoRef.current = progress;
    };

    if (localForageLoaded && books && Object.keys(books).length > 0) {
      loadProgressAndNotes();
    }
  }, [books, localForageLoaded]);

  useEffect(() => {
    if (localForageLoaded) {
      fetchFollowing();
    }
  }, [localForageLoaded]);

  const refreshBookList = () => {
    console.info('refreshing book list');
    // if (!getCurrentUser()?.prefs?.groups) {
    //   console.log('no groups, migrating shelves to groups');
    //   const migratedGroups = migrateShelvesToGroups(t);
    //   changePrefs({
    //     groups: migratedGroups,
    //     activeGroupId: getRootGroup(migratedGroups)?.id || Object.keys(migratedGroups)[0],
    //   });
    // }
    const booksObject = getBooks();
    const sortedBooks = sortBookObject(booksObject);

    const booksJson = JSON.stringify(sortedBooks);
    const booksJsonOld = JSON.stringify(books);

    if (booksJson === booksJsonOld) {
      console.log('books did not change');
    } else {
      console.log('books changed, updating BookList books object');
    }

    setBooks(sortedBooks);
    updateBooksCache(sortedBooks);
    console.log('book list refreshed');
  };

  //   useEffect(() => {
  //     if (user && window.location.href.includes('authed')) {
  //       window.location.href = import.meta.env.BASE_URL;
  //       console.log('redirecting to', import.meta.env.BASE_URL);
  //     }
  //   }, [user]);

  useEffect(() => {
    const authed = async () => {
      console.log('authed, cmd=', cmd, ' user=', user);
      window.location.href = import.meta.env.BASE_URL;
      console.log('redirecting to', import.meta.env.BASE_URL);
      //   const u = await fetchUser(true);

      //   setUser(u);
      //   console.log('user set to', u);
    };

    if (cmd === 'authed') {
      authed();
    } else if (cmd === 'badurl') {
      // TODO: implement
    } else if (cmd) {
      console.log('cmd = ', cmd);
    }
  }, [cmd]);

  useEffect(() => {
    if (!localForageLoaded) return;
    const loadStreaks = async () => {
      if (user?.email) {
        const streaks = await getStreaks([user.email]);
        if (streaks) {
          setStreaks(streaks);

          const streakTime = await aggregateWeeklyTime(streaks[user.email].days, 'dayOfMonth');
          setStreakTime(streakTime);
        }
      }
    };
    const updateWeeklyTime = async () => {
      const time = await aggregateWeeklyTime(7, 'dayOfWeek');
      setWeeklyTime(time);
    };
    const updateMonthlyTime = async () => {
      const time = await aggregateWeeklyTime(30, 'dayOfMonth');
      setMonthlyTime(time);
    };

    console.log('loading streaks from BookList for ', user?.email);
    if (user?.email) {
      loadStreaks();
      updateWeeklyTime();
      updateMonthlyTime();
    }
  }, [user?.email, localForageLoaded]);

  const createBackupZip = async () => {
    const zip = new JSZip();

    // Add localforage data to the zip file
    const localForageData: { [key: string]: any } = {};
    await localforage.iterate((value, key) => {
      localForageData[key] = value;
    });
    const localForageBlob = new Blob([JSON.stringify(localForageData)], {
      type: 'application/json',
    });
    zip.file('localForageBackup.json', localForageBlob);

    // Add IndexedDB files to the zip file
    const db = await getDB();
    const transaction = db.transaction(['ePubFiles'], 'readonly');
    const ePubFilesStore = transaction.objectStore('ePubFiles');

    const ePubFilesKeys = await ePubFilesStore.getAllKeys();

    const metadataStore: { [key: string]: any } = {};

    // Helper function to get all files and their metadata
    const getAllFiles = async (store: any, keys: any, folderName: any) => {
      for (const key of keys) {
        const file = await store.get(key);
        if (file) {
          zip.file(`${folderName}/${key}`, file);

          // If the file is not a cover, save its metadata
          if (!key.startsWith('cover-')) {
            metadataStore[key] = {
              name: file.name,
              type: file.type,
              lastModified: file.lastModified,
            };
          }
        }
      }
    };

    // Wait for all files to be added to the zip
    await getAllFiles(ePubFilesStore, ePubFilesKeys, 'ePubFiles');

    // Add metadata to the zip file
    const metadataBlob = new Blob([JSON.stringify(metadataStore)], {
      type: 'application/json',
    });
    zip.file('metadataBackup.json', metadataBlob);

    const zipBlob = await zip.generateAsync({ type: 'blob' });

    // Save or share the zip file
    const saveOrShareZip = async (blob: Blob) => {
      if (navigator.share) {
        const file = new File([blob], 'backup.zip', {
          type: 'application/zip',
        });
        try {
          await navigator.share({
            files: [file],
            title: t('BACKUP_TITLE'),
            text: t('BACKUP_TEXT'),
          });
          console.log('Shared successfully');
        } catch (error) {
          console.error('Error sharing', error);
          saveAs(blob, 'backup.zip');
        }
      } else {
        saveAs(blob, 'backup.zip');
      }
    };

    saveOrShareZip(zipBlob);
  };

  const loadFromBackup = async (file: File) => {
    try {
      const zip = await JSZip.loadAsync(file);

      // Load localforage data from the zip file
      const localForageFile = zip.file('localForageBackup.json');
      if (localForageFile) {
        const localForageBackup = await localForageFile.async('text');
        const localForageData = JSON.parse(localForageBackup);
        for (const key in localForageData) {
          const data = localForageData[key];
          console.debug('loading key', key, data);
          if (key === 'books') {
            updateBooksCache(data);
          } else if (key === 'currentUser') {
            updateCurrentUser(data);
          } else if (key === 'following') {
            updateFollowing(data);
          } else if (key === 'misc') {
            updateMisc(data);
          } else if (key === 'notesStatus') {
            updateNotesStatus(data);
          } else {
            console.warn('unsupported key in localForageBackup.json:', key);
            await localforage.setItem(key, data);
          }
        }
        console.log('localforage reloaded successfully');
      } else {
        console.warn('localForageBackup.json not found in the zip');
        setSnackbarMessage({ text: t('LOCALFORAGE_BACKUP_NOT_FOUND'), duration: 3000 });
        const localStorageFile = zip.file('localStorageBackup.json');
        if (localStorageFile) {
          const localStorageBackup = await localStorageFile.async('text');
          const localStorageData = JSON.parse(localStorageBackup);
          for (const key in localStorageData) {
            localStorage.setItem(key, localStorageData[key]);
          }
          console.log('localStorage reloaded successfully');
        }
      }

      // Load metadata from the zip file
      const metadataFile = zip.file('metadataBackup.json');
      let metadataStore: { [key: string]: any } = {};
      if (metadataFile) {
        const metadataBackup = await metadataFile.async('text');
        metadataStore = JSON.parse(metadataBackup);
      } else {
        console.warn('metadataBackup.json not found in the zip');
      }

      // Load IndexedDB data from the zip file
      const ePubFilesFolder = zip.folder('ePubFiles');

      if (ePubFilesFolder) {
        console.log('Processing ePubFiles...');
        ePubFilesFolder.forEach(async (relativePath, file) => {
          console.log(`Reading file: ${relativePath}`);
          const fileContent = await file.async('blob');
          if (fileContent) {
            if (relativePath.startsWith('cover-')) {
              // Save as Blob for covers
              console.log(`Saving cover blob ${relativePath} to IndexedDB`);
              await saveFile(
                relativePath,
                fileContent.type ? fileContent : new Blob([fileContent], { type: 'image/jpeg' })
              );
            } else {
              // Save as File for EPUB files
              const metadata = metadataStore[relativePath];
              let fileObject;
              if (metadata) {
                fileObject = new File([fileContent], metadata.name, {
                  type: metadata.type,
                  lastModified: metadata.lastModified,
                });
              } else {
                fileObject = new File([fileContent], file.name, {
                  type: fileContent.type || 'application/epub+zip',
                  lastModified: new Date().getTime(),
                });
              }
              console.log(`Saving file ${relativePath} to IndexedDB`);
              await saveFile(relativePath, fileObject);
            }
          } else {
            console.warn(`Failed to read file content for ${relativePath}`);
          }
        });
        console.log('ePubFiles reloaded successfully');
      } else {
        console.warn('No ePubFiles folder found in the zip');
      }

      console.log('Backup loaded successfully.');
      setSnackbarMessage({ text: t('BACKUP_LOADED_SUCCESSFULLY'), duration: 3000 });
      const user = getCurrentUser();
      if (user) {
        setUser(user);
        if (user?.prefs) {
          changePrefs(user.prefs);
        }
        refreshBookList();
      }
    } catch (error) {
      console.error('Error loading backup:', error);
    }
  };

  // TODO: refreshbooklist after adding books
  // TODO: snackbar on error adding books

  const handleLoadBackup = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSnackbarMessage({ text: t('NO_FILES_SELECTED_YOU_DO_YOU'), duration: 3000 });
      return;
    }
    const file = e.target.files[0];
    setSnackbarMessage({ text: t('LOADING_BACKUP_WAIT'), duration: null });
    await loadFromBackup(file);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      setSnackbarMessage({ text: t('NO_FILES_SELECTED_BUT_YOU_DO_YOU'), duration: 3000 });
      return;
    }

    // For a single file, use the simpler approach
    if (files.length === 1) {
      try {
        setSnackbarMessage({ text: t('ADDING_BOOK'), duration: null });
        const bookId = await addBookFromFile(files[0]);
        if (bookId) {
          setSnackbarMessage({
            text: t('BOOK_ADDED_SUCCESSFULLY', { bookTitle: getBook(bookId)?.title || t('BOOK') }),
            duration: 3000,
          });
        } else {
          setSnackbarMessage({
            text: t('FAILED_TO_ADD_BOOK'),
            duration: 3000,
          });
        }
      } catch (error) {
        console.error('Error adding book:', error);
        setSnackbarMessage({
          text: t('FAILED_TO_ADD_BOOK_ERROR', { error: error instanceof Error ? error.message : String(error) }),
          duration: 3000,
        });
      }
      refreshBookList();
      return;
    }

    // For multiple files, show progress dialog
    const handleMultipleFileUpload = async () => {
      const filesToProcess = Array.from(files);
      const totalFiles = filesToProcess.length;
      let successCount = 0;
      const validBooks: string[] = [];

      // Initialize the upload progress and open the dialog
      setUploadProgress({ current: 0, total: totalFiles });
      setUploadDialogOpen(true);

      // Process files sequentially to show progress
      for (let i = 0; i < filesToProcess.length; i++) {
        try {
          // Update current file name
          setCurrentFileName(filesToProcess[i].name);

          const bookId = await addBookFromFile(filesToProcess[i]);
          if (bookId) {
            validBooks.push(bookId);
            successCount++;
          }
        } catch (error) {
          console.error(`Error adding book ${filesToProcess[i].name}:`, error);
        }

        // Update progress
        setUploadProgress({ current: i + 1, total: totalFiles });
      }

      // Clear the current file name and close dialog when done
      setCurrentFileName('');
      setUploadDialogOpen(false);

      // Show final result message
      if (validBooks.length === 0) {
        setSnackbarMessage({
          text: t('EVERY_BOOK_FAILED_TO_ADD'),
          duration: 3000,
        });
      } else {
        setSnackbarMessage({
          text: t('BOOKS_ADDED_SUMMARY', {
            successCount: successCount,
            totalCount: totalFiles,
          }),
          duration: 5000,
        });
      }
    };

    await handleMultipleFileUpload();
    refreshBookList();
  };

  const handleDeleteBook = async (id: string) => {
    console.log('handle delete book');
    const books = await deleteBook(id);
    setBooks(books);
    refreshBookList();
    // Add notification for book deletion
    setSnackbarMessage({
      text: t('BOOK_DELETED_SUCCESSFULLY'),
      duration: 3000,
    });
  };

  const handleResetBook = async (id: string) => {
    console.log('handle reset book');
    const book = await resetBook(id);
    if (book) {
      setBooks({ ...books, [book.id]: book });
      setSnackbarMessage({ text: t('BOOK_RESET_SUCCESSFUL'), duration: 5000 });
      refreshBookList();
    } else {
      setSnackbarMessage({ text: t('BOOK_RESET_FAILED'), duration: 5000 });
    }
  };

  const handleReplaceFileClick = (id: string) => {
    // Assuming replaceBookFile is an API to replace the book file
    console.debug('handleReplaceFileClick', id);
    setReplaceFileId(id);
    fileInputRef.current?.click();
  };

  const handleReplaceFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.debug('handleReplaceFileChange', event.target.files);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files?.[0];
      if (replaceFileId && file) {
        console.log('File selected, id:', file.name, replaceFileId);
        await replaceBookFile(replaceFileId, file);
        setReplaceFileId(null);
        setSnackbarMessage({ text: t('BOOK_FILE_REPLACED_SUCCESSFULLY'), duration: 5000 });
        refreshBookList();
      }
    }
  };

  const handleAddTranslatedVersion = (id: string) => {
    console.debug('handleAddTranslatedVersion', id);
    translatedVersionIdRef.current = id;
    translatedFileInputRef.current?.click();
  };

  const handleAddTranslatedVersionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.debug('handleAddTranslatedVersionChange', event.target.files);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files?.[0];
      console.debug('handleAddTranslatedVersionChange', file);
      if (translatedVersionIdRef.current && file) {
        console.log('File selected, id:', file.name, translatedVersionIdRef.current);
        await addTranslatedVersion(translatedVersionIdRef.current, file);
        translatedVersionIdRef.current = '';
        setSnackbarMessage({ text: t('TRANSLATED_VERSION_ADDED'), duration: 5000 });
        refreshBookList();
      } else if (!file) {
        setSnackbarMessage({ text: t('NO_FILE_SELECTED'), duration: 3000 });
      } else {
        setSnackbarMessage({ text: t('UNKNOWN_ERROR_ADDING_TRANSLATED_VERSION'), duration: 5000 });
      }
    }
  };

  const openBook = (id: string) => {
    navigate(`/read/${id}`);
  };

  const handleCustomLogout = () => {
    setUser(null);
    logoutUser();
  };

  const handleThemeChange = () => {
    const newTheme = prefs?.theme === 'light' ? 'dark' : prefs?.theme === 'dark' ? 'auto' : 'light';
    changePrefs({ theme: newTheme });
  };

  /**
   * Navigates to the parent group
   */
  const navigateToParent = () => {
    if (activeGroup?.parentId) {
      // Navigate to parent
      changePrefs({ activeGroupId: activeGroup.parentId });
    } else {
      console.warn('No parent group found for', activeGroup);
      // Navigate to root level
      const myLibraryGroup = getRootGroup(prefs?.groups);
      if (myLibraryGroup) {
        changePrefs({ activeGroupId: myLibraryGroup.id });
      }
    }
  };

  /**
   * Opens dialog to create a new group
   */
  const handleCreateGroup = () => {
    setCreateGroupOpen(true);
  };

  /**
   * Handles submission of new group
   */
  const handleCreateGroupsubmit = (name: string) => {
    if (name.trim() && prefs?.groups) {
      // Create a new group with the current group as parent
      const updatedGroups = createGroup(prefs.groups, name.trim(), '', activeGroupId);
      changePrefs({ groups: updatedGroups });
      setCreateGroupOpen(false);
      // Add notification for group creation
      setSnackbarMessage({
        text: t('GROUP_CREATED_SUCCESSFULLY', { groupName: name.trim() }),
        duration: 3000,
      });
    }
  };

  /**
   * Opens dialog to edit an existing group
   */
  const handleEditGroup = (groupId: string) => {
    const group = prefs?.groups?.[groupId];
    if (group) {
      setEditingGroupId(groupId);
      setEditGroupOpen(true);
    }
  };

  /**
   * Handles submission of group edit
   */
  const handleEditGroupsubmit = (name: string) => {
    if (editingGroupId && name && prefs?.groups) {
      const updatedGroups = updateGroup(prefs.groups, {
        id: editingGroupId,
        name: name.trim(),
      });
      console.log('updatedGroups', updatedGroups);
      changePrefs({ groups: updatedGroups });
      setEditGroupOpen(false);
      setEditingGroupId(null);
      refreshBookList();
      // Add notification for group rename
      setSnackbarMessage({
        text: t('GROUP_RENAMED_SUCCESSFULLY', { groupName: name.trim() }),
        duration: 3000,
      });
    }
  };

  /**
   * Handles deletion of a group
   */
  const handleDeleteGroup = (groupId: string, groupName: string, parentId: string) => {
    if (prefs?.groups) {
      const updatedGroups = deleteGroup(prefs.groups, books, groupId);
      changePrefs({ groups: updatedGroups });
      refreshBookList();
      setSnackbarMessage({
        text: t('GROUP_DELETED', { groupName: groupName, parentName: prefs?.groups?.[parentId]?.name || '' }),
        duration: 5000,
      });
    }
  };

  /**
   * Handles deletion of multiple groups
   */
  const handleDeleteGroups = (groupIds: string[]) => {
    let updatedGroups = prefs?.groups;
    if (updatedGroups) {
      for (const groupId of groupIds) {
        updatedGroups = deleteGroup(updatedGroups, books, groupId);
      }
      changePrefs({ groups: updatedGroups });
      refreshBookList();
      // Add notification for multiple group deletion
      if (groupIds.length > 0) {
        setSnackbarMessage({
          text: t('MULTIPLE_GROUPS_DELETED', { count: groupIds.length }),
          duration: 3000,
        });
      }
    }
  };

  /**
   * Renders navigation breadcrumbs for the current group
   */
  const renderBreadcrumbs = () => {
    if (!activeGroup) return null;

    // Build breadcrumb path
    const breadcrumbs = [];
    let group = activeGroup;

    // Add current group
    breadcrumbs.unshift({
      id: group.id,
      name: group.name,
    });

    // Add parent groups
    while (group.parentId) {
      const parent = prefs?.groups?.[group.parentId];
      if (parent) {
        breadcrumbs.unshift({
          id: parent.id,
          name: parent.name,
        });
        group = parent;
      } else {
        break;
      }
    }

    // Add home as first item if not already there
    // if (breadcrumbs.length > 0 && breadcrumbs[0].name !== t('MY_LIBRARY')) {
    //   const myLibrary = getRootGroup(prefs?.groups || {});
    //   if (myLibrary) {
    //     breadcrumbs.unshift({ id: myLibrary.id, name: t('MY_LIBRARY') });
    //   }
    // }

    return (
      <Breadcrumbs aria-label='group breadcrumbs' sx={{ mt: 2 }} separator='/'>
        {breadcrumbs.map((crumb, index) => {
          const isLast = index === breadcrumbs.length - 1;
          return isLast ? (
            <Typography color='text.primary' key={crumb.id} sx={{ alignItems: 'center', display: 'flex' }}>
              {index === 0 && <HomeIcon sx={{ mr: 0.5, fontSize: '1em', verticalAlign: 'middle' }} />}
              {crumb.name}
            </Typography>
          ) : (
            <Link
              component='button'
              variant='body1'
              key={crumb.id}
              onClick={() => {
                changePrefs({ activeGroupId: crumb.id });
              }}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
              underline='hover'
              color='inherit'
            >
              {index === 0 && <HomeIcon sx={{ mr: 0.5, fontSize: '1em', verticalAlign: 'middle' }} />}
              {crumb.name}
            </Link>
          );
        })}
      </Breadcrumbs>
    );
  };

  // If no groups exist, initialize default ones and migrate books
  useEffect(() => {
    if (!prefs) return;
    if (prefs.groups) return;
    console.log('Migrating shelves to groups');
    if (Object.keys(books).length > 0) {
      setSnackbarMessage({ text: t('MIGRATING_SHELVES_TO_GROUPS'), duration: null });
    }
    const migratedGroups = migrateShelvesToGroups(t);
    const myLibraryGroup = getRootGroup(migratedGroups);
    changePrefs({
      groups: migratedGroups,
      activeGroupId: myLibraryGroup?.id || Object.keys(migratedGroups)[0],
    });
    setActiveGroupId(myLibraryGroup?.id || Object.keys(migratedGroups)[0]);
    refreshBookList();
  }, [prefs]);

  /**
   * Gets books and subgroups for a specific group ID
   */
  const getItemsInSelectedGroup = (groupId: string) => {
    // Get all books that have this group as their parent
    const booksInGroup = Object.values(books).filter((book) => book.parentId === groupId);

    // Find subgroups (groups that have this groupId as parentId)
    const subGroups = getAllGroupsSorted(prefs?.groups).filter((g) => g.parentId === groupId);

    return { books: booksInGroup, groups: subGroups };
  };

  // ** Handler to save selected language to both Context and prefs. **
  const handleLanguageSave = (selectedLanguage: string) => {
    console.log('handleLanguageSave', selectedLanguage);
    setLanguage(selectedLanguage);
    changePrefs({ languageCode: selectedLanguage });
    setLanguageModalOpen(false);
  };

  /**
   * Handles the refresh action by clearing translations and reloading the page.
   */
  const handleRefresh = async () => {
    window.location.reload(); // Reload the page
  };

  /**
   * Handles opening the organization dialog and populates it with saved instructions
   */
  const handleOpenOrganizationDialog = () => {
    setOrganizationDialogOpen(true);
  };

  /**
   * Handles closing the organization dialog
   */
  const handleCloseOrganizationDialog = () => {
    setOrganizationDialogOpen(false);
  };

  /**
   * Processes the JSON structure returned by the API to create a hierarchical group structure
   * @param result - The JSON organization structure returned by the API
   * @param shouldMerge - Whether to merge with existing groups (true) or overwrite (false)
   */
  const processOrganizationResult = async (result: { [groupName: string]: any }, shouldMerge: boolean) => {
    if (!result) return;

    // Get existing groups
    let existingGroups = { ...(prefs?.groups || {}) };
    const rootGroup = getRootGroup(existingGroups);

    // Check if root group exists
    if (!rootGroup) {
      console.error('Root group not found');
      return;
    }

    // Use the active group as the parent for new organization
    const parentGroupId = activeGroupId || rootGroup.id;

    // Get all current books
    const allBooks = getBooks();

    // Track all books that get organized into groups
    const organizedBookIds = new Set<string>();

    // If not merging (overwriting), remove all existing child groups at this level
    if (!shouldMerge) {
      // Find all direct child groups of the active group
      const childGroupIds = Object.keys(existingGroups).filter(
        (id) => existingGroups[id].parentId === parentGroupId && id !== parentGroupId
      );

      // Remove each child group
      childGroupIds.forEach((groupId) => {
        delete existingGroups[groupId];
      });

      // Move all books currently in this group to be organized
      Object.keys(allBooks).forEach((bookId) => {
        if (allBooks[bookId].parentId === parentGroupId) {
          // Mark these books for organization
          organizedBookIds.add(bookId);
        }
      });
    }

    // Recursive function to process the hierarchical structure
    const processGroup = (groupData: any, parentId: string = parentGroupId): void => {
      // If groupData is an array, it's a list of book IDs
      if (Array.isArray(groupData)) {
        // Add books directly to parent group
        if (!existingGroups[parentId]) {
          console.error(`Parent group ${parentId} not found`);
          return;
        }

        const bookIds = groupData.filter((id) => typeof id === 'string' && allBooks[id]);

        // Skip if no valid book IDs
        if (bookIds.length === 0) {
          return;
        }

        // Update book parentId references
        bookIds.forEach((bookId) => {
          if (allBooks[bookId]) {
            allBooks[bookId] = {
              ...allBooks[bookId],
              parentId: parentId,
            };
            organizedBookIds.add(bookId);
          }
        });

        return;
      }

      // If this is an object with group names as keys
      if (typeof groupData === 'object' && groupData !== null) {
        // For each group in the structure
        Object.entries(groupData).forEach(([name, value]) => {
          // In merge mode: check if a group with this name already exists under the parent
          let groupId: string;
          let existingGroupWithName = null;

          if (shouldMerge) {
            existingGroupWithName = Object.values(existingGroups).find(
              (group) => group.name === name && group.parentId === parentId
            );
          }

          if (existingGroupWithName) {
            // Use existing group (only in merge mode)
            groupId = existingGroupWithName.id;
          } else {
            // Create a new group
            const updatedGroups = createGroup(existingGroups, name, '', parentId);

            // Find the ID of the newly created group
            const newGroup = Object.values(updatedGroups).find(
              (group) => group.name === name && group.parentId === parentId && !existingGroups[group.id]
            );

            if (!newGroup) {
              console.error(`Failed to create group ${name}`);
              return;
            }

            groupId = newGroup.id;
            existingGroups = updatedGroups;
          }

          // Process this group's contents
          processGroup(value, groupId);
        });
      }
    };

    // Start processing from the root level
    processGroup(result);

    // Update books
    if (!shouldMerge) {
      // In overwrite mode, move any remaining books at this level to root
      // if they weren't included in the organization
      Object.keys(allBooks).forEach((bookId) => {
        const book = allBooks[bookId];
        if (book.parentId === parentGroupId && !organizedBookIds.has(bookId)) {
          book.parentId = rootGroup.id;
        }
      });
    }

    // Save changes
    updateBooksCache(allBooks);
    changePrefs({
      groups: existingGroups,
      organizationInstruction: organizationInstructionInputRef.current?.value,
    });

    // Refresh the book list to reflect the new organization
    refreshBookList();
  };

  /**
   * Handles the organization request when user confirms the dialog
   */
  const handleOrganizeBooks = async () => {
    const organizationInstruction = organizationInstructionInputRef.current?.value.trim();
    if (!organizationInstruction) return;

    setIsOrganizing(true);

    try {
      // Determine which books to organize based on selection or current group
      let bookIds: string[] = [];
      let shouldMerge = false;

      if (multiSelectMode && selectedBooks.length > 0) {
        // If books are selected in multi-select mode, use only those and merge results
        bookIds = selectedBooks;
        shouldMerge = true;
      } else {
        // Otherwise, get all books in the current active group (including subgroups)
        // and replace the group structure
        const booksInActiveGroup = getAllBooksInGroup(prefs?.groups, books, activeGroupId);
        bookIds = booksInActiveGroup.map((book) => book.id);
        shouldMerge = false;
      }

      // Don't proceed if no books to organize
      if (bookIds.length === 0) {
        setSnackbarMessage({
          text: t('NO_BOOKS_TO_ORGANIZE'),
          duration: 3000,
        });
        setIsOrganizing(false);
        handleCloseOrganizationDialog();
        return;
      }

      // Call the API with only the selected or active group books
      const result = await organizeBooks(organizationInstruction, bookIds);

      if (result) {
        // Process the returned structure to create groups
        await processOrganizationResult(result, shouldMerge);
        setSnackbarMessage({
          text: t('BOOKS_ORGANIZED_SUCCESSFULLY'),
          duration: 3000,
        });
      } else {
        setSnackbarMessage({
          text: t('FAILED_TO_ORGANIZE_BOOKS'),
          duration: 3000,
        });
      }
    } catch (error) {
      console.error('Error organizing books:', error);
      setSnackbarMessage({
        text: t('ERROR_ORGANIZING_BOOKS', {
          error: error instanceof Error ? error.message : String(error),
        }),
        duration: 3000,
      });

      changePrefs({
        organizationInstruction: organizationInstruction,
      });
    } finally {
      setIsOrganizing(false);
      handleCloseOrganizationDialog();
    }
  };

  const handleMoveGroup = (groupId: string) => {
    setGroupToMove(groupId);
    setMoveToGroupOpen(true);
  };

  const handleMoveGroupSubmit = (targetGroupId: string) => {
    // Don't move a group to itself
    if (groupToMove === targetGroupId) return;

    if (!prefs?.groups) return;

    const updatedGroups = updateGroup(prefs.groups, {
      id: groupToMove,
      parentId: targetGroupId,
    });

    // Update user preferences with the updated groups
    changePrefs({
      groups: updatedGroups,
    });

    refreshBookList();

    setMoveToGroupOpen(false);
    setGroupToMove(''); // Reset groupToMove after operation is complete

    // Add notification for group move
    const groupName = prefs?.groups?.[groupToMove]?.name || '';
    const targetGroupName = prefs?.groups?.[targetGroupId]?.name || '';
    setSnackbarMessage({
      text: t('GROUP_MOVED_SUCCESSFULLY', { groupName, targetGroupName }),
      duration: 3000,
    });
  };

  /**
   * Handles the request to move a book to a different group
   * @param bookId - ID of the book to move
   */
  const handleMoveBook = (bookId: string) => {
    setBookToMove(bookId);
    setMoveToGroupOpen(true);
  };

  /**
   * Toggles selection of a book
   * @param bookId - The ID of the book to toggle
   * @param event - Optional event to prevent propagation
   */
  const toggleBookSelection = (bookId: string, event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }

    setSelectedBooks((prev) => {
      if (prev.includes(bookId)) {
        return prev.filter((id) => id !== bookId);
      } else {
        return [...prev, bookId];
      }
    });

    // Enable multi-select mode if not already enabled
    if (!multiSelectMode) {
      setMultiSelectMode(true);
    }
  };

  /**
   * Toggles selection of a group
   * @param groupId - The ID of the group to toggle
   * @param event - Optional event to prevent propagation
   */
  const toggleGroupSelection = (groupId: string, event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }

    setSelectedGroups((prev) => {
      if (prev.includes(groupId)) {
        return prev.filter((id) => id !== groupId);
      } else {
        return [...prev, groupId];
      }
    });

    // Enable multi-select mode if not already enabled
    if (!multiSelectMode) {
      setMultiSelectMode(true);
    }
  };

  /**
   * Cancels multi-select mode and clears all selections
   */
  const cancelMultiSelect = () => {
    setMultiSelectMode(false);
    setSelectedBooks([]);
    setSelectedGroups([]);
  };

  /**
   * Deletes all selected books and groups
   */
  const handleDeleteSelected = async () => {
    // First ask for confirmation
    if (!window.confirm(t('CONFIRM_DELETE_SELECTED_ITEMS'))) {
      return;
    }

    // Store the counts before deletion
    const booksCount = selectedBooks.length;
    const groupsCount = selectedGroups.length;

    // Delete selected books
    for (const bookId of selectedBooks) {
      await handleDeleteBook(bookId);
    }

    await handleDeleteGroups(selectedGroups);

    // Exit multi-select mode
    cancelMultiSelect();

    // Add notification for multiple items deletion
    const totalItems = booksCount + groupsCount;
    setSnackbarMessage({
      text: t('ITEMS_DELETED_SUCCESSFULLY', { count: totalItems }),
      duration: 3000,
    });
  };

  /**
   * Opens the move dialog for selected books and groups
   */
  const handleMoveSelected = () => {
    setMoveToGroupOpen(true);
  };

  /**
   * Handles moving multiple selected items to a target group
   * @param targetGroupId - The ID of the target group
   */
  const handleMoveSelectedItems = async (targetGroupId: string) => {
    // First move books
    for (const bookId of selectedBooks) {
      const book = books[bookId];
      if (book) {
        // Update book's parentId
        const updatedBook = {
          ...book,
          parentId: targetGroupId,
        };
        updateBook(updatedBook);
      }
    }

    // Then move groups
    const updatedGroups = { ...(prefs?.groups || {}) };
    for (const groupId of selectedGroups) {
      const group = updatedGroups[groupId];
      if (group) {
        // Skip if trying to move to itself or if the target is a descendant
        if (groupId !== targetGroupId && !isTargetDescendant(groupId, targetGroupId, updatedGroups)) {
          updatedGroups[groupId] = {
            ...group,
            parentId: targetGroupId,
            updatedAt: Date.now(),
          };
        }
      }
    }

    changePrefs({
      groups: updatedGroups,
    });

    // Exit multi-select mode
    cancelMultiSelect();
    refreshBookList();

    // Add notification for multiple items move
    const targetGroupName = prefs?.groups?.[targetGroupId]?.name || '';
    const totalItems = selectedBooks.length + selectedGroups.length;
    setSnackbarMessage({
      text: t('ITEMS_MOVED_SUCCESSFULLY', { count: totalItems, targetGroupName }),
      duration: 3000,
    });
  };

  /**
   * Checks if a target group is a descendant of the source group
   * @param sourceId - The source group ID
   * @param targetId - The target group ID
   * @param groups - The collection of groups
   * @returns boolean indicating if target is a descendant
   */
  const isTargetDescendant = (sourceId: string, targetId: string, groups: GroupCollection): boolean => {
    let currentId = targetId;

    while (currentId) {
      if (currentId === sourceId) {
        return true;
      }

      currentId = groups[currentId]?.parentId || '';
    }

    return false;
  };

  // Update the handleMoveBookSubmit function to handle multiple book moves
  const handleMoveBookSubmit = (targetGroupId: string) => {
    if (multiSelectMode && (selectedBooks.length > 0 || selectedGroups.length > 0)) {
      handleMoveSelectedItems(targetGroupId);
    } else if (bookToMove) {
      // Existing single book move logic
      const book = books[bookToMove];
      if (book) {
        // Update book's parentId
        const updatedBook = {
          ...book,
          parentId: targetGroupId,
        };
        updateBook(updatedBook);
        setBookToMove('');
        refreshBookList();

        // Add notification for book move
        const targetGroupName = prefs?.groups?.[targetGroupId]?.name || '';
        setSnackbarMessage({
          text: t('BOOK_MOVED_SUCCESSFULLY', { bookTitle: book.title || 'Book', targetGroupName }),
          duration: 3000,
        });
      }
    }
  };

  /**
   * Selects all items in the current group
   */
  const selectAll = () => {
    const items = getItemsInSelectedGroup(activeGroupId);
    const bookIds = items.books.map((book) => book.id);
    const groupIds = items.groups.map((group) => group.id);

    setSelectedBooks(bookIds);
    setSelectedGroups(groupIds);
  };

  //   /**
  //    * Updates a book in the books collection
  //    *
  //    * @param bookId - The ID of the book to update
  //    * @param updatedBook - The updated book data
  //    */
  //   const changeBook = (bookId: string, updatedBook: BookMeta) => {
  //     // const updatedBooks = {
  //     //   ...books,
  //     //   [bookId]: updatedBook,
  //     // };
  //     // setBooks(updatedBooks);
  //     // updateBooksCache(updatedBooks);
  //     updateBook(updatedBook); // Call the API to update the book
  //   };

  // Don't render anything until translations are ready
  if (isLoading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    localForageLoaded && (
      <Container
        sx={{
          backgroundColor: getTheme() === 'dark' ? 'black' : 'white',
          paddingTop: '8px',
          minHeight: '100vh',
        }}
      >
        {import.meta.env.DEV && (
          <Typography
            variant='caption'
            color='#888'
            sx={{
              fontSize: '0.5rem',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {t('DEV_MODE')}
          </Typography>
        )}
        <input ref={fileInputRef} type='file' hidden onChange={(e) => handleReplaceFileChange(e)} />
        <input ref={translatedFileInputRef} type='file' hidden onChange={(e) => handleAddTranslatedVersionChange(e)} />
        <Stack
          direction='row'
          spacing={1}
          margin='0 0 12px 0px'
          alignItems='center'
          sx={{ backgroundColor: getTheme() === 'dark' ? 'black' : 'white' }}
          whiteSpace='nowrap'
        >
          <AddBookButton
            variant='contained'
            component='label'
            sx={{
              width: '20%',
              textTransform: 'none',
              backgroundColor: '#5c6bc0',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          >
            {t('ADD_BOOK_BUTTON')}
            <input
              type='file'
              hidden
              multiple // TODO: should be single for replace and translated version
              accept='.epub,application/epub+zip'
              onChange={handleFileChange}
            />
          </AddBookButton>
          <LogoutButton
            variant='outlined'
            component='label'
            onClick={handleCustomLogout}
            sx={{
              width: '20%',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          >
            {t('LOGOUT_BUTTON')}
          </LogoutButton>
          <Box flexGrow={1} />
          <IconButton
            onClick={handleRefresh} // Use the new handler
            sx={{
              padding: '0px 0',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          >
            {isOnline || !user ? <RefreshIcon color='primary' /> : <CloudOffIcon style={{ color: '#b22' }} />}
          </IconButton>
          <Box flexGrow={1} />
          <BackupButton
            variant='outlined'
            component='label'
            onClick={() => {
              setSnackbarMessage({
                text: t('BACKUP_IN_PROGRESS'),
                duration: null,
              });
              return createBackupZip();
            }}
            sx={{
              width: '20%',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          >
            {t('BACKUP_BUTTON')}
          </BackupButton>
          <RestoreButton
            variant='outlined'
            component='label'
            sx={{
              width: '20%',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          >
            {t('RESTORE_BUTTON')}
            <input id='backupInput' type='file' hidden accept='application/zip' onChange={handleLoadBackup} />
          </RestoreButton>
        </Stack>
        <Stack
          direction='row'
          sx={{
            m: '0 0px 32px 0',
            alignItems: 'center',
          }}
          spacing={1}
        >
          <HelpButton
            sx={{
              width: '20%',
              textTransform: 'none',
              color: 'secondary.main',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          />
          <FeedbackButton
            variant='outlined'
            component='label'
            disabled={true}
            sx={{
              width: '20%',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          >
            {t('FEEDBACK_BUTTON')}
          </FeedbackButton>
          <Box flexGrow={1} />
          <ThemeToggleButton
            value={prefs?.theme || 'light'}
            onClick={handleThemeChange}
            aria-label='Theme toggle'
            sx={{
              width: '24px',
              height: '24px',
              border: '0',
              padding: '0',
            }}
          >
            {prefs?.theme === 'light' ? (
              <LightModeIcon color='primary' />
            ) : prefs?.theme === 'dark' ? (
              <DarkModeIcon color='primary' />
            ) : (
              <Brightness6Icon color='primary' />
            )}
          </ThemeToggleButton>
          <Box flexGrow={1} />

          {/* Language selection button – sits just left of the "version" button */}
          <LanguageButton
            variant='outlined'
            onClick={() => {
              setSelectedLanguage(currentLanguage); // reset to currentLanguage
              setLanguageModalOpen(true);
            }}
            sx={{
              textTransform: 'none',
              width: '20%',
              // padding: '4px 8px'
            }}
          >
            {currentLanguage.toUpperCase()}
          </LanguageButton>

          <VersionButton
            variant='outlined'
            color='primary'
            onClick={() => setChangelogOpen(true)}
            sx={{
              textTransform: 'none',
              width: '20%',
              //   padding: '4px 8px'
            }}
          >
            v{version}
          </VersionButton>
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
          {user && (
            <>
              <Avatar src={user.picture} />
              <Typography
                sx={{
                  color: getTheme() === 'dark' ? 'white' : 'black',
                  fontWeight: 'bold',
                  fontSize: '150%',
                }}
              >
                {user.email && streaks?.[user.email]?.days
                  ? t('STREAK_TIME_TEXT', {
                      numDays: streaks[user.email].days,
                      streakEmoji: getStreakEmoji(streaks[user.email].today),
                    })
                  : user.given_name
                  ? t('HI_NAME', { givenName: user.given_name })
                  : t('HI_YOU')}
              </Typography>
            </>
          )}
        </Stack>
        {isOnline &&
          (streakTime.length > 0 ? (
            <Container
              sx={{
                backgroundColor: getTheme() === 'dark' ? 'black' : 'white',
                paddingTop: '8px',
                paddingX: 0,
              }}
            >
              <div className='embla'>
                <div className='embla__viewport' ref={emblaRef}>
                  <div className='embla__container'>
                    {charts.map((chart) => (
                      <div className='embla__slide' key={chart.id}>
                        {chart.component}
                      </div>
                    ))}
                  </div>
                </div>
                <div className='embla__dots'>
                  {charts.map((_, index) => (
                    <DotButton
                      key={index}
                      selected={index === selectedIndex}
                      onClick={() => emblaApi && emblaApi.scrollTo(index)}
                    />
                  ))}
                </div>
                <PrevButton onClick={() => emblaApi && emblaApi.scrollPrev()} disabled={!canScrollPrev} />
                <NextButton onClick={() => emblaApi && emblaApi.scrollNext()} disabled={!canScrollNext} />
              </div>
            </Container>
          ) : (
            // empty space while waiting for data
            <Box sx={{ height: '200px', width: '100%' }} />
          ))}

        {/* Navigation controls */}
        <Stack
          direction='row'
          spacing={1}
          sx={{
            p: 0,
            mt: 4,
            backgroundColor: getTheme() === 'dark' ? 'black' : 'white',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <Button
            variant='outlined'
            startIcon={<CategoryIcon />}
            onClick={handleOpenOrganizationDialog}
            sx={{
              textTransform: 'none',
              alignSelf: 'stretch',
              flex: 1,
              height: '100%',
            }}
          >
            {t('AUTO')}
          </Button>

          <Button
            variant='outlined'
            startIcon={<CreateNewFolderIcon />}
            onClick={handleCreateGroup}
            sx={{
              textTransform: 'none',
              alignSelf: 'stretch',
              flex: 1,
              height: '100%',
            }}
          >
            {t('NEW')}
          </Button>

          <Button
            variant='outlined'
            startIcon={multiSelectMode ? <ClearIcon /> : <CheckBoxOutlineBlankIcon />}
            onClick={() => setMultiSelectMode(!multiSelectMode)}
            sx={{
              textTransform: 'none',
              alignSelf: 'stretch',
              flex: 1,
              height: '100%',
            }}
          >
            {multiSelectMode ? t('CANCEL_SELECTION') : t('SELECT_MULTIPLE')}
          </Button>

          <Button
            variant='outlined'
            startIcon={<ArrowUpwardIcon />}
            onClick={navigateToParent}
            disabled={!activeGroup?.parentId}
            sx={{
              textTransform: 'none',
              alignSelf: 'stretch',
              flex: 1,
              height: '100%',
            }}
          >
            {t('UP_LEVEL')}
          </Button>
        </Stack>
        <Box sx={{ flexGrow: 1, alignSelf: 'center' }}>{renderBreadcrumbs()}</Box>

        {/* Selection Actions Bar - Only visible in multi-select mode */}
        {multiSelectMode && (
          <Stack
            direction='row'
            spacing={1}
            sx={{
              //   width: '90%',
              p: 1,
              mt: 1,
              mb: 1,
              bgcolor: 'background.paper',
              borderRadius: 1,
              border: '1px solid',
              borderColor: '#888',
              elevation: 5,
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'sticky',
              top: 0,
              zIndex: 10,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='body1' color={getTheme() === 'dark' ? 'white' : 'black'}>
                {t('SELECTED_ITEMS_COUNT', {
                  count: selectedBooks.length + selectedGroups.length,
                })}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Tooltip title={t('SELECT_ALL')}>
                <IconButton onClick={selectAll}>
                  <SelectAllIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('MOVE_SELECTED')}>
                <IconButton
                  color='primary'
                  onClick={handleMoveSelected}
                  disabled={selectedBooks.length + selectedGroups.length === 0}
                >
                  <DriveFileMoveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_SELECTED')}>
                <IconButton
                  color='error'
                  onClick={handleDeleteSelected}
                  disabled={selectedBooks.length + selectedGroups.length === 0}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('CANCEL_SELECTION')}>
                <IconButton onClick={cancelMultiSelect}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        )}

        {/* Books and groups display */}
        <Stack
          spacing={1}
          paddingBottom={2}
          paddingTop={2}
          flexWrap='wrap'
          useFlexGap
          direction='row'
          overflow='auto'
          sx={{ backgroundColor: getTheme() === 'dark' ? 'black' : 'white' }}
        >
          {/* Render groups first */}
          {getItemsInSelectedGroup(activeGroupId).groups.map((group) => (
            <Box
              key={group.id}
              sx={{
                display: 'flex',
                backgroundColor: getTheme() === 'dark' ? 'black' : 'white',
                mx: 0,
                px: 0,
                flexGrow: 0,
                position: 'relative',
              }}
            >
              {multiSelectMode && (
                <Checkbox
                  checked={selectedGroups.includes(group.id)}
                  onChange={(e) => toggleGroupSelection(group.id, e.nativeEvent as unknown as React.MouseEvent)}
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    position: 'absolute',
                    p: 0,
                    top: 0,
                    left: 0,
                    zIndex: 5,
                  }}
                />
              )}
              <GroupCover
                allGroups={prefs?.groups || {}}
                books={books}
                group={group}
                onSelect={() => {
                  // Always navigate to the group, regardless of multiSelectMode
                  changePrefs({ activeGroupId: group.id });
                }}
                onDelete={() => handleDeleteGroup(group.id, group.name, group.parentId || '')}
                onEdit={() => handleEditGroup(group.id)}
                onMoveTo={() => handleMoveGroup(group.id)}
                multiSelectMode={multiSelectMode}
              />
            </Box>
          ))}

          {/* Then render books */}
          {getItemsInSelectedGroup(activeGroupId)
            .books.sort((a, b) => (b.timestamp || 0) - (a.timestamp || 0))
            .map((book: BookMeta) => (
              <Box
                key={book.id}
                sx={{
                  display: 'flex',
                  backgroundColor: getTheme() === 'dark' ? 'black' : 'white',
                  mx: 0,
                  px: 0,
                  flexGrow: 1,
                  position: 'relative',
                }}
              >
                {multiSelectMode && (
                  <Checkbox
                    checked={selectedBooks.includes(book.id)}
                    onChange={(e) => toggleBookSelection(book.id, e.nativeEvent as unknown as React.MouseEvent)}
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                      position: 'absolute',
                      p: 0,
                      top: 0,
                      left: 0,
                      zIndex: 5,
                    }}
                  />
                )}
                <MemoizedBookCover
                  book={book}
                  onDeleteBook={() => handleDeleteBook(book.id)}
                  userProgress={progressInfoRef.current?.[book.hash || '']}
                  fileInputRef={fileInputRef}
                  onReplaceFile={() => handleReplaceFileClick(book.id)}
                  onAddTranslatedVersion={() => handleAddTranslatedVersion(book.id)}
                  onResetBook={() => handleResetBook(book.id)}
                  onMoveTo={() => handleMoveBook(book.id)}
                  onSelect={() => {
                    if (multiSelectMode) {
                      toggleBookSelection(book.id);
                    } else {
                      openBook(book.id);
                    }
                  }}
                  userEmail={user?.email}
                  multiSelectMode={multiSelectMode}
                />
              </Box>
            ))}
        </Stack>

        {changelogOpen && <Changelog onClose={() => setChangelogOpen(false)} />}

        {/* Language Dialog */}
        <Dialog
          open={languageModalOpen}
          onClose={() => setLanguageModalOpen(false)}
          aria-labelledby='language-dialog-title'
        >
          <DialogTitle id='language-dialog-title'>{t('SELECT_LANGUAGE')}</DialogTitle>
          <DialogContent>
            <FormControl sx={{ minWidth: 200, marginTop: 2 }}>
              <InputLabel>{t('LANGUAGE')}</InputLabel>
              <Select
                label={t('LANGUAGE')}
                value={selectedLanguage}
                onChange={(e) => {
                  setSelectedLanguage(e.target.value);
                  handleLanguageSave(e.target.value);
                }}
              >
                {LANGUAGES.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    {lang.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
        </Dialog>

        {/* Create Group Dialog */}
        <StableDialog
          open={createGroupOpen}
          onClose={() => setCreateGroupOpen(false)}
          aria-labelledby='create-group-dialog-title'
        >
          <DialogTitle id='create-group-dialog-title'>{t('NEW_GROUP')}</DialogTitle>
          <DialogContent>
            <FormControl sx={{ minWidth: 200, marginTop: 2, width: '100%' }}>
              <Box
                component='form'
                onSubmit={(e) => {
                  e.preventDefault();
                  // Get value directly from the ref when submitting
                  const value = createGroupInputRef.current?.value || '';
                  handleCreateGroupsubmit(value);
                }}
              >
                <Stack direction='row' spacing={2}>
                  <TextField inputRef={createGroupInputRef} autoFocus label={t('NAME')} />
                  <Button type='submit' variant='contained' sx={{ ml: 2 }}>
                    {t('CREATE')}
                  </Button>
                </Stack>
              </Box>
            </FormControl>
          </DialogContent>
        </StableDialog>

        {/* Edit Group Dialog */}
        <StableDialog
          open={editGroupOpen}
          onClose={() => setEditGroupOpen(false)}
          aria-labelledby='edit-group-dialog-title'
          slots={{
            transition: Fade,
          }}
        >
          <DialogTitle id='edit-group-dialog-title'>{t('RENAME_GROUP')}</DialogTitle>
          <DialogContent>
            <FormControl sx={{ minWidth: 200, marginTop: 2, width: '100%' }}>
              <Box
                component='form'
                onSubmit={(e) => {
                  e.preventDefault();
                  // Get value directly from the ref when submitting
                  const value = renameGroupInputRef.current?.value || '';
                  handleEditGroupsubmit(value);
                }}
              >
                <Stack direction='row' spacing={2}>
                  <TextField
                    inputRef={renameGroupInputRef}
                    autoFocus
                    label={t('NAME')}
                    defaultValue={prefs?.groups?.[editingGroupId || '']?.name || ''}
                  />
                  <Button type='submit' variant='contained' sx={{ ml: 2 }}>
                    {t('SAVE')}
                  </Button>
                </Stack>
              </Box>
            </FormControl>
          </DialogContent>
        </StableDialog>

        {/* Add the organization dialog */}
        <Dialog
          open={organizationDialogOpen}
          onClose={handleCloseOrganizationDialog}
          maxWidth='sm'
          fullWidth
          slots={{
            transition: Zoom,
          }}
          slotProps={{
            transition: {
              timeout: 400,
              style: { transformOrigin: 'center top' },
            },
            paper: {
              sx: {
                borderRadius: 2,
                boxShadow: (theme) => theme.shadows[24],
              },
            },
          }}
          sx={{
            '& .MuiDialog-container > .MuiDialog-paper': {
              position: 'fixed',
              top: 0,
            },
          }}
        >
          <DialogTitle>{t('ORGANIZE_BOOKS')}</DialogTitle>
          <DialogContent>
            <DialogContentText variant='body1' sx={{ mb: 2 }}>
              {t('ORGANIZE_INSTRUCTIONS_1')}
            </DialogContentText>
            <DialogContentText variant='caption' sx={{ mb: 1 }}>
              {t('ORGANIZE_INSTRUCTIONS_2')}
            </DialogContentText>
            <TextField
              inputRef={organizationInstructionInputRef}
              autoFocus
              margin='dense'
              label={t('ORGANIZATION_INSTRUCTION_LABEL')}
              type='text'
              multiline
              fullWidth
              variant='outlined'
              defaultValue={prefs?.organizationInstruction || defaultOrganizationInstruction}
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseOrganizationDialog} color='primary'>
              {t('CANCEL')}
            </Button>
            <LoadingButton onClick={handleOrganizeBooks} color='primary' loading={isOrganizing}>
              {t('DO_IT')}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/* Move To Group Dialog */}
        <MoveToDialog
          open={moveToGroupOpen}
          onClose={() => {
            setMoveToGroupOpen(false);
            setBookToMove('');
            setGroupToMove('');
          }}
          onMove={(targetGroupId) => {
            if (multiSelectMode && (selectedBooks.length > 0 || selectedGroups.length > 0)) {
              handleMoveSelectedItems(targetGroupId);
            } else if (bookToMove) {
              handleMoveBookSubmit(targetGroupId);
            } else if (groupToMove) {
              handleMoveGroupSubmit(targetGroupId);
            }
          }}
          groups={prefs?.groups || {}}
          currentGroupId={
            multiSelectMode ? '' : bookToMove ? books[bookToMove]?.parentId || activeGroupId : groupToMove
          }
          isMovingBook={Boolean(bookToMove) || (multiSelectMode && selectedBooks.length > 0)}
          isMultiSelect={multiSelectMode}
          activeGroupId={activeGroupId}
          selectedItems={{
            books: selectedBooks.length,
            groups: selectedGroups.length,
          }}
        />

        {/* Upload Progress Dialog */}
        <Dialog open={uploadDialogOpen} aria-labelledby='upload-dialog-title' disableEscapeKeyDown>
          <DialogTitle id='upload-dialog-title'>{t('UPLOADING_BOOKS')}</DialogTitle>
          <DialogContent>
            <Box sx={{ width: '100%', minWidth: 300, my: 2 }}>
              <Typography variant='body2' color='text.secondary' gutterBottom>
                {t('PROCESSING_BOOK_COUNT', {
                  current: uploadProgress.current,
                  total: uploadProgress.total,
                })}
              </Typography>
              <LinearProgress
                variant='determinate'
                value={(uploadProgress.current / Math.max(uploadProgress.total, 1)) * 100}
                sx={{ height: 10, borderRadius: 5 }}
              />
              <Typography variant='body2' color='text.secondary' align='right' sx={{ mt: 1 }}>
                {Math.round((uploadProgress.current / Math.max(uploadProgress.total, 1)) * 100)}%
              </Typography>
              {currentFileName && (
                <Typography
                  variant='caption'
                  color='text.secondary'
                  sx={{
                    display: 'block',
                    mt: 2,
                    fontStyle: 'italic',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('IMPORTING_CURRENT_BOOK', { fileName: currentFileName })}
                </Typography>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    )
  );
};

export default BookList;
