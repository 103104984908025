import React, { createContext, useContext, useState, useEffect, useMemo, useCallback, type ReactNode } from 'react';
import { AppContext } from '../context/AppContext';

export type LangCode = string;

export interface TranslationsContextType {
  translations: Record<LangCode, Record<string, string>>;
  setLanguage: (lang: LangCode) => void;
  currentLanguage: LangCode;
  isLoading: boolean;
}

const initialTranslationsContext: TranslationsContextType = {
  translations: {},
  setLanguage: () => {},
  currentLanguage: 'en',
  isLoading: false,
};

export const TranslationsContext = createContext<TranslationsContextType>(initialTranslationsContext);

/**
 * TranslationsProvider component that dynamically loads language translation JSON files
 * from a data subdirectory. It always loads English (en.json) as fallback along with the active
 * language file (if different from English).
 *
 * @param children - The child React nodes.
 * @returns The provider wrapping children with translation data.
 */
export const TranslationsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { prefs } = useContext(AppContext);
  const [currentLanguage, setCurrentLanguage] = useState<LangCode>(
    localStorage.getItem('language') || navigator.language.slice(0, 2) || 'en'
  );
  const [translations, setTranslations] = useState<Record<LangCode, Record<string, string>>>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Determine the preferred language using user preferences or the browser language.
  const userLanguage = useMemo<LangCode | null>(() => {
    return prefs?.languageCode || null;
  }, [prefs]);

  /**
   * Updates the active language.
   *
   * @param lang - The new language code.
   */
  const setLanguage = useCallback((lang: LangCode): void => {
    setCurrentLanguage(lang);
    localStorage.setItem('language', lang);
  }, []);

  // Sync current language with user preferences.
  useEffect(() => {
    if (userLanguage && currentLanguage !== userLanguage) {
      setLanguage(userLanguage);
    }
  }, [userLanguage]);

  // Load translation files asynchronously: always load 'en.json' and, if needed, the current language file.
  useEffect(() => {
    /**
     * Loads translation JSON files for English and the active language.
     */
    const loadTranslations = async (): Promise<void> => {
      setIsLoading(true);

      const loadedTranslations: Record<LangCode, Record<string, string>> = {};

      try {
        // Always load English translations as fallback
        const enModule = await import(`../data/en.json`);
        loadedTranslations['en'] = enModule.default;

        // If the current language is not English, load its translation file
        if (currentLanguage !== 'en') {
          try {
            const langModule = await import(`../data/${currentLanguage}.json`);
            loadedTranslations[currentLanguage] = langModule.default;
          } catch (error) {
            console.error(`Error loading translations for ${currentLanguage}:`, error);
            loadedTranslations[currentLanguage] = {};
          }
        }

        setTranslations(loadedTranslations);
      } catch (error) {
        console.error('Error loading English translations:', error);
        setTranslations({});
      } finally {
        setIsLoading(false);
      }
    };

    loadTranslations();
  }, [currentLanguage]);

  const contextValue = useMemo<TranslationsContextType>(
    () => ({
      translations,
      setLanguage,
      currentLanguage,
      isLoading,
    }),
    [translations, setLanguage, currentLanguage, isLoading]
  );

  return (
    <TranslationsContext.Provider value={contextValue}>
      {children}
      {/* {isLoading && (
        <Box position='fixed' bottom={16} right={16}>
          <CircularProgress size={24} />
        </Box>
      )} */}
    </TranslationsContext.Provider>
  );
};

/**
 * Hook to obtain a translation function that only returns translations once they're loaded.
 * Returns a function that accepts a translation key and optional variables for dynamic substitution.
 * During loading, it returns empty strings to avoid UI flashing.
 *
 * @returns A function to translate keys with optional substitutions.
 */
export const useTranslation = (): ((key: string, variables?: Record<string, string | number>) => string) => {
  const { translations, currentLanguage, isLoading } = useContext(TranslationsContext);

  /**
   * Replaces placeholders (e.g., {name}) within the translation string with actual values.
   *
   * @param str - The translation string that may contain placeholders.
   * @param variables - An object containing replacement values.
   * @returns The formatted translation string.
   */
  const replaceVariables = (str: string, variables: Record<string, string | number>): string => {
    return str.replace(/{(\w+)}/g, (_, key) => {
      return variables[key] !== undefined ? String(variables[key]) : `{${key}}`;
    });
  };

  /**
   * Translates a key with optional variables.
   * Returns empty string while translations are loading to prevent UI flashing.
   *
   * @param key - The translation key.
   * @param variables - Optional variables for dynamic substitution.
   * @returns The translated string, or empty string while loading.
   */
  const t = useCallback(
    (key: string, variables?: Record<string, string | number>): string => {
      // Return empty string while loading to prevent UI flashing
      if (isLoading) {
        return '';
      }

      // Try current language, fallback to 'en', then the key itself
      const translation = translations[currentLanguage]?.[key] || translations['en']?.[key] || key;
      return variables ? replaceVariables(translation, variables) : translation;
    },
    [translations, currentLanguage, isLoading]
  );

  return t;
};
