import React, { useState } from 'react';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { Typography, Button, Box } from '@mui/material';
import { Rendition, type NavItem } from 'epubjs';
import type Section from 'epubjs/types/section';
import { guessCurrent } from '../utils/books';
import type { BookMeta, AnchorLocations, RichLocation } from '../types/book';
import { charsPerWord, locationSize } from '../config';
import type { EpubView } from './EpubView';

interface TocComponentProps {
  toc: NavItem[];
  setLocation: (location: string) => void;
  rendition?: Rendition;
  closeDrawer: () => void;
  book: BookMeta;
  goToPercentage: (percentage: number) => void;
  anchors?: AnchorLocations;
  readerRef: React.RefObject<EpubView | null>;
  chapterLocations?: RichLocation[];
}

type DisplayMode = 'percentage' | 'location' | 'wordCount';

/**
 * Renders the Table of Contents using MUI's SimpleTreeView component.
 * Items are collapsed by default, and display additional information based on the selected display mode.
 */
const TocComponent: React.FC<TocComponentProps> = ({
  toc,
  rendition,
  anchors,
  closeDrawer,
  book,
  goToPercentage,
  readerRef,
  chapterLocations,
}) => {
  const [globalMode, setGlobalMode] = useState<DisplayMode>('percentage');
  const numLocations = book.wordCount ? Math.ceil((book.wordCount * charsPerWord) / locationSize) : 0;

  /**
   * Toggles the global display mode for all TOC items.
   */
  const toggleGlobalMode = () => {
    setGlobalMode((prev) => (prev === 'percentage' ? 'location' : prev === 'location' ? 'wordCount' : 'percentage'));
  };

  /**
   * Finds the matching section for a given href.
   * @param href - The href to find the matching section.
   * @returns The matching Section or undefined.
   */
  const findMatchingSection = (href: string): Section | undefined => {
    return rendition?.book.spine.items.find((s: Section) =>
      s.href.endsWith(href.split('/').pop()?.split('#')[0] || '')
    );
  };

  /**
   * Calculates the percentage location of a given href within the book.
   * @param href - The href to calculate the percentage for.
   * @returns The percentage location.
   */
  const calculatePercentage = (href: string): number => {
    if (anchors) {
      const anchor = anchors[href];
      if (anchor && numLocations) {
        return (anchor.location / numLocations) * 100;
      }
    }

    if (!rendition) return 0;
    const section = findMatchingSection(href);
    if (!section) return 0;
    const index = rendition.book.spine.items.indexOf(section);
    if (chapterLocations) {
      const chapterLocation = chapterLocations[index];
      if (chapterLocation) {
        return (chapterLocation.location / numLocations) * 100;
      }
    }

    return 0;
  };

  /**
   * Gets the numeric location for a given href.
   * @param href - The href to get the location for.
   * @returns The numeric location of the item.
   */
  const getItemLocation = (href: string): number => {
    if (anchors) {
      const anchor = anchors[href];
      if (anchor) {
        return anchor.location;
      }
    }

    if (chapterLocations) {
      const section = findMatchingSection(href);
      if (section) {
        const index = rendition?.book.spine.items.indexOf(section);
        if (index !== undefined && index >= 0) {
          return chapterLocations[index]?.location || 0;
        }
      }
    }

    return 0;
  };

  /**
   * Recursively finds the next item in the TOC hierarchy.
   * @param items - The current list of NavItems.
   * @param currentPath - The path indices leading to the current item.
   * @returns An object containing the next href and whether it's a parent endpoint.
   */
  const findNextHref = (
    items: NavItem[],
    currentPath: number[]
  ): { href: string | undefined; isParentEndpoint: boolean } => {
    let currentItems = items;
    for (let i = 0; i < currentPath.length; i++) {
      const index = currentPath[i];
      if (index >= currentItems.length) return { href: undefined, isParentEndpoint: true };
      const currentItem = currentItems[index];
      if (i === currentPath.length - 1) {
        // Try to find the next sibling
        if (index + 1 < currentItems.length) {
          return { href: currentItems[index + 1].href, isParentEndpoint: false };
        }
        // If no next sibling, we need to go up a level
        return { href: undefined, isParentEndpoint: true };
      }
      if (currentItem.subitems) {
        currentItems = currentItem.subitems;
      } else {
        break;
      }
    }
    return { href: undefined, isParentEndpoint: true };
  };

  /**
   * Calculates the word count for a given href based on its location.
   * @param href - The href of the item.
   * @param path - The path indices leading to the item.
   * @returns The calculated word count.
   */
  const getWordCount = (href: string, path: number[]): number => {
    const currentLocation = getItemLocation(href);
    const { href: nextHref, isParentEndpoint } = findNextHref(toc, path);
    let nextLocation: number;

    if (nextHref) {
      nextLocation = getItemLocation(nextHref);
    } else if (isParentEndpoint) {
      // If it's the last item in a subsection, find the parent's next sibling
      const parentPath = path.slice(0, -1);
      const { href: parentNextHref } = findNextHref(toc, parentPath);
      nextLocation = parentNextHref ? getItemLocation(parentNextHref) : numLocations;
    } else {
      // If it's the last item overall, use the total word count
      nextLocation = numLocations;
    }

    if (currentLocation !== undefined && nextLocation !== undefined && book.wordCount && numLocations) {
      const wordDiff = nextLocation - currentLocation;
      if (wordDiff < 0) {
        console.warn(`Negative word difference for href: ${href}`);
        return 0;
      }
      return Math.round((wordDiff / numLocations) * book.wordCount);
    }

    // Fallback to chapter-level word count if available
    const section = findMatchingSection(href);
    if (section && book.chapterWordCount) {
      const sectionIndex = rendition?.book.spine.items.indexOf(section);
      if (sectionIndex !== undefined && sectionIndex >= 0) {
        return book.chapterWordCount[sectionIndex] || 0;
      }
    }

    return 0;
  };

  /**
   * Renders the additional value (percentage, location, or word count) for a TOC item.
   * @param item - The navigation item.
   * @param path - The path indices leading to the item.
   * @returns The JSX element displaying the additional value.
   */
  const renderToggleValue = (item: NavItem, path: number[]) => {
    let value: string | number;
    switch (globalMode) {
      case 'percentage':
        value = `${calculatePercentage(item.href).toFixed(1)}%`;
        break;
      case 'location':
        value = getItemLocation(item.href);
        break;
      case 'wordCount':
        value = `${getWordCount(item.href, path)}w`;
        break;
      default:
        return null;
    }

    return (
      <Typography
        variant='body2'
        onClick={(e) => {
          e.stopPropagation();
          toggleGlobalMode();
        }}
        sx={{ cursor: 'pointer', flexShrink: 0, minWidth: '60px', textAlign: 'right' }}
      >
        {value}
      </Typography>
    );
  };

  /**
   * Navigates to the given href location.
   * @param href - The href to navigate to.
   */
  const gotoHref = (href: string) => {
    if (anchors) {
      const anchor = anchors[href];
      if (anchor) {
        // setLocation(anchor.epubcfi);
        console.log('gotoHref', href, anchor);
        readerRef.current?.display(anchor.epubcfi);
        closeDrawer();
        return;
      } else {
        console.warn('Anchor not found:', href);
      }
    }

    const hrefId = href.split('#')[1];
    const section = findMatchingSection(href);
    if (!section) {
      console.warn('Section not found:', href);
      return;
    }
    let newLocation = section.href;
    if (hrefId) {
      newLocation = `${section.href}#${hrefId}`;
    }
    console.log('newLocation = ', newLocation);
    // setLocation(newLocation);
    readerRef.current?.display(newLocation);
    closeDrawer();
  };

  /**
   * Handles the click event on a TreeItem.
   * @param item - The navigation item.
   * @param href - The href to navigate to.
   */
  const handleItemClick = (item: NavItem, _href: string) => {
    gotoHref(item.href);
  };

  /**
   * Finds the NavItem in the toc array based on the nodeId.
   * @param items - The array of NavItems to search.
   * @param nodeId - The nodeId to match.
   * @returns The matching NavItem or undefined.
   */
  const findItemByNodeId = (items: NavItem[], nodeId: string): NavItem | undefined => {
    const indices = nodeId.split('-').slice(2).map(Number);
    let currentItems = items;

    for (const index of indices) {
      if (index >= currentItems.length) {
        return undefined;
      }
      const currentItem = currentItems[index];
      if (index === indices[indices.length - 1]) {
        return currentItem;
      }
      if (!currentItem.subitems) {
        return undefined;
      }
      currentItems = currentItem.subitems;
    }

    return undefined;
  };

  /**
   * Renders a TreeItem for the SimpleTreeView.
   * @param item - The navigation item.
   * @param nodeId - The unique node ID.
   * @param path - The path indices leading to the item.
   * @returns The TreeItem JSX element.
   */
  const renderTree = (item: NavItem, nodeId: string, path: number[]) => (
    <TreeItem
      key={nodeId}
      itemId={nodeId}
      label={
        <Box sx={{ display: 'flex', direction: 'row', width: '100%', alignItems: 'top' }}>
          <Typography
            variant='body1'
            sx={{ justifyContent: 'flex-start', flexGrow: 1, width: '100%', textAlign: 'left' }}
          >
            {item.label}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              direction: 'row',
              justifyContent: 'flex-end',
              textAlign: 'right',
            }}
          >
            {renderToggleValue(item, path)}
          </Box>
        </Box>
      }
    >
      {item.subitems &&
        item.subitems.map((subitem, subIndex) => renderTree(subitem, `${nodeId}-${subIndex}`, [...path, subIndex]))}
    </TreeItem>
  );

  return (
    <>
      <Button
        variant='outlined'
        onClick={() => {
          goToPercentage(guessCurrent(book.settings.updates, book.settings.progress));
          closeDrawer();
        }}
        sx={{ margin: '16px 0' }}
      >
        Find my spot
      </Button>
      <SimpleTreeView
        sx={{ height: '100%', flexGrow: 1, overflowY: 'auto', width: '100%', maxWidth: '600px' }}
        expansionTrigger='iconContainer'
        onItemExpansionToggle={(event, _nodeId) => {
          event.stopPropagation();
        }}
        onItemClick={(_event, nodeId) => {
          const item = findItemByNodeId(toc, nodeId);
          if (item) {
            handleItemClick(item, item.href);
          }
        }}
      >
        {toc.map((item, index) => renderTree(item, `toc-item-${index}`, [index]))}
      </SimpleTreeView>
    </>
  );
};

export default TocComponent;
