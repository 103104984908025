/**
 * Utility functions for managing book groups
 */
import { v4 as uuidv4 } from 'uuid';
import type { Group, GroupCollection, GroupItem, BookMeta, BookCollection } from '../types/book';
import { getBooks, updateBooksCache } from './books';

/**
 * Type guard to check if an item is a Group
 * @param item - The item to check
 * @returns True if the item is a Group, false otherwise
 */
export const isGroup = (item: GroupItem): item is Group => {
  return 'name' in item && 'createdAt' in item && 'updatedAt' in item && !('title' in item) && !('settings' in item);
};

/**
 * Creates a new group
 * @param groups - The collection of groups
 * @param name - The name of the group
 * @param description - Description of the group
 * @param parentId - Optional ID of parent group
 * @returns The updated groups collection
 */
export const createGroup = (
  groups: GroupCollection,
  name: string,
  description: string,
  parentId?: string
): GroupCollection => {
  const now = Date.now();
  const newGroup: Group = {
    id: uuidv4(),
    name,
    createdAt: now,
    updatedAt: now,
    parentId,
    description,
  };

  let updatedGroups = {
    ...groups,
    [newGroup.id]: newGroup,
  };

  return updatedGroups;
};

/**
 * Updates a group's properties
 * @param groups - The collection of groups
 * @param groupUpdate - The properties to update
 * @returns The updated groups collection
 */
export const updateGroup = (groups: GroupCollection, groupUpdate: Partial<Group> & { id: string }): GroupCollection => {
  const { id } = groupUpdate;
  const group = groups[id];

  // Group doesn't exist
  if (!group) return groups;

  // Create updated group with new values
  const updatedGroup: Group = {
    ...group,
    ...groupUpdate,
    updatedAt: Date.now(),
  };

  // Update the groups collection
  const updatedGroups = {
    ...groups,
    [id]: updatedGroup,
  };

  // Handle parent ID changes if needed
  const oldParentId = group.parentId;
  const newParentId = groupUpdate.parentId;

  if (newParentId !== oldParentId && newParentId !== undefined) {
    // Verify the new parent exists
    if (!groups[newParentId]) {
      // If new parent doesn't exist, revert to original parent
      updatedGroups[id] = {
        ...updatedGroup,
        parentId: oldParentId,
      };
    }
    // New parent exists and is different from old parent, no other action needed
    // since the parentId relationship is maintained by the group itself
  }

  return updatedGroups;
};

/**
 * Deletes a group and updates parent references
 * @param groups - The collection of groups
 * @param books - The collection of books
 * @param id - ID of the group to delete
 * @returns The updated groups collection
 */
export const deleteGroup = (groups: GroupCollection, books: BookCollection, id: string): GroupCollection => {
  // If group doesn't exist, return unchanged
  if (!groups[id]) return groups;

  let updatedGroups = { ...groups };
  const groupToDelete = groups[id];

  // Find all child groups and reassign their parentId to the deleted group's parent
  Object.values(groups).forEach((group) => {
    if (group.parentId === id) {
      updatedGroups = {
        ...updatedGroups,
        [group.id]: {
          ...group,
          parentId: groupToDelete.parentId,
          updatedAt: Date.now(),
        },
      };
    }
  });

  let updatedBooks = { ...books };
  Object.values(books).forEach((book) => {
    if (book.parentId === id) {
      updatedBooks[book.id] = { ...book, parentId: groupToDelete.parentId };
    }
  });
  updateBooksCache(updatedBooks);
  // Remove the group itself
  const { [id]: removed, ...rest } = updatedGroups;

  return rest;
};

/**
 * Adds an item to a group by setting its parentId
 * @param groups - The collection of groups
 * @param books - The collection of books
 * @param groupId - ID of the group to add to
 * @param itemId - ID of the item to add
 * @returns The updated groups collection
 */
export const addItemToGroup = (
  groups: GroupCollection,
  books: BookCollection,
  groupId: string,
  itemId: string
): { groups: GroupCollection; books: BookCollection } => {
  // Group doesn't exist
  if (!groups[groupId]) return { groups, books };

  // Check if item is a group
  if (groups[itemId]) {
    // Prevent circular references (don't add a parent to its child)
    let currentGroupId = groupId;
    while (currentGroupId) {
      if (currentGroupId === itemId) {
        // Circular reference detected
        return { groups, books };
      }
      currentGroupId = groups[currentGroupId]?.parentId || '';
    }

    // Update the group's parent ID
    return {
      groups: updateGroup(groups, {
        id: itemId,
        parentId: groupId,
      }),
      books,
    };
  }
  // Item is a book
  else if (books[itemId]) {
    // Update the book's parent ID
    const updatedBooks = {
      ...books,
      [itemId]: {
        ...books[itemId],
        parentId: groupId,
      },
    };
    return { groups, books: updatedBooks };
  }

  // Item not found
  return { groups, books };
};

/**
 * Adds multiple items to a group
 * @param groups - The collection of groups
 * @param books - The collection of books
 * @param groupId - ID of the group to add to
 * @param itemIds - IDs of the items to add
 * @returns The updated groups and books collections
 */
export const addItemsToGroup = (
  groups: GroupCollection,
  books: BookCollection,
  groupId: string,
  itemIds: string[]
): { groups: GroupCollection; books: BookCollection } => {
  return itemIds.reduce((result, itemId) => addItemToGroup(result.groups, result.books, groupId, itemId), {
    groups,
    books,
  });
};

/**
 * Removes an item from a group by clearing its parentId
 * @param groups - The collection of groups
 * @param books - The collection of books
 * @param groupId - ID of the group to remove from
 * @param itemId - ID of the item to remove
 * @returns The updated groups and books collections
 */
export const removeItemFromGroup = (
  groups: GroupCollection,
  books: BookCollection,
  groupId: string,
  itemId: string
): { groups: GroupCollection; books: BookCollection } => {
  // Group doesn't exist
  if (!groups[groupId]) return { groups, books };

  // Check if item is a group with this group as parent
  if (groups[itemId] && groups[itemId].parentId === groupId) {
    // Remove the parent reference
    return {
      groups: updateGroup(groups, {
        id: itemId,
        parentId: undefined,
      }),
      books,
    };
  }
  // Check if item is a book with this group as parent
  else if (books[itemId] && books[itemId].parentId === groupId) {
    // Remove the parent reference
    const updatedBooks = {
      ...books,
      [itemId]: {
        ...books[itemId],
        parentId: undefined,
      },
    };
    return { groups, books: updatedBooks };
  }

  // Item not in group or not found
  return { groups, books };
};

/**
 * Removes multiple items from a group
 * @param groups - The collection of groups
 * @param books - The collection of books
 * @param groupId - ID of the group to remove from
 * @param itemIds - IDs of the items to remove
 * @returns The updated groups and books collections
 */
export const removeItemsFromGroup = (
  groups: GroupCollection,
  books: BookCollection,
  groupId: string,
  itemIds: string[]
): { groups: GroupCollection; books: BookCollection } => {
  return itemIds.reduce((result, itemId) => removeItemFromGroup(result.groups, result.books, groupId, itemId), {
    groups,
    books,
  });
};

/**
 * Gets all books in a group and its subgroups
 * @param groups - The collection of groups
 * @param books - The collection of books
 * @param groupId - ID of the group to get books from
 * @returns Array of books in the group and subgroups
 */
export const getAllBooksInGroup = (
  groups: GroupCollection | undefined,
  books: BookCollection | undefined,
  groupId: string
): BookMeta[] => {
  if (!groups || !books || !groups[groupId]) return [];

  const result: BookMeta[] = [];
  const processed = new Set<string>();

  // Helper function to recursively collect books from a group and its sub-groups
  const collectBooks = (g: Group) => {
    // Get books that have this group as their parent
    Object.values(books).forEach((book) => {
      if (book.parentId === g.id && !processed.has(book.id)) {
        result.push(book);
        processed.add(book.id);
      }
    });

    // Process child groups
    Object.values(groups).forEach((childGroup) => {
      if (childGroup.parentId === g.id && !processed.has(childGroup.id)) {
        processed.add(childGroup.id);
        collectBooks(childGroup);
      }
    });
  };

  // Start collection from the specified group
  collectBooks(groups[groupId]);
  return result;
};

/**
 * Gets all items (books and groups) directly in a group (no recursion)
 * @param groups - The collection of groups
 * @param books - The collection of books
 * @param groupId - ID of the group to get items from
 * @returns Array of items in the group
 */
export const getItemsInGroup = (
  groups: GroupCollection | undefined,
  books: BookCollection | undefined,
  groupId: string
): GroupItem[] => {
  if (!groups || !books || !groups[groupId]) return [];

  const result: GroupItem[] = [];

  // Add subgroups
  Object.values(groups).forEach((group) => {
    if (group.parentId === groupId) {
      result.push(group);
    }
  });

  // Add books
  Object.values(books).forEach((book) => {
    if (book.parentId === groupId) {
      result.push(book);
    }
  });

  return result;
};

/**
 * Initializes a default groups structure
 * @param t - Translation function
 * @returns Initial groups collection
 */
export const initializeDefaultGroups = (t: Function): GroupCollection => {
  // Root group (My Library)
  const rootId = 'root';
  const root: Group = {
    id: rootId,
    name: t('MY_LIBRARY'),
    description: t('MY_LIBRARY_DESCRIPTION'),
    parentId: undefined,
    createdAt: Date.now(),
    updatedAt: Date.now(),
  };

  let groups = {
    [rootId]: root,
  } as GroupCollection;
  groups = createGroup(groups, t('ACTIVE'), t('ACTIVE_DESCRIPTION'), rootId);
  groups = createGroup(groups, t('ARCHIVED'), t('ARCHIVED_DESCRIPTION'), rootId);

  return groups;
};

/**
 * Migrates legacy shelves to the new groups system
 * @param t - Translation function
 * @returns Migrated groups collection
 */
export const migrateShelvesToGroups = (t: Function): GroupCollection => {
  const defaultGroups = initializeDefaultGroups(t);

  const books = getBooks();

  // Find the "Active" and "Archive" group IDs
  let activeGroupId: string | undefined;
  let archiveGroupId: string | undefined;

  // Iterate through groups to find the Active and Archive groups by their names
  Object.values(defaultGroups).forEach((group) => {
    if (group.name === t('ACTIVE')) {
      activeGroupId = group.id;
    } else if (group.name === t('ARCHIVED')) {
      archiveGroupId = group.id;
    }
  });

  // Update each book's parentId based on its location
  const updatedBooks: BookCollection = { ...books };

  let activeCount = 0;
  let archiveCount = 0;
  let trashCount = 0;

  Object.values(updatedBooks).forEach((book) => {
    if (book.location === 'trash') {
      trashCount++;
    } else if (book.location === 'active' && activeGroupId) {
      activeCount++;
      book.parentId = activeGroupId;
    } else if ((book.location === 'archived' || book.archived || book.location === 'archive') && archiveGroupId) {
      archiveCount++;
      book.parentId = archiveGroupId;
    }
  });

  // Save the updated books
  updateBooksCache(updatedBooks);

  console.log(
    `Migrated ${activeCount} books to ${t('ACTIVE')} and ${archiveCount} books to ${t(
      'ARCHIVED'
    )}, ignored ${trashCount} books in trash`
  );

  return defaultGroups;
};

/**
 * Returns all groups sorted by name
 * @param groups - The collection of groups
 * @returns Array of all groups sorted by name
 */
export const getAllGroupsSorted = (groups: GroupCollection | undefined): Group[] => {
  if (!groups) return [];
  return Object.values(groups).sort((a, b) => {
    // Root group always first
    if (a.parentId === undefined) return -1;
    if (b.parentId === undefined) return 1;

    // Then sort by parent ID
    if (a.parentId !== b.parentId) {
      return a.parentId.localeCompare(b.parentId);
    }

    // Then by order if available
    if (a.order !== undefined && b.order !== undefined) {
      return a.order - b.order;
    }

    // Finally by name
    return a.name.localeCompare(b.name);
  });
};

/**
 * Gets the root group (group with no parent)
 * @param groups - The collection of groups
 * @returns The root group, or undefined if not found
 */
export const getRootGroup = (groups: GroupCollection | undefined): Group | undefined => {
  if (!groups) return undefined;
  return groups['root'] || Object.values(groups).find((g) => g.parentId === undefined);
};

/**
 * Gets all group IDs
 * @param groups - The collection of groups
 * @returns Array of all group IDs
 */
export const getAllGroupIds = (groups: GroupCollection | undefined): string[] => {
  if (!groups) return [];
  return Object.keys(groups);
};

/**
 * Gets all items (groups and books) that belong to a specific group by checking parentId relationships
 * @param groups - The collection of groups
 * @param books - The collection of books
 * @param groupId - The ID of the group to get items for
 * @returns Array of items (groups and books) that belong to the specified group
 */
export const getGroupChildren = (
  groups: GroupCollection | undefined,
  books: BookCollection | undefined,
  groupId: string
): GroupItem[] => {
  if (!groups) return [];

  const result: GroupItem[] = [];

  // Add child groups (groups that have this group as their parent)
  Object.values(groups).forEach((group) => {
    if (group.parentId === groupId) {
      result.push(group);
    }
  });

  // Add books that have this group as their parent
  if (books) {
    Object.values(books).forEach((book) => {
      if (book.parentId === groupId) {
        result.push(book);
      }
    });
  }

  return result;
};
