import { useEffect } from 'react';

/**
 * A component that performs a full browser redirect to a specified URL.
 *
 * @param props - The component props.
 * @param props.url - The external URL to navigate to.
 * @returns null
 */
export const RedirectExternal = ({ url }: { url: string }): null => {
  useEffect(() => {
    // Force a full page reload to navigate to the external URL.
    window.location.replace(url);
  }, [url]);

  return null;
};
