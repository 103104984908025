import { createTheme } from '@mui/material/styles';

export const chooseTheme = (effectiveTheme: 'light' | 'dark') => {
  return createTheme({
    typography: {
      fontFamily: ['Lato', 'Noto Sans', 'sans-serif'].join(','),
    },
    palette: {
      mode: effectiveTheme,
      ...(effectiveTheme === 'light'
        ? {
            primary: { main: '#3f51b5' },
            secondary: { main: '#f50057' },
            background: {
              default: '#efefef',
              paper: '#f5f5f5',
            },
            text: {
              primary: '#333',
              secondary: '#666',
            },
            action: {
              active: 'rgba(0, 0, 0, 0.7)',
              hover: 'rgba(0, 0, 0, 0.1)',
              selected: 'rgba(0, 0, 0, 0.15)',
            },
          }
        : {
            primary: { main: '#5c6bc0' },
            secondary: { main: '#c9d336' },
            background: {
              default: '#000000',
              paper: '#121212',
            },
            text: {
              primary: '#ddd',
              secondary: '#999',
            },
            action: {
              active: 'rgba(255, 255, 255, 0.8)',
              hover: 'rgba(255, 255, 255, 0.1)',
              selected: 'rgba(255, 255, 255, 0.16)',
            },
          }),
    },
    components: {
      MuiSnackbar: {
        styleOverrides: {
          root: {
            '& .MuiSnackbarContent-root': {
              backgroundColor: effectiveTheme === 'light' ? '#e5e5f0' : '#333',
              color: effectiveTheme === 'light' ? '#333' : '#ddd',
            },
          },
        },
      },
    },
  });
};
