import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Fade,
  Grow,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SchoolIcon from '@mui/icons-material/School';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SettingsIcon from '@mui/icons-material/Settings';
import HighlightIcon from '@mui/icons-material/Highlight';
import TranslateIcon from '@mui/icons-material/Translate';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import IntroductionTour from './IntroductionTour';
import type { IntroductionSlide } from './IntroductionTour';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import FolderIcon from '@mui/icons-material/Folder';
import InventoryIcon from '@mui/icons-material/Inventory';

/**
 * Maps tutorial IDs to their corresponding icon components
 */
const TUTORIAL_ICONS: Record<string, React.ReactElement> = {
  basics: <AutoStoriesIcon />,
  organization: <InventoryIcon />,
  menu: <AirportShuttleIcon />,
  groups: <FolderIcon />,
  settings: <SettingsIcon />,
  highlights: <HighlightIcon />,
  language: <TranslateIcon />,
  ai: <SmartToyIcon />,
};

/**
 * Interface for a tutorial definition
 * @interface Tutorial
 * @property {string} id - Unique identifier for the tutorial
 * @property {string} name - Display name of the tutorial
 * @property {string} description - Short description of the tutorial
 * @property {IntroductionSlide[]} slides - Slides to display in the tutorial
 */
export interface Tutorial {
  id: string;
  name: string;
  description: string;
  slides: IntroductionSlide[];
}

/**
 * Props for the TutorialSelector component
 * @interface TutorialSelectorProps
 * @property {boolean} open - Whether the tutorial selector is open
 * @property {() => void} onClose - Callback when the tutorial selector is closed
 * @property {Tutorial[]} tutorials - List of available tutorials
 */
interface TutorialSelectorProps {
  open: boolean;
  onClose: () => void;
  tutorials: Tutorial[];
}

/**
 * Component that displays a modal with tutorial options and launches the selected tutorial
 * Uses transitions for smooth visual experience when selecting and completing tutorials
 *
 * @param {TutorialSelectorProps} props - Component props
 * @returns {JSX.Element} TutorialSelector component
 */
const TutorialSelector: React.FC<TutorialSelectorProps> = ({ open, onClose, tutorials }) => {
  const [selectedTutorial, setSelectedTutorial] = useState<Tutorial | null>(null);
  const [tutorialVisible, setTutorialVisible] = useState(false);

  // Whether the selector list should be visible
  const selectorVisible = open;

  const handleSelectTutorial = (tutorial: Tutorial) => {
    setSelectedTutorial(tutorial);
    setTutorialVisible(true);
  };

  const handleCloseTutorial = () => {
    // Delay clearing the selected tutorial to allow for fade out transition
    setTutorialVisible(false);
    setTimeout(() => {
      setSelectedTutorial(null);
    }, 500);
  };

  const handleCloseSelector = () => {
    // Only close the entire component if no tutorial is active
    if (!tutorialVisible) {
      onClose();
    }
  };

  const handleFinalClose = () => {
    handleCloseTutorial();
  };

  return (
    <>
      <Dialog
        open={selectorVisible}
        onClose={handleCloseSelector}
        fullWidth
        maxWidth='sm'
        TransitionComponent={Grow}
        TransitionProps={{
          timeout: 500,
          style: { transformOrigin: 'center center' },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Select a Tutorial
          <IconButton
            aria-label='close'
            onClick={handleCloseSelector}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <List sx={{ pt: 0 }}>
            {tutorials.map((tutorial, index) => (
              <Grow
                key={tutorial.id}
                in={selectorVisible}
                timeout={1000}
                style={{
                  transitionDelay: `${index * 100}ms`,
                }}
              >
                <ListItem disableGutters>
                  <ListItemButton onClick={() => handleSelectTutorial(tutorial)}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'primary.main' }}>{TUTORIAL_ICONS[tutorial.id] || <SchoolIcon />}</Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={tutorial.name} secondary={tutorial.description} />
                  </ListItemButton>
                </ListItem>
              </Grow>
            ))}
          </List>
        </DialogContent>
      </Dialog>

      {selectedTutorial && (
        <Fade in={tutorialVisible} timeout={{ enter: 500, exit: 500 }}>
          <Box>
            <IntroductionTour open={tutorialVisible} onClose={handleFinalClose} slides={selectedTutorial.slides} />
          </Box>
        </Fade>
      )}
    </>
  );
};

export default TutorialSelector;
