/// <reference types="vite/client" />

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Reader } from './components/Reader';
import { ThemeProvider, type Theme } from '@mui/material/styles';
import BookList from './components/BookList';
import HandleShare from './components/HandleShare';
import { ErrorBoundary } from 'react-error-boundary';
import React, { useEffect, useState, useContext } from 'react';
import { chooseTheme } from './theme';
import { AppContext, AppProvider } from './context/AppContext';
import { Snackbar } from '@mui/material';
import Loading from './components/Loading';
import { TranslationsProvider } from './utils/i18n';
import LoginPage from './components/LoginPage';
import { RedirectExternal } from './components/RedirectExternal';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  React.useEffect(() => {
    // Automatically reset the error boundary and reload the page
    if (resetErrorBoundary) {
      setTimeout(() => {
        resetErrorBoundary();
        window.location.reload();
      }, 3000);
    }
  }, [resetErrorBoundary]);

  return (
    <div role='alert'>
      <p>Crap. Hang on, reloading the page...</p>
      <pre>{error.message}</pre>
    </div>
  );
}

const ThemedApp = () => {
  const { effectiveTheme, snackbarMessage, setSnackbarMessage, localForageLoaded, user } = useContext(AppContext);
  const [theme, setTheme] = useState<Theme | undefined>();
  const [loadingTimeout, setLoadingTimeout] = useState(false);

  useEffect(() => {
    // Only start timeout if we're still waiting for localForage or theme
    if (localForageLoaded && theme) {
      return;
    }

    // Add timeout to prevent infinite loading
    const timeoutId = setTimeout(() => {
      setLoadingTimeout(true);
      console.error('Loading timeout reached - forcing app render');
      // Force default theme if needed
      if (!theme) {
        setTheme(chooseTheme('light'));
      }
    }, 5000); // 5 second timeout

    return () => clearTimeout(timeoutId);
  }, [localForageLoaded, theme]); // Add dependencies to re-run effect when these change

  useEffect(() => {
    if (effectiveTheme) {
      setTheme(chooseTheme(effectiveTheme));
    }
  }, [effectiveTheme]);

  useEffect(() => {
    if (snackbarMessage !== null) {
      console.log('snackbar message set to', snackbarMessage);
    }
  }, [snackbarMessage]);

  return loadingTimeout || (localForageLoaded && theme) ? (
    <ThemeProvider theme={theme || chooseTheme('light')}>
      <div className='relative w-full bg-stone-100 dark:bg-black' style={{ margin: 0 }}>
        <div className='max-w-6xl mx-auto grid grid-cols-1' style={{ margin: 0 }}>
          <main>
            <Routes>
              <Route path='/about' element={<RedirectExternal url={`${import.meta.env.BASE_URL}about/`} />} />
              <Route path='/tos' element={<RedirectExternal url={`${import.meta.env.BASE_URL}tos/`} />} />
              <Route path='/privacy' element={<RedirectExternal url={`${import.meta.env.BASE_URL}privacy/`} />} />
              <Route
                path='/handle_epub'
                element={
                  user ? (
                    <HandleShare setSnackbarMessage={setSnackbarMessage} user={user} />
                  ) : (
                    <Navigate to='/login' replace />
                  )
                }
              />
              <Route path='/:cmd?' element={user ? <BookList /> : <Navigate to='/login' replace />} />{' '}
              <Route path='/read/:id/:uriLocation?' element={user ? <Reader /> : <Navigate to='/login' replace />} />{' '}
              <Route path='/login' element={user ? <Navigate to='/' replace /> : <LoginPage />} />
            </Routes>
          </main>
        </div>
      </div>
      {Boolean(snackbarMessage && snackbarMessage.text) && (
        <Snackbar
          open={Boolean(snackbarMessage)}
          autoHideDuration={snackbarMessage?.duration || 10000}
          onClose={() => setSnackbarMessage(null)}
          message={snackbarMessage?.text}
        />
      )}
    </ThemeProvider>
  ) : (
    <Loading />
  );
};

const App = () => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Reset the state of your application so the error doesn't happen again
      }}
    >
      <AppProvider>
        <TranslationsProvider>
          <BrowserRouter basename={import.meta.env.BASE_URL}>
            <ThemedApp />
          </BrowserRouter>
        </TranslationsProvider>
      </AppProvider>
    </ErrorBoundary>
  );
};

export default App;
