import { useState, useCallback } from 'react';
import { getAllTutorials } from '../data/tutorials';
import type { Tutorial } from '../components/TutorialSelector';

/**
 * Hook for managing tutorial selection and display
 *
 * @returns {Object} Tutorial management functions and state
 */
export const useTutorials = () => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const [tutorials] = useState<Tutorial[]>(getAllTutorials());

  /**
   * Open the tutorial selector modal
   */
  const openTutorialSelector = useCallback(() => {
    setIsSelectorOpen(true);
  }, []);

  /**
   * Close the tutorial selector modal
   */
  const closeTutorialSelector = useCallback(() => {
    setIsSelectorOpen(false);
  }, []);

  return {
    isSelectorOpen,
    tutorials,
    openTutorialSelector,
    closeTutorialSelector,
  };
};

export default useTutorials;
