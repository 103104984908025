import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography, Dialog, DialogContent, Paper, Fade } from '@mui/material';
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, PrevButton, NextButton } from './CarouselButtons';

/**
 * Props for the IntroductionTour component
 * @interface IntroductionTourProps
 * @property {boolean} open - Controls whether the tour dialog is displayed
 * @property {() => void} onClose - Callback function when tour is closed
 * @property {IntroductionSlide[]} slides - Array of slide content to display in the tour
 * @property {boolean} [showOnFirstVisit=false] - Whether to automatically show on first visit
 * @property {string} [storageKey='intro_tour_completed'] - Local storage key to track tour completion
 */
interface IntroductionTourProps {
  open: boolean;
  onClose: () => void;
  slides: IntroductionSlide[];
  showOnFirstVisit?: boolean;
  storageKey?: string;
}

/**
 * Individual slide content for the introduction tour
 * @interface IntroductionSlide
 * @property {string} title - Title of the slide
 * @property {string} content - Main content text
 * @property {string} [imageSrc] - Optional image to display
 * @property {React.ReactNode} [customContent] - Optional custom content for more complex slides
 */
export interface IntroductionSlide {
  title: string;
  content: string;
  imageSrc?: string;
  customContent?: React.ReactNode;
}

/**
 * A component that provides a multi-step introduction/help screen using a carousel
 * with full-screen images and user-friendly text overlay
 *
 * @param {IntroductionTourProps} props - Component props
 * @returns {JSX.Element} IntroductionTour component
 */
export const IntroductionTour: React.FC<IntroductionTourProps> = ({
  open,
  onClose,
  slides,
  showOnFirstVisit = false,
  storageKey = 'intro_tour_completed',
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  // Track if we're on the last slide
  const isLastSlide = selectedIndex === scrollSnaps.length - 1;
  const isFirstSlide = selectedIndex === 0;

  // Initialize carousel and handle updates
  const onInit = useCallback((emblaApi: any) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: any) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);

    return () => {
      emblaApi.off('reInit', onInit);
      emblaApi.off('reInit', onSelect);
      emblaApi.off('select', onSelect);
    };
  }, [emblaApi, onInit, onSelect]);

  // Handle automatic display on first visit
  useEffect(() => {
    if (showOnFirstVisit && !localStorage.getItem(storageKey)) {
      // Don't set storage item here - we'll do that when they complete the tour
    }
  }, [showOnFirstVisit, storageKey]);

  // Handle the completion of the tour
  const handleLastSlideAction = () => {
    localStorage.setItem(storageKey, 'true');
    onClose();
  };

  const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onClose}
      aria-labelledby='introduction-tour-title'
      TransitionComponent={Fade}
      TransitionProps={{
        timeout: 400,
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#000000',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <DialogContent sx={{ p: 0, height: '100%', position: 'relative', overflow: 'hidden' }}>
        <Box className='embla' sx={{ overflow: 'hidden', height: '100%' }} ref={emblaRef}>
          <Box
            className='embla__container'
            sx={{
              display: 'flex',
              height: '100%',
            }}
          >
            {slides.map((slide, index) => (
              <Box
                className='embla__slide'
                key={index}
                sx={{
                  flex: '0 0 100%',
                  position: 'relative',
                  height: '100%',
                }}
              >
                {slide.imageSrc && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      width: '80%',
                      height: 'calc(100% - 150px)',
                      backgroundImage: `url(/screenshots/${slide.imageSrc}.jpg)`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      zIndex: 1,
                    }}
                  />
                )}

                <Paper
                  elevation={3}
                  sx={{
                    position: 'absolute',
                    bottom: { xs: 40, sm: 60 },
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: { xs: '90%', sm: '80%', md: '70%' },
                    maxWidth: 700,
                    background: 'rgba(255, 255, 255, 0.95)',
                    color: '#080808',
                    p: { xs: 2, sm: 3 },
                    borderRadius: 2,
                    zIndex: 2,
                    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <Typography
                    variant='h6'
                    component='h2'
                    gutterBottom
                    align='center'
                    sx={{
                      fontWeight: 'bold',
                      color: 'primary.main',
                      mb: 1,
                    }}
                  >
                    {slide.title}
                  </Typography>

                  <Typography
                    align='center'
                    sx={{
                      fontSize: { xs: '1rem', sm: '1.1rem' },
                      lineHeight: 1.5,
                    }}
                    dangerouslySetInnerHTML={{ __html: slide.content }}
                  />

                  {slide.customContent && <Box sx={{ mt: 2, textAlign: 'center' }}>{slide.customContent}</Box>}
                </Paper>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Left navigation arrow - hidden on first slide */}
        {!isFirstSlide && (
          <Box
            sx={{
              position: 'absolute',
              left: { xs: 10, sm: 30 },
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 10,
              background: 'rgba(255, 255, 255, 0.8)',
              borderRadius: '50%',
              width: { xs: 45, sm: 55 },
              height: { xs: 45, sm: 55 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              '&:hover': {
                background: 'rgba(255, 255, 255, 1)',
              },
              '& svg': {
                transform: 'translateX(4px)',
                width: { xs: 28, sm: 32 },
                height: { xs: 28, sm: 32 },
                fontSize: { xs: 28, sm: 32 },
              },
            }}
            onClick={() => scrollTo(selectedIndex - 1)}
          >
            <PrevButton onClick={() => {}} disabled={false} />
          </Box>
        )}

        {/* Right navigation arrow - closes tutorial on last slide */}
        <Box
          sx={{
            position: 'absolute',
            right: { xs: 10, sm: 30 },
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 10,
            background: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '50%',
            width: { xs: 45, sm: 55 },
            height: { xs: 45, sm: 55 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            '&:hover': {
              background: 'rgba(255, 255, 255, 1)',
            },
            '& svg': {
              width: { xs: 28, sm: 32 },
              height: { xs: 28, sm: 32 },
              fontSize: { xs: 28, sm: 32 },
            },
          }}
          onClick={isLastSlide ? handleLastSlideAction : () => scrollTo(selectedIndex + 1)}
        >
          <NextButton onClick={() => {}} disabled={false} />
        </Box>

        {/* Navigation dots */}
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            zIndex: 3,
          }}
        >
          {scrollSnaps.map((_, index) => (
            <DotButton key={index} onClick={() => scrollTo(index)} selected={index === selectedIndex} />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default IntroductionTour;
