/**
 * Seed hash function (xmur3) to generate a 32-bit seed from a string.
 *
 * @param {string} str - The input string.
 * @returns {() => number} A function that returns a 32-bit seed.
 */
const xmur3 = (str: string): (() => number) => {
  let h = 1779033703 ^ str.length;
  for (let i = 0; i < str.length; i++) {
    h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
    h = (h << 13) | (h >>> 19);
  }
  return function () {
    h = Math.imul(h ^ (h >>> 16), 2246822507);
    h = Math.imul(h ^ (h >>> 13), 3266489909);
    return (h ^= h >>> 16) >>> 0;
  };
};

/**
 * Seeded pseudo-random number generator (Mulberry32).
 *
 * @param {number} a - A 32-bit integer seed.
 * @returns {() => number} A function that returns a pseudo-random number between 0 and 1.
 */
const mulberry32 = (a: number): (() => number) => {
  return () => {
    a |= 0;
    a = (a + 0x6d2b79f5) | 0;
    let t = Math.imul(a ^ (a >>> 15), 1 | a);
    t = (t + Math.imul(t ^ (t >>> 7), 61 | t)) ^ t;
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
};

/**
 * Generates a deterministic random-looking UUIDv4 based on the provided seed string.
 *
 * @param {string} seed - The input string used to deterministically generate the UUID.
 * @returns {string} A UUIDv4 formatted string.
 *
 * @description
 * This function computes 16 bytes of pseudo-random data from a seed string.
 * It then adjusts the proper bits so that the output looks like a random UUIDv4.
 */
export const createDeterministicUUIDv4 = (seed: string): string => {
  const seedFn = xmur3(seed);
  const rand = mulberry32(seedFn());
  const bytes = new Uint8Array(16);
  for (let i = 0; i < 16; i++) {
    bytes[i] = Math.floor(rand() * 256);
  }
  // Set UUID version (4) and variant bits (10xx) as per RFC 4122.
  bytes[6] = (bytes[6] & 0x0f) | 0x40;
  bytes[8] = (bytes[8] & 0x3f) | 0x80;

  // Convert bytes into the UUID string format: 8-4-4-4-12.
  const byteToHex: string[] = [];
  for (let i = 0; i < 256; ++i) {
    byteToHex[i] = (i + 0x100).toString(16).substr(1);
  }
  const uuid =
    byteToHex[bytes[0]] +
    byteToHex[bytes[1]] +
    byteToHex[bytes[2]] +
    byteToHex[bytes[3]] +
    '-' +
    byteToHex[bytes[4]] +
    byteToHex[bytes[5]] +
    '-' +
    byteToHex[bytes[6]] +
    byteToHex[bytes[7]] +
    '-' +
    byteToHex[bytes[8]] +
    byteToHex[bytes[9]] +
    '-' +
    byteToHex[bytes[10]] +
    byteToHex[bytes[11]] +
    byteToHex[bytes[12]] +
    byteToHex[bytes[13]] +
    byteToHex[bytes[14]] +
    byteToHex[bytes[15]];
  return uuid;
};
