import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useTranslation } from '../utils/i18n';

interface BookCoverContextMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onReplaceFile: () => void;
  onDeleteBook: () => void;
  onAddTranslatedVersion: () => void;
  onResetBook: () => void;
  onMoveTo: () => void;
}

/**
 * BookCoverContextMenu Component
 *
 * Context menu that appears when long-pressing a book cover.
 * Provides various book-related actions, with all labels provided via translations.
 *
 * @param {BookCoverContextMenuProps} props - Component props
 * @returns {JSX.Element} The rendered component
 */
const BookCoverContextMenu: React.FC<BookCoverContextMenuProps> = ({
  anchorEl,
  onClose,
  onReplaceFile,
  onDeleteBook,
  onAddTranslatedVersion,
  onResetBook,
  onMoveTo,
}) => {
  const t = useTranslation();

  return (
    <Menu
      id='book-context-menu'
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          onMoveTo();
          onClose();
        }}
      >
        {t('BOOK_COVER_CONTEXTMENU_MOVE_TO')}
      </MenuItem>
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          onReplaceFile();
          onClose();
        }}
      >
        {t('BOOK_COVER_CONTEXTMENU_REPLACE_EPUB')}
      </MenuItem>
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          onAddTranslatedVersion();
          onClose();
        }}
      >
        {t('BOOK_COVER_CONTEXTMENU_ADD_TRANSLATED')}
      </MenuItem>
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          onResetBook();
          onClose();
        }}
      >
        {t('BOOK_COVER_CONTEXTMENU_RESET_BOOK')}
      </MenuItem>
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          onDeleteBook();
          onClose();
        }}
      >
        {t('BOOK_COVER_CONTEXTMENU_DELETE_BOOK')}
      </MenuItem>
    </Menu>
  );
};

export default BookCoverContextMenu;
