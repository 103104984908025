export const getStackTrace = () => {
  const stack = new Error().stack;
  return stack ? stack.split('\n')[3].trim() : '';
};

const createLogger = () => {
  const logWithInfo =
    (type: 'log' | 'warn' | 'error' | 'info' | 'debug') =>
    (...args: any[]) => {
      const stackInfo = getStackTrace();
      const timestamp = new Date().toISOString();
      console[type](`[${timestamp}] (${stackInfo})`, ...args);
    };

  return {
    log: logWithInfo('log'),
    warn: logWithInfo('warn'),
    error: logWithInfo('error'),
    info: logWithInfo('info'),
    debug: logWithInfo('debug'),
  };
};

export const logger = createLogger();
