import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardActionArea, CardContent, Typography, Stack, Box } from '@mui/material';
import { useLongPress, type LongPressReactEvents } from 'use-long-press';
import { useTranslation } from '../utils/i18n';
import type { Group, BookMeta, GroupCollection, BookCollection } from '../types/book';
import { isGroup, getGroupChildren, getAllBooksInGroup } from '../utils/groups';
import { getBooks } from '../utils/books';
import GroupContextMenu from './GroupContextMenu';
import { loadCoverImage } from '../utils/image';
import FolderIcon from '@mui/icons-material/Folder';
/**
 * Props for the GroupCover component
 */
interface GroupCoverProps {
  allGroups: GroupCollection;
  group: Group;
  books: BookCollection;
  onSelect: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onMoveTo?: () => void;
  multiSelectMode?: boolean;
}

interface PreviewItem {
  isFolder: boolean;
  id: string;
  name: string;
}

/**
 * GroupCover Component
 *
 * Renders a visual representation of a group, showing thumbnails of books inside
 * and providing interaction options for the group.
 *
 * @param props - GroupCover component properties
 * @returns JSX.Element
 */
const GroupCover: React.FC<GroupCoverProps> = React.memo(
  ({ allGroups, group, books, onSelect, onDelete, onEdit, onMoveTo }) => {
    const t = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [coverUrls, setCoverUrls] = useState<Record<string, string>>({});
    // Track whether context menu was recently closed
    const [menuRecentlyClosed, setMenuRecentlyClosed] = useState(false);

    // Get the items in this group
    const itemsInGroup = useMemo(() => {
      const books = getBooks(group.id);
      return getGroupChildren(allGroups, books, group.id);
    }, [allGroups, group.id]);

    // Separate books and subgroups
    const booksInGroup = itemsInGroup.filter((item): item is BookMeta => !isGroup(item)) as BookMeta[];
    const allBooksInGroup = getAllBooksInGroup(allGroups, books, group.id);
    const subgroups = itemsInGroup.filter(isGroup) as Group[];

    // Create a stable dependency key for the effect
    const booksInGroupKey = React.useMemo(() => booksInGroup.map((book) => book.id).join(','), [booksInGroup]);

    // Load cover images for all books in this group
    useEffect(() => {
      // Store refs to cover URLs that will be created in this effect run
      const urlsToCleanup: string[] = [];

      const loadCovers = async () => {
        const newCoverUrls: Record<string, string> = {};

        // Load covers for books in this group
        for (const book of booksInGroup) {
          if (book.id) {
            const coverUrl = await loadCoverImage(book.id);
            newCoverUrls[book.id] = coverUrl;
            if (coverUrl.startsWith('blob:')) {
              urlsToCleanup.push(coverUrl);
            }
          }
        }

        setCoverUrls(newCoverUrls);
      };

      loadCovers();

      // Cleanup function to revoke object URLs
      return () => {
        // Only revoke URLs created in this effect run
        urlsToCleanup.forEach((url) => {
          if (url.startsWith('blob:')) {
            URL.revokeObjectURL(url);
          }
        });
      };
    }, [booksInGroupKey]);

    /**
     * Handles long press to open context menu
     */
    const onLongPress = (event: LongPressReactEvents<Element>) => {
      event.preventDefault();
      if (event.target instanceof HTMLElement) {
        setAnchorEl(event.target);
      }
    };

    /**
     * Handles right-click to open context menu, mimicking long-press behavior
     */
    const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.target instanceof HTMLElement) {
        setAnchorEl(event.target);
      }
    };

    const handleContextMenuClose = () => {
      setAnchorEl(null);
      // Set recently closed flag to prevent immediate group selection
      setMenuRecentlyClosed(true);
      // Clear the flag after a short delay
      setTimeout(() => {
        setMenuRecentlyClosed(false);
      }, 300);
    };

    /**
     * Handles group selection with safety check to prevent accidental selection
     * when dismissing the context menu
     *
     * @param event - The click event
     */
    const handleSelect = (_event: React.MouseEvent<Element>) => {
      // If menu was recently closed or is currently open, don't select the group
      if (menuRecentlyClosed || anchorEl) {
        return;
      }
      onSelect();
    };

    const bindLongPress = useLongPress(
      (event: LongPressReactEvents<Element>) => {
        onLongPress(event);
      },
      {
        onCancel: () => {
          /* do nothing on cancel */
        },
        filterEvents: (_event) => true, // All events can trigger long press
        threshold: 500, // ms
        captureEvent: true,
        cancelOnMovement: false,
      }
    );

    // Determine what to show in the preview grid
    const hasContent = booksInGroup.length > 0 || subgroups.length > 0;
    const maxPreviewItems = 3; // Maximum items to show in preview

    // Prioritize showing a mix of books and subgroups if both exist
    let previewItems: PreviewItem[] = [];

    if (booksInGroup.length > 0) {
      // Add books first
      const booksToShow = booksInGroup.slice(0, maxPreviewItems);
      previewItems = booksToShow.map((book) => ({
        isFolder: false,
        id: book.id,
        name: book.title || '',
      }));
    }

    // Add folders if we have space and there are subgroups
    if (previewItems.length < maxPreviewItems && subgroups.length > 0) {
      const remainingSlots = maxPreviewItems - previewItems.length;
      const groupsToShow = subgroups.slice(0, remainingSlots);

      const folderItems = groupsToShow.map((group) => ({
        isFolder: true,
        id: group.id,
        name: group.name || '',
      }));

      previewItems = [...previewItems, ...folderItems];
    }

    return (
      <Card
        variant='outlined'
        sx={{
          width: 'calc((100vw - 44px) / 2)',
          minWidth: '160px',
          maxWidth: '195px',
          px: 0,
          py: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
        {...bindLongPress()}
        onContextMenu={handleRightClick}
      >
        <CardActionArea
          onClick={handleSelect}
          sx={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '8px',
              height: '100%',
            }}
          >
            {/* Top section (70%) - Book covers or folder icon */}
            <Box
              sx={{
                width: '100%',
                height: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 1,
              }}
            >
              {hasContent ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1,
                  }}
                >
                  {/* Show preview items (books and/or folders) */}
                  {previewItems.map((item) => (
                    <Box
                      key={item.id}
                      sx={{
                        width: '40px',
                        height: '60px',
                        backgroundImage: !item.isFolder ? `url(${coverUrls[item.id] || ''})` : 'none',
                        backgroundSize: 'cover',
                        display: item.isFolder ? 'flex' : 'block',
                        justifyContent: 'center',
                      }}
                    >
                      {item.isFolder && (
                        <Stack direction='column' alignItems='center' justifyContent='start'>
                          <FolderIcon sx={{ fontSize: '30px', opacity: 0.6 }} />
                          <Typography
                            variant='caption'
                            sx={{
                              textAlign: 'center',
                              fontSize: '8px',
                              px: 0.5,
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Stack>
                      )}
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FolderIcon sx={{ fontSize: '72px', opacity: 0.6 }} />
                </Box>
              )}
            </Box>

            {/* <Divider sx={{ width: '100%', p: 0, m: 1 }} /> */}
            {/* Bottom section (30%) - Group name and counts */}
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <Typography
                variant='h6'
                textOverflow='ellipsis'
                sx={{
                  fontSize: '1.2rem',
                  fontWeight: 'medium',
                  textAlign: 'center',
                }}
              >
                {group.name}
              </Typography>

              {/* Item counts */}
              {allBooksInGroup.length > 0 && (
                <Typography
                  variant='caption'
                  color='text.secondary'
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  {`${allBooksInGroup.length} ${allBooksInGroup.length === 1 ? t('BOOK') : t('BOOKS')}`}
                </Typography>
              )}
            </Box>
          </CardContent>
        </CardActionArea>

        {/* Context menu */}
        {onDelete && onEdit && (
          <GroupContextMenu
            anchorEl={anchorEl}
            onClose={handleContextMenuClose}
            onDelete={onDelete}
            onEdit={onEdit}
            onMoveTo={onMoveTo || (() => {})}
          />
        )}
      </Card>
    );
  },
  (prevProps, nextProps) => {
    // Check if multiSelectMode has changed
    if (prevProps.multiSelectMode !== nextProps.multiSelectMode) {
      return false;
    }

    // Check if group ID is the same
    if (prevProps.group.id !== nextProps.group.id) {
      return false;
    }

    // Check if group has been updated
    if (prevProps.group.updatedAt !== nextProps.group.updatedAt) {
      return false;
    }

    // Check if any books have changed in this group
    const prevBooks = getBooks(prevProps.group.id);
    const nextBooks = getBooks(nextProps.group.id);

    if (Object.keys(prevBooks).length !== Object.keys(nextBooks).length) {
      return false;
    }

    // Check if any child groups have changed
    const prevChildGroups = Object.values(prevProps.allGroups)
      .filter((g) => g.parentId === prevProps.group.id)
      .map((g) => g.id)
      .sort();

    const nextChildGroups = Object.values(nextProps.allGroups)
      .filter((g) => g.parentId === nextProps.group.id)
      .map((g) => g.id)
      .sort();

    if (prevChildGroups.length !== nextChildGroups.length) {
      return false;
    }

    // Compare IDs
    for (let i = 0; i < prevChildGroups.length; i++) {
      if (prevChildGroups[i] !== nextChildGroups[i]) {
        return false;
      }
    }

    return true;
  }
);

export default GroupCover;
