import ReactDOM from 'react-dom/client';
import App from './App';
import { setViewport } from './utils/viewports';
import { googleFonts, generateGoogleFontsLink } from './fonts.config';

window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

setViewport();

// Add fonts before rendering
document.head.insertAdjacentHTML('beforeend', generateGoogleFontsLink(googleFonts));

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
