import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useTranslation } from '../utils/i18n';

/**
 * Props for the GroupContextMenu component
 */
interface GroupContextMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onDelete: () => void;
  onEdit: () => void;
  onMoveTo: () => void;
}

/**
 * GroupContextMenu Component
 *
 * Context menu that appears when long-pressing a group.
 * Provides options to edit or delete the group.
 *
 * @param props - GroupContextMenu component properties
 * @returns JSX.Element
 */
const GroupContextMenu: React.FC<GroupContextMenuProps> = ({ anchorEl, onClose, onDelete, onEdit, onMoveTo }) => {
  const t = useTranslation();

  return (
    <Menu
      id='group-context-menu'
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <MenuItem
        onClick={() => {
          onMoveTo();
          onClose();
        }}
      >
        {t('GROUP_CONTEXT_MENU_MOVE_TO')}
      </MenuItem>
      <MenuItem
        onClick={() => {
          onEdit();
          onClose();
        }}
      >
        {t('GROUP_CONTEXT_MENU_RENAME')}
      </MenuItem>

      <MenuItem
        onClick={() => {
          onDelete();
          onClose();
        }}
      >
        {t('GROUP_CONTEXT_MENU_DELETE')}
      </MenuItem>
    </Menu>
  );
};

export default GroupContextMenu;
