import type { Tutorial } from '../components/TutorialSelector';

/**
 * All available tutorials in the application
 */
export const TUTORIALS: Tutorial[] = [
  {
    id: 'basics',
    name: 'Getting started',
    description: 'Add your first book',
    slides: [
      {
        title: 'Adding books',
        content: 'To add books, tap the <b>Add</b> button in the top left corner.',
        imageSrc: 'add_button',
      },
      {
        title: 'Adding books',
        content:
          'Tap <b>Media picker</b> (or the equivalent), and find the book(s) you want to add. You can select and add multiple epub files at once. Your book should be added to your list shortly.',
        imageSrc: 'add_button_2',
      },
      {
        title: 'Download weReader as an app for a better experience',
        content:
          'In Chrome on Android, you can add weReader as an app by opening the three-dot menu in the top right corner and selecting <b>Install weReader</b>.<br /><br />On iOS, this is <b>Add to Home Screen</b> in Safari.',
        imageSrc: 'installing',
      },
    ],
  },
  {
    id: 'organization',
    name: 'Organizing your library',
    description: "Group your books and shuffle 'em around",
    slides: [
      {
        title: 'Book context menu',
        content:
          'You can <b>long press</b> on a book to do things like move the book to a different group or delete it.',
        imageSrc: 'cover_context',
      },
      {
        title: 'Book groups',
        content:
          'You can store your books in <b>groups</b>, which you can create with the <b>New</b> button.<br /><br /><b>Long press</b> on a group to rename, move, or delete it.<br /><br /><small>(<b>Pro tip:</b> If you delete a group, anything inside of it will be moved up a level, not deleted.)</small>',
        imageSrc: 'group_context',
      },
      {
        title: 'Bulk actions',
        content:
          'You can select multiple books and perform actions on them at once. To do this, tap the <b>Select</b> button, then tap the books you want to select. To select groups, tap the checkbox itself to toggle it, or anywhere else to open the group.<br /><br />You can then perform actions on selected books/groups like moving them to a different group (shown above) or deleting them.',
        imageSrc: 'multimove',
      },
      {
        title: 'Have the AI organize your library (experimental)',
        content:
          "If you tap <b>Auto</b>, you can describe exactly how you'd like to have your books organized, and the AI will give it a shot. It will only take effect within your currently selected group; if you want it to do everything, use it on the top level, <b>My Library</b>.<br /><br /><small><b>Note:</b> This may not work as expected, or get every book right. It <i>will</i> override any groups you already have set up. If you don't want to lose your groups, you should back up your library first with the <b>Backup</b> button!</small>",
        imageSrc: 'organize_books',
      },
    ],
  },
  {
    id: 'menu',
    name: 'Getting around',
    description: 'Turn pages, skip chapters, mess with the status bar',
    slides: [
      {
        title: 'Skipping chapters and turning pages',
        content:
          "<b>Tapping</b> on the left or right hand of the screen will move forward or backward a page, or to the next or previous chapter when you're at the end or beginning of a chapter.<br /><br />You can also do a <b>long swipe</b> from the left or right edge of the screen to skip chapters; you'll see the <b>indicator</b> shown above when you swipe far enough.<br /><br />When reading in page mode (as opposed to scroll mode), <b>swipes left and right</b> will also turn pages.",
        imageSrc: 'skip_forward',
      },
      {
        title: 'Status bar',
        content:
          'Tap anywhere to toggle the <b>status bar</b>. You can configure up to four things to show by tapping in the appropriate spot, including things like reading speed and time left in chapter and/or book.<br /><br />Open the menu by <b>tapping the button in the top left</b>. (You can also hold down three fingers anywhere.)',
        imageSrc: 'status_bar',
      },
      {
        title: 'Table of Contents',
        content: 'This is the table of contents. Tapping an entry will jump to that chapter.',
        imageSrc: 'toc',
      },
      {
        title: 'Chapter display type',
        content:
          'By <b>tapping on the right column</b>, you can switch between showing <b>percentage</b> through the book, a Kindle-style <b>location number</b>, or the <b>number of words</b> in each chapter.',
        imageSrc: 'toc_toggle',
      },

      {
        title: 'Going back',
        content:
          "You can use the <b>back button</b> in the menu (shown above), or use the standard <b>back gesture</b> while reading, to jump back to your last spot.<br /><br /><small>(<b>Pro tip:</b> If you do it multiple times in a row, you're able to jump back <b>as far as you like</b> in your history.)</small>",
        imageSrc: 'settings_back',
      },
    ],
  },
  {
    id: 'highlights',
    name: 'Highlights & notes',
    description: 'Adding notes and highing lights; use it with a friend!',
    slides: [
      {
        title: 'Create highlights',
        content:
          "If you <b>select some text</b>, you'll be able to <b>tap a color</b> to highlight it.<br /><br />If you tap on the highlighted text, you can change the color or add a note on it.<br /><br /><small>(<b>**Pro tip**:</b> Notes support <i>_markdown_</i> formatting.)</small>",
        imageSrc: 'highlight_comment',
      },
      {
        title: 'Remove or edit a note',
        content:
          "If there's only one comment on a highlight when you tap it, the comment will appear right in the text. Tapping on the comment will hide it again. Or you can <b>tap the highlight again</b> to bring up the normal note box.",
        imageSrc: 'highlight_comment_inline',
      },
      {
        title: 'Remove or edit a note',
        content: 'If you tap this <b>undo arrow</b>, it will remove the most recent note...',
        imageSrc: 'highlight_comment_doubletap',
      },
      {
        title: 'Remove or edit a note',
        content:
          '...and you can <b>change it</b> and re-add it if you want. Tapping the <b>trash can</b> will remove a highlight entirely.<br /><br /><small>(<b>Pro tip:</b> The <b>emoji button</b> lets you add reactions to a highlight!)</small>',
        imageSrc: 'highlight_undo',
      },
      {
        title: 'Note list',
        content:
          'You can also see all your notes in the <b>note list</b> in the menu. Tapping on a note will jump to it.<br /><br />If anyone has commented on a highlight, it appears as a little <b>chip</b> under the highlighted text. Tap on these chips to show the comment.<br /><br /><small>(<b>Pro tip:</b> If you and others use the <i>exact same</i> .epub file, your notes will be visible to each other. (Thus <b>we</b>Reader.))</small>',
        imageSrc: 'notes_chips',
      },
      {
        title: 'Hiding spoilers',
        content:
          'The <b>show/hide</b> switch controls whether you see all notes in the list, or only the ones that are located before your spot in the book.<br /><br /><small>(<b>Pro tip:</b> The <b>time/space</b> switch sorts the notes by when they were added, or by where they are in the book.)</small>',
        imageSrc: 'notes_hide_toggle',
      },
    ],
  },
  {
    id: 'settings',
    name: 'Customization and layout',
    description: 'How to fiddle with margins and spacing and fonts and all that',
    slides: [
      {
        title: 'Settings menu',
        content: 'This is where you find all the visual settings.',
        imageSrc: 'settings',
      },
      {
        title: 'Layout settings',
        content:
          "This is for fine-tuning. Moving any slider <b>all the way to the left</b> will leave it on the <b>default publisher's setting</b>.",
        imageSrc: 'settings_layout',
      },
      {
        title: 'Note visibility',
        content:
          "Some people like to see highlights, some people don't. This is where you can control it.<br /><br /><small>(<b>Pro tip:</b> <b>Comments-only</b> will show only highlights with comments. Highlights with comments appear with a <b>dashed border</b>, and unread ones have a <b>glowy effect</b>.)</small>",
        imageSrc: 'settings_notes',
      },
      {
        title: 'Tooltips, tooltips, tooltips',
        content:
          'Most buttons and controls in the menu (and out in the main screen) have tooltips which you can see by <b>long-pressing</b> them.<br /><br />This one in particular will switch between reading in <b>scrolling mode</b> (as shown) and reading in <b>paged mode</b>.',
        imageSrc: 'settings_tooltips',
      },
    ],
  },
  {
    id: 'language',
    name: 'Language support',
    description: 'For the would-be and current polyglots',
    slides: [
      {
        title: 'Dictionary',
        content:
          "The <b>dictionary button</b> will query an English dictionary if you're reading an English book. If you're reading a non-English book and your language is set to English, it will look up the word in <b>Wiktionary</b> for the book's language.",
        imageSrc: 'dictionary',
      },
      {
        title: 'Language selection',
        content:
          'weReader supports <b>23 languages</b>. You can select your preferred language in the settings or on the main screen, and most of the app will switch to using it.<br /><br />Here, we show selecting <b>Russian</b>.',
        imageSrc: 'settings_russian',
      },
      {
        title: 'AI translation',
        content:
          'If you are reading a book that is in a language different than the one you have set, this <b>translate button</b> appears. It will translate the highlighted text into your language.',
        imageSrc: 'translate_button',
      },
      {
        title: 'AI translation',
        content:
          'The translation takes <b>context</b> into account. This makes it a lot more accurate than, say, Google Translate.',
        imageSrc: 'russian_translation',
      },
      {
        title: 'AI language usage',
        content:
          "Note that the AI will also respond to you in your preferred language, regardless of the language of the book you're reading.",
        imageSrc: 'russian_response',
      },
    ],
  },
  {
    id: 'ai',
    name: 'AI assistant',
    description: "The thing you never thought you needed and now can't live without",
    slides: [
      {
        title: 'AI button',
        content:
          "Highlight some text and tap the <b>AI button</b> to get its input. It will take <b>context</b> into account, and make its best guess about what you want to know.<br /><br />Here, we've selected <b>Suizhen</b>...",
        imageSrc: 'ai_button',
      },
      {
        title: 'Default mode',
        content:
          "You'll get a response something like this, attempting to help you out, and possibly explaining <b>how it connects</b> to nearby text.<br /><br /><small>(<b>Pro tip:</b> If you hit the <b>plus button</b>, it will add the AI's response as a note. The <b>share button</b> is mainly for Anki users.)</small>",
        imageSrc: 'ai_normal',
      },
      {
        title: 'Get specific',
        content:
          "If the AI isn't reading your mind sufficiently well, you can highlight some text and add a note tagged with <b>@ai</b> somewhere in it, and it will respond to your comment or question.",
        imageSrc: 'highlight_at_ai',
      },
      {
        title: 'Context levels',
        content:
          'Sometimes, you want to control <b>how much context</b> the AI uses. If you <b>long press</b> on the AI button, you can choose between three different levels of context.',
        imageSrc: 'ai_dropdown',
      },
      {
        title: 'No context',
        content:
          'The <b>top button</b> of the three will use <b>no context at all</b>, just the highlighted text. This can be useful if you want to know a definition or some non-book-related background information.<br /><br />The <b>middle button</b> will use a bunch of context, often <b>everything in the chapter</b> up to the point of the highlighted text. It will also go into more detail than the default button will.',
        imageSrc: 'ai_no_context',
      },
      {
        title: 'Full context',
        content:
          'The <b>bottom button</b> will use <b>the entire book</b> (up to the point of the highlighted text) as context. It will also give a longer response, and go out of its way to draw inferences. As a result, this is significantly slower.',
        imageSrc: 'ai_full_context',
      },
    ],
  },
];

/**
 * Get a specific tutorial by ID
 *
 * @param {string} id - The tutorial ID to find
 * @returns {Tutorial | undefined} The found tutorial or undefined
 */
export const getTutorialById = (id: string): Tutorial | undefined => {
  return TUTORIALS.find((tutorial) => tutorial.id === id);
};

/**
 * Get all available tutorials
 *
 * @returns {Tutorial[]} All available tutorials
 */
export const getAllTutorials = (): Tutorial[] => {
  return TUTORIALS;
};
