import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography, Skeleton, Stack, Box } from '@mui/material';
import type { BookMeta } from '../types/book';
import { getPerson, getMinutesReadInBook, durationFormat, calculateEngagement, getTotalWPM } from '../utils/books';
import { useLongPress, type LongPressReactEvents } from 'use-long-press';
import BookCoverContextMenu from './BookCoverContextMenu';
import { debounce } from '../utils/core';
import { useTranslation } from '../utils/i18n';
import { loadCoverImage } from '../utils/image';

interface BookCoverProps {
  book: BookMeta;
  onReplaceFile: () => void;
  onDeleteBook: () => void;
  onMoveTo: () => void;
  onSelect: () => void;
  userProgress?: { [userEmail: string]: any };
  fileInputRef: React.RefObject<HTMLInputElement | null>;
  onAddTranslatedVersion: () => void;
  onResetBook: () => void;
  userEmail?: string;
  multiSelectMode?: boolean;
}

interface BookCoverState {
  wordCount: number | null;
  coverUrl: string;
  minutesRead: number | undefined;
  engagement: number;
  wpm: number;
  isLoading: boolean;
}

/**
 * BookCover Component
 *
 * Renders the cover of a book along with progress details.
 *
 * @param props - BookCover component properties.
 * @returns JSX.Element
 */
const BookCover: React.FC<BookCoverProps> = ({
  book,
  userProgress,
  onDeleteBook,
  onReplaceFile,
  onAddTranslatedVersion,
  onResetBook,
  onMoveTo,
  onSelect,
  userEmail,
}) => {
  const t = useTranslation();
  const [coverUrl, setCoverUrl] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [wordCount, setWordCount] = useState<number | null>(null);
  const [minutesRead, setMinutesRead] = useState<number | undefined>();
  const [engagement, setEngagement] = useState<number>(0);
  const [wpm, setWpm] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  // Track whether context menu was recently closed
  const [menuRecentlyClosed, setMenuRecentlyClosed] = useState(false);

  /**
   * Renders user progress from either a default setting or userProgress property.
   */
  const renderUserProgress = () => {
    if (!userProgress) {
      return (
        <Typography variant='body2' color='text.secondary'>
          {t('BOOK_COVER_PROGRESS')}: {((book.settings?.progress || 0.0) * 100.0).toFixed(0)}%
        </Typography>
      );
    }
    return Object.keys(userProgress)
      .filter((email) => userEmail === email)
      .map((email, index) => (
        <Typography key={`${book.hash}-${email}-${index}`} variant='body2' color='text.secondary'>
          {getPerson(email)?.given_name ?? t('SOMEBODY')}: {(userProgress[email].progress * 100).toFixed(0)}%
          {userProgress[email].note_count ? ` (${userProgress[email].note_count})` : ''}
        </Typography>
      ));
  };

  const onLongPress = (event: LongPressReactEvents<Element>) => {
    event.preventDefault();
    if (event.target instanceof HTMLElement) {
      setAnchorEl(event.target);
    }
  };

  /**
   * Handle right-click events to show context menu, mimicking long-press behavior
   */
  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.target instanceof HTMLElement) {
      setAnchorEl(event.target);
    }
  };

  const handleContextMenuClose = () => {
    setAnchorEl(null);
    // Set recently closed flag to prevent immediate book opening
    setMenuRecentlyClosed(true);
    // Clear the flag after a short delay
    setTimeout(() => {
      setMenuRecentlyClosed(false);
    }, 300);
  };

  /**
   * Handles book selection with safety check to prevent accidental selection
   * when dismissing the context menu
   */
  const handleSelect = (_event: React.MouseEvent<Element>) => {
    // If menu was recently closed or is currently open, don't select the book
    if (menuRecentlyClosed || anchorEl) {
      return;
    }
    onSelect();
  };

  const bindLongPress = useLongPress(
    (event: LongPressReactEvents<Element>) => {
      onLongPress(event);
    },
    {
      onCancel: () => {
        /* do nothing on cancel */
      },
      filterEvents: (_event) => true, // All events can trigger long press
      threshold: 500, // ms
      captureEvent: true,
      cancelOnMovement: false,
    }
  );

  const debouncedLoadContent = useRef(
    debounce(async (book: BookMeta, setStates: (states: Partial<BookCoverState>) => void) => {
      const [wordCount, coverUrl, minutesRead, engagement, wpm] = await Promise.all([
        book.id && book.wordCount ? book.wordCount : null,
        loadCoverImage(book.id),
        book.id ? getMinutesReadInBook(book.id) : undefined,
        book.id && book.wordCount ? calculateEngagement(book) : 0,
        book.id && book.wordCount && book.wpm ? book.wpm : getTotalWPM(book, true) || 0,
      ]);

      setStates({
        wordCount,
        coverUrl,
        minutesRead,
        engagement,
        wpm,
        isLoading: false,
      });
    }, 50)
  ).current;

  const setStates = useCallback((states: Partial<BookCoverState>) => {
    Object.entries(states).forEach(([key, value]) => {
      switch (key) {
        case 'wordCount':
          setWordCount(value as number | null);
          break;
        case 'coverUrl':
          setCoverUrl(value as string);
          break;
        case 'minutesRead':
          setMinutesRead(value as number | undefined);
          break;
        case 'engagement':
          setEngagement(value as number);
          break;
        case 'isLoading':
          setIsLoading(value as boolean);
          break;
        case 'wpm':
          setWpm(value as number);
          break;
      }
    });
  }, []);

  useEffect(() => {
    debouncedLoadContent(book, setStates);
    return () => {
      debouncedLoadContent.cancel();
      if (coverUrl.startsWith('blob:')) {
        URL.revokeObjectURL(coverUrl);
      }
    };
  }, [book.id, book.wordCount, book.timestamp, debouncedLoadContent, setStates]);

  if (isLoading) {
    return <Skeleton variant='rectangular' width={400} height={240} animation='wave' />;
  }

  return (
    <Card
      variant='outlined'
      {...bindLongPress()}
      onContextMenu={handleRightClick}
      sx={{ flexGrow: 1, maxWidth: '400px', height: '240px', px: 0 }}
    >
      <CardActionArea
        onClick={handleSelect}
        sx={{
          height: '100%',
        }}
      >
        <Stack direction='row'>
          <CardMedia
            component='img'
            image={coverUrl}
            alt={book.title}
            sx={{
              objectFit: 'contain',
              width: '160px',
              height: '240px',
              aspectRatio: '2/3',
              alignItems: 'center',
            }}
          />
          <BookCoverContextMenu
            anchorEl={anchorEl}
            onClose={handleContextMenuClose}
            onDeleteBook={onDeleteBook}
            onReplaceFile={onReplaceFile}
            onAddTranslatedVersion={onAddTranslatedVersion}
            onResetBook={onResetBook}
            onMoveTo={onMoveTo}
          />

          <CardContent sx={{ padding: 0, width: '100%', flexGrow: 1 }}>
            <Stack
              spacing={0}
              height={'100%'}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', ml: 1, mr: 1, mt: 0.5, mb: 0 }}
              >
                {wordCount && (
                  <Typography color='#888' sx={{ fontSize: '0.9em' }}>
                    {t('BOOK_COVER_WORD_COUNT', { count: Math.ceil(wordCount / 1000) })}
                  </Typography>
                )}
                {minutesRead ? (
                  <Typography color='#888' sx={{ mr: 2, fontSize: '0.9em' }}>
                    {t('BOOK_COVER_READ_FOR', { duration: durationFormat(minutesRead) })}
                  </Typography>
                ) : (
                  <Box />
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  boxSizing: 'border-box',
                  ml: 1,
                  mr: 1,
                  mt: 0.5,
                  flexDirection: 'column',
                  px: 1,
                  py: 0.5,
                  width: 'stretch',
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.05,
                    mixBlendMode: 'difference',
                  },
                  border: '1px solid',
                  borderColor: 'currentColor',
                  borderRadius: 1,
                  '& > *': {
                    position: 'relative',
                    zIndex: 1,
                  },
                }}
              >
                {book.author && (
                  <Typography
                    sx={{
                      fontSize: '1em',
                      boxSizing: 'border-box',
                      maxWidth: '240px',
                      textAlign: 'center',
                      width: '100%',
                      opacity: 0.8,
                      fontWeight: 'light',
                    }}
                  >
                    {book.author}
                  </Typography>
                )}
                <Typography
                  sx={{
                    boxSizing: 'border-box',
                    fontSize: '1.4em',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {book.title}
                </Typography>
              </Box>
              {wpm > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    ml: 1,
                    mr: 1,
                    mt: 0.5,
                    mb: 0,
                    color: 'rgba(128, 128, 128, 0.75)',
                  }}
                >
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', pl: 0.5 }}>
                    <Typography sx={{ pr: 2 }}>{t('BOOK_COVER_WPM', { wpm: wpm.toFixed(0) })}</Typography>
                  </Box>
                  {engagement > 0 && (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', pr: 0.5 }}>
                      <Typography sx={{ pr: 2 }}>
                        {t('BOOK_COVER_FOCUS', { percentage: (engagement * 100).toFixed(0) })}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              <Box flexGrow={1} />
              <Box sx={{ pl: 2.5, mb: 0, mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                {renderUserProgress()}
              </Box>
            </Stack>
          </CardContent>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(BookCover, (prevProps, nextProps) => {
  // Re-render if multiSelectMode changes
  if (prevProps.multiSelectMode !== nextProps.multiSelectMode) {
    return false;
  }

  // Re-render if book ID changes
  if (prevProps.book.id !== nextProps.book.id) {
    return false;
  }

  // Re-render if book has been updated
  if (prevProps.book.timestamp !== nextProps.book.timestamp) {
    return false;
  }

  // Otherwise, assume equal
  return true;
});
