import { type BookSettings } from '../types/book';
import { getScaleFactor, getAllTextSelectors, findMostCommonTextSelector } from './books';
import { defaultBookSettings } from './defaults';
export const checkAndAdjustStyles = async (
  doc: Document | undefined,
  settings: BookSettings,
  ignoreClass?: string
): Promise<Record<string, any>> => {
  if (!doc) {
    console.warn('No document to adjust styles for');
    return {};
  }

  const textSelectors = getAllTextSelectors(doc, ignoreClass);
  const mostCommonSelector = findMostCommonTextSelector(doc, textSelectors);
  const adjustedStyles: Record<string, Record<string, any>> = {};

  const commonElement = findMostRepresentativeElement(doc, mostCommonSelector) as Element;
  //   const commonElement = doc.querySelector(mostCommonSelector) as Element;
  const scaleFactor =
    (commonElement &&
      getScaleFactor(
        commonElement,
        !settings.fontSize || settings.fontSize === defaultBookSettings.fontSize ? 16 : settings.fontSize
      )) ||
    1;
  console.debug('most common selector=', mostCommonSelector, 'scaleFactor=', scaleFactor);

  // Adjust styles for the most common selector
  adjustedStyles[mostCommonSelector] = await adjustStylesForSelector(doc, settings, mostCommonSelector);

  // Adjust styles for other selectors
  for (const selector of textSelectors) {
    if (selector !== mostCommonSelector) {
      adjustedStyles[selector] = await adjustStylesForSelector(doc, settings, selector, mostCommonSelector);
    }
  }

  return adjustedStyles;
};

const containsText = (element: Element): boolean => {
  return element.textContent?.trim() !== '';
};

/**
 * Determines if an element is displayed as block or inline.
 * @param element The element to check
 * @returns 'block' if the element is displayed as block, 'inline' otherwise
 */
const isBlockElement = (element: Element): boolean => {
  const computedStyle = window.getComputedStyle(element);
  const display = computedStyle.display;

  // Check for block-level display values
  if (['block', 'flex', 'grid', 'table', 'list-item'].includes(display)) {
    return true;
  }

  // All other display values are considered inline for this purpose
  return false;
};

/**
 * Finds the most representative element matching the given selector that contains textual content.
 *
 * The heuristic is to choose an element that has either a direct non-whitespace text node
 * or an inline child element with non-whitespace text, and that has the minimal total length of its class names,
 * which is assumed to mean it is less specific (e.g. a plain "p") and hence more representative.
 *
 * As a fallback, if no element satisfies the textual content requirement,
 * the median element from the entire list is returned.
 *
 * @param doc The Document object to search within.
 * @param selector The CSS selector string to match elements.
 * @returns The most representative matching element or null if none are found.
 */
const findMostRepresentativeElement = (doc: Document, selector: string): Element | null => {
  const elements = Array.from(doc.querySelectorAll(selector));
  if (elements.length === 0) return null;

  let bestElement: Element | null = null;
  let bestScore = Infinity;
  let foundValid = false;

  /**
   * Determines if the given element contains textual content.
   *
   * It returns true if the element has either:
   * - A direct non-whitespace text node, or
   * - An inline child element (eg, span, a, em) that contains non-whitespace text.
   *
   * @param element The element to check.
   * @returns True if textual content is found, false otherwise.
   */
  const hasTextualContent = (element: Element): boolean => {
    return Array.from(element.childNodes).some((node: ChildNode) => {
      if (
        node.nodeType === Node.TEXT_NODE &&
        typeof node.textContent === 'string' &&
        node.textContent.trim().length > 0
      ) {
        return true;
      }
      if (node.nodeType === Node.ELEMENT_NODE) {
        const childElement = node as Element;
        // If the element is inline (non-block) and has non-empty text content, consider it valid.
        if (!isBlockElement(childElement) && childElement.textContent && childElement.textContent.trim().length > 0) {
          return true;
        }
      }
      return false;
    });
  };

  // Iterate over all elements to see if they contain textual content.
  // For those that do, compute a score based on the length of all class names,
  // preferring elements with fewer/simpler class names.
  for (const element of elements) {
    if (hasTextualContent(element)) {
      foundValid = true;
      let classScore = 0;
      if (element.className && typeof element.className === 'string') {
        classScore = element.className.split(/\s+/).reduce((acc, className) => acc + className.length, 0);
      }
      if (classScore < bestScore) {
        bestScore = classScore;
        bestElement = element;
      }
    }
  }

  // If no element with valid textual content is found,
  // return the median element from the full list as a last resort.
  if (!foundValid) {
    return elements[Math.floor(elements.length / 2)] || null;
  }

  return bestElement;
};

const adjustStylesForSelector = async (
  doc: Document,
  settings: BookSettings,
  selector: string,
  commonSelector?: string
): Promise<Record<string, any>> => {
  const adjustedStyle: Record<string, any> = {};
  //   if (selector.includes('+')) {
  //     console.debug('selector includes +', selector);
  //     return {};
  //   }
  const element = doc.querySelector(selector) as Element;
  if (!element) return {};
  // Use the helper function to select the most representative commonElement with direct text content.
  const commonElement = findMostRepresentativeElement(doc, commonSelector || selector);
  console.debug('commonElement', commonElement);

  // Helper function to check if a property should be adjusted
  const shouldAdjustProperty = (property: string): boolean => {
    if (!commonSelector) return true;
    const elementStyle = getComputedStyle(element)[property as any];
    const commonStyle = getComputedStyle(commonElement as Element)[property as any];
    // if (property === 'textIndent')
    //   console.debug('elementStyle', elementStyle, 'commonStyle', commonStyle, 'return', elementStyle === commonStyle);
    return elementStyle === commonStyle;
  };

  // Check and adjust font size
  if (
    commonSelector &&
    settings.fontSize &&
    settings.fontSize !== defaultBookSettings.fontSize &&
    containsText(element)
  ) {
    //} && shouldAdjustProperty('fontSize')) {
    adjustedStyle.fontSize = adjustFontSizeProportionally(doc, settings.fontSize, selector);
  }

  // Check and adjust line spacing
  if (
    settings.lineSpacing &&
    settings.lineSpacing !== defaultBookSettings.lineSpacing &&
    shouldAdjustProperty('lineHeight') &&
    isBlockElement(element) &&
    containsText(element)
  ) {
    adjustedStyle.lineHeight = await checkAndAdjustLineSpacing(doc, settings.lineSpacing, selector);
  }

  // Check and adjust horizontal margins (padding)
  //   if (
  //     settings.margin !== undefined &&
  //     settings.margin !== defaultBookSettings.margin &&
  //     isBlockElement(element) &&
  //     !(element.tagName === 'DIV')

  //     // !element.tagName.startsWith('H')
  //   ) {
  //     // if (shouldAdjustProperty('paddingLeft')) {
  //     const adjustedMarginLeft = await checkAndAdjustMargin(doc, settings.margin, selector, 'left', marginAdjustment);
  //     adjustedStyle.paddingLeft = adjustedMarginLeft;
  //     // }
  //     // if (shouldAdjustProperty('paddingRight')) {
  //     const adjustedMarginRight = await checkAndAdjustMargin(doc, settings.margin, selector, 'right', marginAdjustment);
  //     adjustedStyle.paddingRight = adjustedMarginRight;
  //     // }
  //   }

  console.log('settings.alignment', settings.alignment);
  console.log('getComputedStyle(element).textAlign', getComputedStyle(element).textAlign);
  console.log('isBlockElement(element)', isBlockElement(element));
  console.log('containsText(element)', containsText(element));
  console.log('shouldAdjustProperty(textAlign)', shouldAdjustProperty('textAlign'));

  // Check and adjust text alignment
  if (
    settings.alignment &&
    shouldAdjustProperty('textAlign') &&
    ['left', 'justify', 'start'].includes(settings.alignment) &&
    ['left', 'justify', 'start'].includes(getComputedStyle(element).textAlign) &&
    isBlockElement(element) &&
    containsText(element)
  ) {
    adjustedStyle.textAlign = settings.alignment;
  }

  // Check and adjust font family
  if (
    settings.fontFamily &&
    settings.fontFamily !== 'default' &&
    shouldAdjustProperty('fontFamily') &&
    // containsText(element) &&
    // Only exclude headers and pre-formatted text
    !['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'PRE', 'CODE'].includes(element.tagName)
  ) {
    // console.debug('Font family adjustment for:', element.tagName, selector);
    adjustedStyle.fontFamily = `${settings.fontFamily}, sans-serif`;
  }

  // Check and adjust font weight
  if (settings.fontWeight && settings.fontWeight !== 0 && shouldAdjustProperty('fontWeight') && containsText(element)) {
    adjustedStyle.fontWeight = `${settings.fontWeight}`;
  }

  // Check and adjust vertical margins
  if (settings.marginY !== undefined && settings.marginY !== -0.1) {
    if (shouldAdjustProperty('marginTop') && shouldAdjustProperty('marginBottom')) {
      adjustedStyle.marginTop = `${settings.marginY}em`;
      adjustedStyle.marginBottom = `${settings.marginY}em`;
    }
  }

  // Check and adjust text indent
  //   console.debug('textIndentVal', getComputedStyle(element)['textIndent']);
  if (
    settings.indent !== undefined &&
    settings.indent !== defaultBookSettings.indent &&
    ((['left', 'justify', 'start'].includes(getComputedStyle(element).textAlign) &&
      shouldAdjustProperty('textIndent')) ||
      !commonSelector)
    // containsText(element)
  ) {
    console.debug('textIndent adjustment', settings.indent, 'for selector', selector, 'and element', element);
    adjustedStyle.textIndent = `${settings.indent}em !important`;
  } else {
    console.debug(
      'no textIndent adjustment',
      settings.indent,
      'for selector',
      selector,
      'and element',
      element,
      'and align',
      getComputedStyle(element).textAlign,
      'and adjust',
      shouldAdjustProperty('textIndent')
    );
  }

  // Check and adjust text color
  //   if (getTheme(prefs.theme) === 'light') {
  // adjustedStyle.color = await checkAndAdjustTextColor(doc, prefs, selector);
  //   }

  return adjustedStyle;
};

const adjustFontSizeProportionally = (doc: Document, desiredSize: number, selector: string): string => {
  const element = doc.querySelector(selector);
  if (!element) return `${desiredSize}px`;
  const elementComputedStyle = window.getComputedStyle(element);
  const elementComputedSize = parseFloat(elementComputedStyle.fontSize);
  const parentComputedStyle = parseFloat(window.getComputedStyle(element.parentElement || element).fontSize);
  return `${elementComputedSize / parentComputedStyle}em`;
};

const checkAndAdjustLineSpacing = async (doc: Document, desiredSpacing: number, selector: string): Promise<string> => {
  const testElement = doc.querySelector(selector);
  if (!testElement) return `${desiredSpacing}`;

  const computedSpacing = window.getComputedStyle(testElement).lineHeight;
  if (computedSpacing !== `${desiredSpacing}`) {
    return `${desiredSpacing}`;
  }
  return `${desiredSpacing}`;
};
