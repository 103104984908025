import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import FolderIcon from '@mui/icons-material/Folder';
import type { GroupCollection } from '../types/book';
import { useTranslation } from '../utils/i18n';
import { getAllGroupsSorted, getRootGroup } from '../utils/groups';

/**
 * Interface for selected items count
 */
interface SelectedItemsCount {
  books: number;
  groups: number;
}

/**
 * Props for the MoveToDialog component
 */
interface MoveToDialogProps {
  open: boolean;
  onClose: () => void;
  onMove: (targetGroupId: string) => void;
  groups: GroupCollection;
  currentGroupId: string;
  isMovingBook?: boolean;
  isMultiSelect?: boolean;
  selectedItems?: SelectedItemsCount;
  activeGroupId?: string;
}

/**
 * MoveToDialog Component
 *
 * Shows a modal dialog with a collapsible tree view of the group structure.
 * Allows the user to select a target group to move one or multiple items into.
 *
 * @param props - Component properties
 * @returns React Element
 */
const MoveToDialog: React.FC<MoveToDialogProps> = ({
  open,
  onClose,
  onMove,
  groups,
  currentGroupId,
  isMovingBook = false,
  isMultiSelect = false,
  selectedItems = { books: 0, groups: 0 },
  activeGroupId,
}) => {
  const t = useTranslation();
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const sortedGroups = getAllGroupsSorted(groups);
  const rootGroup = getRootGroup(groups);

  // Reset state when dialog closes
  useEffect(() => {
    if (!open) {
      setSelectedGroupId('');
    }
  }, [open]);

  /**
   * Finds the path from root to the target group
   *
   * @param targetId - ID of the target group
   * @param groupsCollection - Collection of all groups
   * @returns Array of group IDs forming the path
   */
  const findPathToGroup = (targetId: string, groupsCollection: GroupCollection): string[] => {
    const path: string[] = [];
    let currentId = targetId;

    // Start from the target group and go up to the root
    while (currentId && currentId !== rootGroup?.id) {
      path.unshift(currentId);
      const group = groupsCollection[currentId];
      if (!group || !group.parentId) break;
      currentId = group.parentId;
    }

    // Add root group if it exists
    if (rootGroup) {
      path.unshift(rootGroup.id);
    }

    return path;
  };

  // When dialog opens, reset selection and expand to current group
  useEffect(() => {
    if (open) {
      // Expand the path to the current group
      if (currentGroupId && rootGroup) {
        const pathToCurrentGroup = findPathToGroup(currentGroupId, groups);
        setExpandedItems(pathToCurrentGroup);
      } else if (activeGroupId) {
        const pathToActiveGroup = findPathToGroup(activeGroupId, groups);
        setExpandedItems(pathToActiveGroup);
      } else {
        setExpandedItems(rootGroup ? [rootGroup.id] : []);
      }
      if (activeGroupId) {
        setTimeout(() => {
          setSelectedGroupId(activeGroupId);
          const activeGroupRef = document.querySelector(`li[id*="${activeGroupId}"]`);
          if (activeGroupRef) {
            activeGroupRef.scrollIntoView({ behavior: 'instant', block: 'start' });
          }
        }, 100);
      }
    }
  }, [open, currentGroupId, groups, rootGroup, activeGroupId, isMovingBook, isMultiSelect]);

  // Skip rendering if no root group is found
  if (!rootGroup) return null;

  /**
   * Builds a tree of group nodes for the SimpleTreeView component
   *
   * @param parentId - ID of the parent group
   * @returns Array of TreeItem components
   */
  const buildGroupTree = (parentId?: string): React.ReactNode[] => {
    return sortedGroups
      .filter((group) => group.parentId === parentId && (isMovingBook || group.id !== currentGroupId))
      .map((group) => {
        const childNodes = buildGroupTree(group.id);
        return (
          <TreeItem
            key={group.id}
            itemId={group.id}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5 }}>
                <FolderIcon color='action' sx={{ mr: 1 }} />
                {group.name}
              </Box>
            }
          >
            {childNodes}
          </TreeItem>
        );
      });
  };

  /**
   * Confirms the move operation
   */
  const handleMoveConfirm = () => {
    if (selectedGroupId) {
      onMove(selectedGroupId);
      onClose();
    }
  };

  // Check if we should disable the root group (only when moving a group, not a book)
  const isRootDisabled = !isMovingBook && !isMultiSelect && rootGroup.id === currentGroupId;

  // Generate a title for the dialog based on what is being moved
  const getDialogTitle = () => {
    if (isMultiSelect) {
      const bookText =
        selectedItems.books > 0
          ? selectedItems.books === 1
            ? t('BOOK_COUNT', { count: selectedItems.books })
            : t('BOOKS_COUNT', { count: selectedItems.books })
          : '';
      const groupText =
        selectedItems.groups > 0
          ? selectedItems.groups === 1
            ? t('GROUP_COUNT', { count: selectedItems.groups })
            : t('GROUPS_COUNT', { count: selectedItems.groups })
          : '';
      const itemsText = bookText && groupText ? `${bookText} ${t('AND')} ${groupText}` : bookText || groupText;

      return t('MOVE_MULTIPLE_ITEMS_TITLE', { items: itemsText });
    }

    return isMovingBook ? t('MOVE_BOOK') : t('MOVE_GROUP');
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='move-to-dialog-title' maxWidth='sm' fullWidth>
      <DialogTitle id='move-to-dialog-title'>{getDialogTitle()}</DialogTitle>
      <DialogContent>
        <SimpleTreeView
          aria-label='group structure'
          sx={{ height: 300, overflowY: 'auto', mt: 1 }}
          onItemClick={(_event, nodeId) => setSelectedGroupId(nodeId)}
          selectedItems={selectedGroupId}
          expandedItems={expandedItems}
          onExpandedItemsChange={(_event, newExpandedItems) => setExpandedItems(newExpandedItems)}
        >
          {/* Root node */}
          <TreeItem
            itemId={rootGroup.id}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5 }}>
                <FolderIcon color='primary' sx={{ mr: 1 }} />
                {rootGroup.name}
              </Box>
            }
            disabled={isRootDisabled}
          >
            {buildGroupTree(rootGroup.id)}
          </TreeItem>
        </SimpleTreeView>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <Button onClick={handleMoveConfirm} color='primary' disabled={!selectedGroupId}>
          {t('MOVE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveToDialog;
