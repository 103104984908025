import React from 'react';
import { Box, Typography, Container, Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import GoogleButton from 'react-google-button';

/**
 * LoginPage component.
 *
 * This component renders a login page with a Google sign-in button
 * and an 'About' link that navigates to the /about/ page.
 *
 * @component
 * @example
 * return <LoginPage />;
 */
const LoginPage: React.FC = () => {
  const origin = window.location.origin;
  const redirectUri = `${origin}/auth/callback`;

  const authState = JSON.stringify({
    return_url: origin,
    env: import.meta.env.DEV ? 'dev' : 'prod',
  });

  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
    import.meta.env.VITE_GOOGLE_CLIENT_ID
  }&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(
    'email profile openid'
  )}&response_type=code&access_type=offline&prompt=consent&state=${encodeURIComponent(authState)}`;

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component='h1' variant='h5' sx={{ mt: 4 }}>
          Welcome to
        </Typography>
        <Typography component='h1' variant='h4' sx={{ mb: 2 }}>
          <b>we</b>Reader
        </Typography>
        <GoogleButton
          onClick={() => {
            window.location.href = authUrl;
          }}
        />
        {/* Using React Router Link to perform client-side navigation */}
        <Box sx={{ mt: 8, textAlign: 'center', fontSize: '1.25rem' }}>
          <MuiLink component={RouterLink} to='/about/' underline='hover' sx={{ mx: 1 }}>
            About
          </MuiLink>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
