// Utility functions for managing books in local storage
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  defaultBookMeta,
  defaultBookSettings,
  defaultFavorite,
  defaultFontSize,
  defaultPrefs,
  defaultStatusBarBoxes,
} from './defaults';
import localforage from 'localforage';

dayjs.extend(utc);

import type {
  BookMeta,
  NoteCommentGroup,
  Note,
  NotesGroup,
  Person,
  Prefs,
  StatusBarBoxInfo,
  UpdateRecord,
  UserInfo,
  NoteComment,
  BookCollection,
  NotesStatus,
  NoteStatus,
  SnackbarMessage,
  GeepersQuery,
  Misc,
  AnchorLocations,
  RichLocation,
  RichSection,
  ChaptersInfo,
  Group,
} from '../types/book';
import {
  saveObject,
  loadObject,
  calculateFileHashFromArrayBuffer,
  saveFile,
  deleteFile,
  loadFile,
  calculateHashFromString,
} from './indexedDB';
import Epub, { EpubCFI, type Book } from 'epubjs';
import type Annotations from 'epubjs/types/annotations';
import {
  aiUser,
  localStorageBooksName,
  localStorageCurrentUserName,
  localStorageFollowingName,
  localStorageMiscName,
  localStorageNotesStatusName,
  locationSize,
} from '../config';
import { debounce } from './core';
import { isIOS, isMobileOnly, isDesktop } from 'react-device-detect';
import { logger } from './logger';
import daynight from 'daynight';
import { useOnlineStore } from '../store/onlineStore';
import { countComments } from './notes';
import isEmpty from 'lodash-es/isEmpty';
import { MIN_UPDATE_INTERVAL } from './constants';
import { createDeterministicUUIDv4 } from './crypto';
import { setViewport } from './viewports';
import { getRootGroup } from './groups';

// Import group functions as needed to avoid circular dependencies
// These will be initialized after our functions are defined
let groupUtils: {
  addItemToGroup: (groupId: string, itemId: string) => boolean;
  removeItemFromGroup: (groupId: string, itemId: string) => boolean;
  getGroups: () => { [id: string]: Group };
} | null = null;

// Function to initialize group utilities
export const initGroupUtils = (utils: typeof groupUtils) => {
  groupUtils = utils;
};

// Initialize localforage
localforage.config({
  name: 'WeReader',
  storeName: 'ePubData',
});

const chaptersStore = localforage.createInstance({
  name: 'WeReader',
  storeName: 'chapters',
});

const anchorsStore = localforage.createInstance({
  name: 'WeReader',
  storeName: 'anchors',
});

const chapterLocsStore = localforage.createInstance({
  name: 'WeReader',
  storeName: 'chapterLocs',
});

const cssStore = localforage.createInstance({
  name: 'WeReader',
  storeName: 'css',
});

let cachedBooks: BookCollection | null = null;
let cachedNotesStatus: NotesStatus | null = null;
let cachedCurrentUser: UserInfo | null = null;
let cachedFollowing: Person[] | null = null;
let cachedMisc: Misc | null = null;

export const loadFromLocalForage = async (): Promise<boolean> => {
  if (cachedBooks === null) {
    cachedBooks = await localforage.getItem(localStorageBooksName);
  }
  if (cachedNotesStatus === null) {
    cachedNotesStatus = await localforage.getItem(localStorageNotesStatusName);
  }
  if (cachedCurrentUser === null) {
    cachedCurrentUser = await localforage.getItem(localStorageCurrentUserName);
  }
  if (cachedFollowing === null) {
    cachedFollowing = await localforage.getItem(localStorageFollowingName);
  }
  if (cachedMisc === null) {
    cachedMisc = await localforage.getItem(localStorageMiscName);
  }
  //   const allGroupIds = getAllGroupIds(cachedCurrentUser?.prefs?.groups);
  const rootGroup = getRootGroup(cachedCurrentUser?.prefs?.groups);
  const archivedShelfId = Object.values(cachedCurrentUser?.prefs?.groups || {}).find(
    (group) => group.name === 'Archived'
  )?.id;
  const activeShelfId = Object.values(cachedCurrentUser?.prefs?.groups || {}).find(
    (group) => group.name === 'Active'
  )?.id;
  let updated = false;

  const hasRootAsAncestor = (book: BookMeta) => {
    if (!book.parentId) {
      return false;
    }
    //recursively follow parentIDs until root, else false
    let item = book as Group | BookMeta | undefined;
    while (item?.parentId) {
      item = cachedCurrentUser?.prefs?.groups?.[item.parentId];
      if (!item) {
        return false;
      }
    }
    return item?.id === 'root';
  };

  if (cachedBooks) {
    for (const book of Object.values(cachedBooks)) {
      if (book.location === 'active' && activeShelfId && !book.parentId) {
        cachedBooks[book.id] = { ...book, parentId: activeShelfId };
        updated = true;
      } else if (
        (book.location === 'archived' || book.location === 'archive' || book.archived) &&
        archivedShelfId &&
        !book.parentId
      ) {
        cachedBooks[book.id] = { ...book, parentId: archivedShelfId };
        updated = true;
      } else if (book.location !== 'trash' && !hasRootAsAncestor(book)) {
        console.log('found loose book, setting location to root group:', book.parentId, 'book title:', book.title);
        if (rootGroup) {
          cachedBooks[book.id] = { ...book, parentId: rootGroup.id };
          updated = true;
        } else {
          console.warn('Root group not found, cannot update book location');
        }
      }
    }
  }
  if (updated && cachedBooks) {
    updateBooksCache(cachedBooks);
  }
  console.info('loaded from localForage:', cachedMisc);
  return true;
};

export const getBooks = (parentId?: string): BookCollection => {
  const books = cachedBooks || {};
  if (parentId) {
    return Object.fromEntries(Object.entries(books).filter(([id]) => books[id].parentId === parentId));
  }
  return books;
};

export const pruneTrashEntries = (): void => {
  const books = getBooks();
  let pruned = false;

  for (const id in books) {
    if (books[id].location === 'trash') {
      delete books[id];
      pruned = true;
    }
  }

  if (pruned) {
    try {
      updateBooksCache(books);
      console.log('Pruned trash entries from books cache');
    } catch (error) {
      console.error('Error pruning trash entries:', error);
    }
  }
};

export const updateBooksCache = (books: BookCollection) => {
  try {
    cachedBooks = books;
    localforage.setItem(localStorageBooksName, books);
  } catch (error) {
    // if (error instanceof DOMException && error.name === 'QuotaExceededError') {
    //   const usedSpace = await calculateUsedStorage();
    //   console.warn(`Storage quota exceeded. Attempting to prune trash entries. Current usage: ${usedSpace}`);

    //   pruneTrashEntries();
    //   const updatedUsedSpace = await calculateUsedStorage();

    //   // Try to update the cache again after pruning
    //   try {
    //     localforage.setItem(localStorageBooksName, books);
    //     console.log('Successfully updated books cache after pruning trash entries');
    //     window.dispatchEvent(
    //       new CustomEvent('snackbarMessage', {
    //         detail: `Storage quota was exceeded. Pruned trash entries.\n\nStorage was ${usedSpace} but is now ${updatedUsedSpace}.`,
    //       })
    //     );
    //   } catch (retryError) {
    //     const message = `Storage quota still exceeded after pruning. Current usage: ${updatedUsedSpace}. Please delete some books manually to free up space.`;
    //     console.error(message);
    //     window.dispatchEvent(new CustomEvent('snackbarMessage', { detail: message }));
    //   }
    // } else {
    console.error('Error updating books cache:', error);
    // }
  }
};

// const calculateUsedStorage = async (): Promise<string> => {
//   let total = 0;
//   const keys = await localforage.keys();
//   for (let key of keys) {
//       total += (await localforage.getItem(key) as string).length * 2; // Multiply by 2 for UTF-16 encoding
//   }
//   return (total / 1024 / 1024).toFixed(2) + ' MB';
// };

export const getNotesStatus = (): NotesStatus => {
  return cachedNotesStatus || {};
};

export const getCurrentUser = (): UserInfo | null => {
  return cachedCurrentUser || null;
};

export const getFollowing = (): Person[] | null => {
  return cachedFollowing || null;
};

export const getMisc = (): Misc | null => {
  return cachedMisc || null;
};

export const updateNotesStatus = (notesStatus: NotesStatus) => {
  cachedNotesStatus = notesStatus;
  localforage.setItem(localStorageNotesStatusName, notesStatus);
};

export const updateCurrentUser = (user: UserInfo) => {
  cachedCurrentUser = user;
  console.log('updatedCurrentUser:', user);
  localforage.setItem(localStorageCurrentUserName, user);
  return user;
};

export const updateFollowing = (following: Person[]) => {
  cachedFollowing = following;
  localforage.setItem(localStorageFollowingName, following);
};

export const updateMisc = (misc: Misc) => {
  cachedMisc = misc;
  localforage.setItem(localStorageMiscName, misc);
};

/**
 * Adds default books for new users, including translations.
 * Downloads and adds The Metamorphosis in multiple languages.
 * @returns Promise that resolves when all books are added
 */
/**
 * Configuration for default books to add
 */
const DEFAULT_BOOKS = [
  {
    lang: 'en',
    title: '(en) Franz Kafka - Metamorphosis.epub',
  },
  //   {
  //     lang: 'de',
  //     title: '(de) Franz Kafka - Die Verwandlung.epub',
  //   },
  //   {
  //     lang: 'eo',
  //     title: '(eo) Franz Kafka - La Metamorfozo.epub',
  //   },
] as const;

/**
 * Adds default books for new users, including translations.
 * Downloads and adds The Metamorphosis in multiple languages.
 * @returns Promise that resolves when all books are added
 */
export const addDefaultBooks = async (): Promise<void> => {
  try {
    console.log('adding default books');

    // Helper function to create File from URL
    const createBookFile = async (url: string, filename: string): Promise<File> => {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch ${filename}: ${response.status}`);
      }
      const blob = await response.blob();
      console.log(`Downloaded ${filename}, size: ${blob.size} bytes`);
      return new File([blob], filename, { type: 'application/epub+zip' });
    };

    // Add all books and collect their IDs
    await Promise.all(
      DEFAULT_BOOKS.map(async ({ title }) => {
        const file = await createBookFile(`${import.meta.env.BASE_URL}files/${title}`, title);
        const id = await addBookFromFile(file);
        if (!id) {
          console.error(`Failed to add ${title}`);
        }
        return id;
      })
    );

    // Create translations mapping
    // const translations = DEFAULT_BOOKS.reduce((acc, { lang }, index) => {
    //   if (bookIds[index]) {
    //     acc[lang] = bookIds[index];
    //   }
    //   return acc;
    // }, {} as Record<string, string>);

    // Update all books with translations
    // for (const { lang } of DEFAULT_BOOKS) {
    //   const bookId = translations[lang];
    //   const book = getBook(bookId);
    //   if (book) {
    //     await updateBook({ ...book, translations }, true);
    //   }
    // }

    // console.log('added default books:', translations);
  } catch (error) {
    console.error('Error adding default books:', error);
  }
};

export const getNoteStatus = (noteId: string): NoteStatus | undefined => {
  return getNotesStatus()?.[noteId];
};

export const setNoteStatus = (noteId: string, noteStatus: NoteStatus) => {
  const notesStatus = getNotesStatus();
  notesStatus[noteId] = { timestamp: Date.now(), ...noteStatus };
  updateNotesStatus(notesStatus);
};

export const clearCurrentUser = () => {
  cachedCurrentUser = null;
  localforage.removeItem(localStorageCurrentUserName);
  console.log('cleared current user');
};

export const getTheme = (theme?: string): 'light' | 'dark' => {
  const currentTheme = theme || getPrefs().theme;
  return currentTheme === 'auto'
    ? daynight().light
      ? 'light'
      : 'dark'
    : (currentTheme as 'light' | 'dark') ||
        (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
};

export const getCommentList = (commentGroup?: NoteCommentGroup): NoteComment[] => {
  if (!commentGroup) {
    return [];
  }

  const comments = Object.values(commentGroup).filter((comment) => !comment.deleted);
  if (comments.length === 0) return [];

  return comments.sort((a, b) => {
    // If either comment is missing a timestamp, it should come first
    if (!a.timestamp) return -1;
    if (!b.timestamp) return 1;

    // Otherwise, sort by timestamp
    const dateA = dayjs(a.timestamp).valueOf();
    const dateB = dayjs(b.timestamp).valueOf();
    return dateA - dateB; // Earliest to most recent
  });
};

export const fetchGeepersData = async (data: GeepersQuery) => {
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  if (!getCurrentUser()) {
    return Promise.resolve({});
  }
  try {
    const response = await fetch('/api/geepers', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const result = await response.json();
    return result['response'];
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// const deleteCookie = (name = 'session') => {
//   document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
//   console.log('deleted cookie: ', name);
// };

// function getDifferences<T extends object>(newObj: T, oldObj: T): Partial<T> {
//   const diff: Partial<T> = {};
//   (Object.keys(newObj) as Array<keyof T>).forEach((key) => {
//     // Using a safer approach to check for property existence
//     if (Object.prototype.hasOwnProperty.call(oldObj, key) && newObj[key] !== oldObj[key]) {
//       diff[key] = newObj[key];
//     }
//   });
//   return diff;
// }

const polishBook = (book: BookMeta) => {
  if (!book.notes) {
    book.notes = {};
  }
  const email = getCurrentUser()?.email;
  Object.values(book.notes).forEach((note) => {
    if (email && !note.owner) {
      note.owner = email;
    } else if (note.owner === undefined) {
      note.owner = '';
    }
  });
  // Replace short note keys with note.id
  Object.entries(book.notes).forEach(([key, note]) => {
    if (key.length > 0 && key.length < 5 && note.id) {
      delete book.notes[key];
      book.notes[note.id] = note;
    }
  });
};

const mergeBookMeta = (oldBook: BookMeta, newBook: BookMeta): BookMeta => {
  return {
    ...oldBook,
    ...newBook,
    settings: {
      ...oldBook.settings,
      ...newBook.settings,
      updates: dedupe([...(oldBook.settings?.updates || []), ...(newBook.settings?.updates || [])]),
    },
    notes: mergeNotes(oldBook.notes, newBook.notes),
    visibleAt: {
      ...oldBook.visibleAt,
      ...newBook.visibleAt,
    },
    minutesRead: {
      ...oldBook.minutesRead,
      ...newBook.minutesRead,
    },
    chapterHashes: newBook.chapterHashes || oldBook.chapterHashes || [],
  };
};

const writeLocalBook = (book: BookMeta) => {
  const books = getBooks();
  books[book.id] = book;
  updateBooksCache(books);
  console.debug('wrote local book', book);
};

export const updateBook = async (bookUpdate: BookMeta, important: boolean = false): Promise<BookMeta | undefined> => {
  const isOnline = useOnlineStore.getState().isOnline;
  const books = getBooks();
  const localBook = books[bookUpdate.id] || defaultBookMeta;
  const updatedBook = mergeBookMeta(localBook, bookUpdate);
  const currentTime = Date.now();
  const lastRemoteUpdateTimestamp = getMisc()?.lastRemoteUpdateTimestamp || 0;
  const secondsSinceLastRemoteUpdate = Math.ceil((currentTime - lastRemoteUpdateTimestamp) / 1000);

  if (secondsSinceLastRemoteUpdate > 60 || important) {
    polishBook(updatedBook);
  }
  books[updatedBook.id] = updatedBook;
  updateBooksCache(books);

  if (secondsSinceLastRemoteUpdate < 60 && !important) {
    return updatedBook;
  }
  console.debug('updating book because ', important ? 'important' : 'secondsSinceLastRemoteUpdate');

  if (Object.keys(updatedBook).length > 0 && getCurrentUser()) {
    console.log('/api/update_book with: ', updatedBook);
    updateMisc({ ...getMisc(), lastRemoteUpdateTimestamp: currentTime });
    if (!isOnline) {
      return updatedBook;
    }

    try {
      const response = await fetch('/api/update_book', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedBook),
      });

      if (response.status === 401) {
        clearCurrentUser();
        // deleteCookie();
        fetchUser(true);
        return updatedBook;
      }

      const data = await response.json();
      if (!data.error) {
        console.debug('received data on updateBook', data);
      } else {
        console.error('data error on updateBook', data);
      }
    } catch (error) {
      console.error('failed on full update', updatedBook, error);
    }
    return updatedBook;
  }
  return;
};

/**
 * Merges two NotesGroup objects, giving priority to the primary group.
 *
 * @param secondary - The secondary NotesGroup to merge from.
 * @param primary - The primary NotesGroup to merge into, which takes precedence.
 * @returns A new NotesGroup containing merged notes from both inputs.
 *
 * @description
 * This function combines two NotesGroup objects, with the following behavior:
 * - All notes from the primary group are included in the result.
 * - Notes from the secondary group are added if they don't exist in the primary group.
 * - For notes that exist in both groups, the primary group's properties take precedence.
 * - Same behavior for the 'comments' property, which is merged from both groups.
 */
const mergeNotes = (secondary: NotesGroup, primary: NotesGroup): NotesGroup => {
  const primaryNotes: NotesGroup = { ...primary };

  for (const [key, secondaryNote] of Object.entries(secondary)) {
    if (primaryNotes[key]) {
      // If the note exists on primary, merge its properties
      const primaryNote = primaryNotes[key];
      primaryNotes[key] = {
        ...secondaryNote,
        ...primaryNote,
        comments: {
          ...secondaryNote.comments,
          ...primaryNote.comments,
        },
      };
    } else {
      // If the note doesn't exist on primary, add it
      primaryNotes[key] = secondaryNote;
    }
  }

  return primaryNotes;
};

const reportOpenBase = async (id: string): Promise<BookMeta | undefined> => {
  const isOnline = useOnlineStore.getState().isOnline;
  const user = fetchUser();
  if (!user) {
    console.log('No user found');
    return Promise.reject('No user found'); // or return Promise.resolve(null); if you prefer
  }

  const localBook = getBook(id);
  if (!localBook) {
    console.error('No book found with id', id);
    return Promise.reject('No book found');
    // return Promise.reject("No book found"); // or return Promise.resolve(null); if you prefer
  }

  if (!isOnline) {
    return localBook;
  }
  try {
    const response = await fetch('/api/open_book', {
      credentials: 'include', // Necessary to include cookies with the request
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Ensure you set the content type header
      },
      body: JSON.stringify(localBook),
    });
    let data = await response.json();
    if (!data.error) {
      console.log('received data on reportOpen', data);
      const remoteBook: BookMeta = data;

      // TODO: local notes should take precedence! but trying to fix that bug
      const mergedBook = mergeBookMeta(remoteBook, localBook);
      writeLocalBook(mergedBook);
      return mergedBook;
    } else {
      console.warn('data error on reportOpen', data);
      return;
      // return Promise.reject(data.error); // Reject the promise if there's an error
    }
  } catch (error) {
    console.error('Fetch error on reportOpen', error);
    // fetchUser(true);
    return;
    // return Promise.reject(error); // Reject the promise if the fetch fails
  }
};

export const upgrade = async () => {
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  const updateVersion = (storeVersion: number): number => {
    updateMisc({ ...getMisc(), storeVersion: storeVersion });
    console.log('set storeVersion to', storeVersion);
    return storeVersion;
  };

  await loadFromLocalForage();

  let version = getMisc()?.storeVersion || parseInt(localStorage.getItem('version') || '24', 10);
  const books = getBooks();
  if (!books) {
    return version;
  }

  try {
    console.debug('store version is currently ', version);
    if (version < 25) {
      console.log('upgrading storage to localforage');
      try {
        // Migrate data from localStorage to localforage
        const booksJson = localStorage.getItem(localStorageBooksName);
        if (booksJson) {
          const books = JSON.parse(booksJson);
          updateBooksCache(books);
        }

        const notesStatusJson = localStorage.getItem(localStorageNotesStatusName);
        if (notesStatusJson) {
          const notesStatus = JSON.parse(notesStatusJson);
          updateNotesStatus(notesStatus);
        }

        const following = JSON.parse(localStorage.getItem('following') || '[]') as Person[];
        updateFollowing(following);

        const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}') as UserInfo;
        updateCurrentUser(currentUser);

        const currentVersion = localStorage.getItem('currentVersion') || '4.4.2';
        const storeVersion = localStorage.getItem('storeVersion') || 24;
        const lastRemoteUpdateTimestamp = parseInt(localStorage.getItem('lastRemoteUpdateTimestamp') || '0', 10);
        const lastVersionCheck = parseInt(localStorage.getItem('lastVersionCheck') || '0', 10);

        const misc = {
          version: currentVersion,
          lastVersionCheck: lastVersionCheck,
          lastRemoteUpdateTimestamp: lastRemoteUpdateTimestamp,
          storeVersion: storeVersion,
        } as Misc;

        updateMisc(misc);

        if (getMisc()?.version && Object.keys(getBooks()).length > 0) {
          localStorage.removeItem(localStorageNotesStatusName);
          localStorage.removeItem(localStorageBooksName);
          localStorage.removeItem('version');
          localStorage.removeItem('currentVersion');
          localStorage.removeItem('lastVersionCheck');
          localStorage.removeItem('lastRemoteUpdateTimestamp');
          localStorage.removeItem('following');
          localStorage.removeItem('currentUser');
          console.log('Successfully migrated to localforage');
          version = updateVersion(25);
        } else {
          console.error('not ideal!');
          version = updateVersion(25);
        }
      } catch (error) {
        console.error('Error migrating to localforage:', error);
        throw error;
      }
    }
    // if (version < 26) {
    //   console.log('migrating to groups');
    //   try {
    //     // Migrate data from localStorage to localforage
    //     const user = getCurrentUser();
    //     if (!user) {
    //       console.error('No user found in migrateShelvesToGroups');
    //       return;
    //     }
    //     const groups = migrateShelvesToGroups(t);
    //     const activeGroupId = getRootGroup(groups)?.id;
    //     updateCurrentUser({ ...user, prefs: { ...user.prefs, groups, activeGroupId } });
    //     version = updateVersion(26);
    //   } catch (error) {
    //     console.error('Error migrating to groups:', error);
    //     throw error;
    //   }
    // }
  } catch (error) {
    console.error('Failed to update books at version', version);
    throw error;
  } finally {
    return version;
  }
};

const fetchFollowingBase = async () => {
  const isOnline = useOnlineStore.getState().isOnline;
  const user = fetchUser();
  if (!user) {
    return [];
  }
  if (!isOnline) {
    return [];
  }
  console.log('fetching following...');
  try {
    const response = await fetch('/api/get_following', {
      credentials: 'include',
    });

    if (response.status === 401) {
      // Clear all user data on 401 unauthorized
      clearCurrentUser();
      //   updateFollowing([]);
      //   updateNotesStatus({});
      //   updateMisc(null);
      // Force refresh user data
      //   await fetchUser(true);
      return [];
    }

    const data = await response.json();
    if (!data.error) {
      updateFollowing(data);
      return data;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Failed to fetch following', error);
    return [];
  }
};

export const getDisplayName = async (email: string) => {
  let following: Person[] = getFollowing() || [];
  // Attempt to find the user in the already fetched list
  let user = following.find((e) => e.email === email);

  if (!user) {
    // Fetch following if the user wasn't found or the list was empty
    following = await fetchFollowing();
    user = following.find((e) => e.email === email);
  }

  return user ? user.given_name : 'Anonymous';
};

export const getPerson = (email: string): Person | null => {
  if (email === 'ai') {
    return aiUser;
  }
  // Attempt to find the user in the already fetched list
  let user = getFollowing()?.find((e) => e.email === email);

  // if (!user) {
  //   // Fetch following if the user wasn't found or the list was empty
  //   following = await fetchFollowing();
  //   user = following.find((e) => e.email === email);
  // }

  if (user) {
    return user;
  } else {
    return null;
  }
};

// Function to load the EPUB file
// const loadEpub = async (id: string): Promise<Book | null> => {
//   const books = getBooks();
//   const book = books[id];
//   try {
//     const bookData = await getArrayBuffer(id);
//     if (!bookData) {
//       console.error(`Failed to get ArrayBuffer for book ${id}`);
//       return null;
//     }
//     const epubBook = Epub(bookData);
//     await epubBook.ready;
//     return epubBook;
//   } catch (error) {
//     console.error(`Failed to load Epub for book ${id}`, error);
//     return null;
//   }
// };

export const cleanUpNotes = (book: BookMeta): boolean => {
  // Filter out deleted notes
  book.notes = Object.fromEntries(Object.entries(book.notes).filter(([_, note]) => !note.deleted));

  let changed = false;
  Object.values(book.notes).forEach((note: Note) => {
    if (!note.timestamp) {
      note.timestamp = new Date().toISOString();
      changed = true;
    }
    if (!note.id) {
      note.id = crypto.randomUUID();
      changed = true;
    }
    if (note.comment && (!note.comments || Object.keys(note.comments).length === 0)) {
      const id = crypto.randomUUID();
      note.comments = {
        [id]: {
          text: note.comment,
          owner: note.owner,
          timestamp: note.timestamp || new Date().toISOString(),
          id: id,
          reactions: {},
        },
      };
      changed = true;
    }
  });
  return changed;
};

export const getBook = (id: string): BookMeta | undefined => {
  const books = getBooks();
  let book = books[id];
  if (!book) {
    return;
  }
  if (!book.notes) {
    book.notes = {};
  } else if (cleanUpNotes(book)) {
    console.warn('cleanUpNotes changed book ', book);
    updateBook(book);
  }
  return book;
};

export const getUserId = (): string => {
  const user = getCurrentUser();
  return user?.email || '';
};

let fetchingUser = false;
export const fetchUser = async (force: boolean = false): Promise<UserInfo | null | undefined> => {
  const user = getCurrentUser();
  if (!force && user) {
    return user;
  }
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return user ?? undefined;
  }

  if (fetchingUser) {
    return;
  }
  fetchingUser = true;

  console.log('fetching current user from server...');
  // Fetch the current user's information from the backend
  try {
    const response = await fetch('/api/current_user', {
      credentials: 'include', // Necessary to include cookies with the request
    });
    const data = await response.json();
    if (!data.error && data.email) {
      console.log('fetched user from server: ', data);
      updateCurrentUser(data);
      fetchingUser = false;
      return data;
    } else {
      //   clearCurrentUser();
      // deleteCookie();
      console.error('data error fetching user: ', data);
      // window.dispatchEvent(new CustomEvent('logout'));
      fetchingUser = false;
      return null; // No user logged in, or session expired
    }
  } catch (error) {
    console.error('error fetching user: ', error);
    fetchingUser = false;
    if (force && user) {
      return user;
    }
    return undefined;
  } finally {
    fetchingUser = false;
  }
};

export const dedupe = (data: UpdateRecord[], field = 0): UpdateRecord[] => {
  const seen = new Set();
  return data.filter((item) => {
    if (seen.has(item[field])) {
      return false;
    }
    seen.add(item[field]);
    return true;
  });
};

const getUpdatesBase = async (id: string): Promise<UpdateRecord[]> => {
  const book = getBook(id);
  if (!book) {
    return [];
  }

  const user = fetchUser();
  if (!user) {
    console.log('No user found in getUpdatesBase');
    return book.settings?.updates || [];
  }

  const hash = book.hash;
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return [];
  }
  console.log('/api/get_updates', hash);
  try {
    const response = await fetch('/api/get_updates', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ hash: hash }),
    });

    let data = await response.json();
    if (!data.error) {
      //   await updateBook({ ...book, settings: { updates: data } });
      //   writeLocalBook(mergeBookMeta({ ...book, settings: { updates: data } }, book));
      return data; // Successfully return the data
    } else {
      console.error('data error: ', data);
      return []; // Return empty array if there's an error in the data
    }
  } catch (error) {
    console.error('getUpdates error: ', error);
    return []; // Return empty array on fetch error
  }
};

export const logoutUser = () => {
  // Optional: Inform your backend to invalidate the session
  clearCurrentUser();
  //   deleteCookie();
  fetch('/api/logout', {
    credentials: 'include', // If you're using cookies to manage session
    // Additional fetch options as needed
  })
    .then((response) => {
      // Handle response
      if (response.ok) {
        // Clear user state/frontend session
        console.log('logged out okay');
      }
    })
    .catch((error) => {
      console.error('Logout error', error);
    });
};

export const saveLocations = async (id: string, locations: any) => {
  await saveObject(`locations-${id}`, locations);
  console.debug('saveLocations for ', id);
};

export const loadLocations = async (id: string) => {
  //   const locs = JSON.parse(localStorage.getItem("locations") || "{}");
  // if (Object.keys(locs).includes(id))
  const locs = await loadObject(`locations-${id}`);
  if (!locs) {
    console.log('no locations for ', id);
  }
  //   console.debug("loadLocations loc = ", locs);
  return locs;
};

/**
 * Updates the current user's information in local storage.
 *
 * @param {Partial<UserInfo>} user - Partial user information to update.
 * @throws {Error} Logs an error if no current user is found.
 * @see {@link mergePrefs}
 *
 * @description
 * This function retrieves the current user from local storage,
 * merges the new user information with the existing data,
 * and updates the local storage with the combined information.
 * It also merges the user preferences using the `mergePrefs` function.
 * If no current user is found, it logs an error and returns without making changes.
 */
export const updateUser = (user: Partial<UserInfo>, prefs?: Partial<Prefs>): UserInfo | undefined => {
  const savedUser = getCurrentUser();
  if (!savedUser) {
    console.error('No user found in updateUser');
    return;
  }
  const newPrefs = mergePrefs(mergePrefs(savedUser.prefs, user.prefs), prefs);
  const newUser = {
    ...savedUser,
    ...user,
    prefs: newPrefs,
  };
  return updateCurrentUser(newUser);
};

/**
 * Merges user preferences with new preferences and default preferences.
 *
 * @param {Partial<Prefs>} userPrefs - The existing user preferences.
 * @param {Partial<Prefs>} newPrefs - The new preferences to be merged.
 * @returns {Prefs} A new Prefs object containing the merged preferences.
 *
 * @description
 * This function combines three sources of preferences:
 * 1. Default preferences (defaultPrefs)
 * 2. Existing user preferences (userPrefs)
 * 3. New preferences to be applied (newPrefs)
 *
 * It handles special merging for the statusBarBoxes array and the favorite object.
 * For statusBarBoxes, it combines all sources and removes duplicates based on position.
 * For favorite, it performs a shallow merge of all sources.
 */
export const mergePrefs = (userPrefs: Partial<Prefs> = {}, newPrefs: Partial<Prefs> = {}): Prefs => {
  const isArray = (value: any): value is any[] => Array.isArray(value);

  return {
    ...defaultPrefs,
    ...userPrefs,
    ...newPrefs,
    statusBarBoxes: Array.from(
      [
        ...defaultStatusBarBoxes,
        ...(isArray(userPrefs?.statusBarBoxes) ? userPrefs.statusBarBoxes : []),
        ...(isArray(newPrefs?.statusBarBoxes) ? newPrefs.statusBarBoxes : []),
      ]
        .reduce((acc, item) => {
          acc.set(item.position, item);
          return acc;
        }, new Map<number, StatusBarBoxInfo>())
        .values()
    ),
    favorite: {
      ...defaultFavorite,
      ...(userPrefs?.favorite || {}),
      ...(newPrefs?.favorite || {}),
    },
  };
};

export const getPrefs = (): Prefs => {
  //   const userJson = fetchUser();
  //   return mergePrefs(userJson?.prefs);
  //   return mergePrefs(getCurrentUser()?.prefs);
  return getCurrentUser()?.prefs || {};
};

/**
 * Pushes user preferences to the server.
 *
 * @async
 * @returns {Promise<any>} A promise that resolves when the preferences are successfully updated, or rejects with an error.
 *
 * @description
 * This function merges the provided partial preferences with the existing user preferences,
 * then sends the updated preferences to the server. It handles various error cases,
 * including no user found, server errors, and network errors.
 */
export const pushPrefs = async (): Promise<any> => {
  const user = await fetchUser();
  if (!user) {
    console.log('No user found in update_prefs');
    return Promise.reject('No user found');
  }
  //   if (!fetchedUser) {
  //     console.warn('pushPrefs called before user was fetched');
  //     return;
  //   }
  const prefs = user.prefs;
  console.debug('pushPrefs', prefs);

  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  try {
    const response = await fetch('/api/update_prefs', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prefs: prefs,
      }),
    });
    const data = await response.json();
    if (!data.error) {
      console.log('pushed new prefs: ', prefs);
      return;
    } else {
      console.error('data error on update_prefs', data);
      return Promise.reject(data.error);
    }
  } catch (error) {
    console.error('Fetch error on update_prefs', error);
    fetchUser(true);
    return Promise.reject(error);
  }
};

const getStreaksBase = async (
  users: string[]
): Promise<{ [id: string]: { days: number; today: boolean } } | undefined> => {
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  try {
    const response = await fetch('/api/get_streaks', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(users),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch streaks');
    }
    const data = await response.json();
    console.log('fetched streaks:', data);
    return data;
  } catch (error) {
    useOnlineStore.setState({ isOnline: false });
    console.error('Error fetching streaks:', error);
    throw error;
  }
};

const getBookProgressAndNotesBase = async (hashes: string[]): Promise<any> => {
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  const startTime = Date.now();
  try {
    const response = await fetch('/api/get_progress_and_notes', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ hashes }),
    });
    console.log(`getBookProgressAndNotesBase fetch took ${Date.now() - startTime}ms`);

    if (!response.ok) {
      throw new Error('Failed to fetch book progress and notes');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching book progress and notes:', error);
    throw error;
  }
};

export const getBookProgressAndNotes = async (hashes: string[]): Promise<any> => {
  return await getBookProgressAndNotesBase(hashes);
};

const getBookProgressBase = async (hashes: string[]): Promise<any> => {
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  const startTime = Date.now();
  try {
    const response = await fetch('/api/get_progress', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(hashes),
    });
    console.log(`getBookProgressBase fetch took ${Date.now() - startTime}ms`);

    if (!response.ok) {
      throw new Error('Failed to fetch book progress');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching book progress:', error);
    throw error;
  }
};

export const getBookData = async (book: BookMeta): Promise<any> => {
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    return;
  }
  try {
    const response = await fetch('/api/get_book', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(book),
    });
    if (!response.ok) {
      return {};
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching book progress:', error);
    return {};
  }
};

/**
 * Calculates the median value from an array of numbers within specified bounds.
 *
 * @param values - Array of numbers to calculate median from
 * @param midpoint - The relative position to select (0.5 = median)
 * @param lowerBound - Optional lower bound (0.0 to 1.0) to filter values
 * @param upperBound - Optional upper bound (0.0 to 1.0) to filter values
 * @returns The median value, or 0 if no values remain after filtering
 */
// const median = (values: number[], midpoint: number = 0.5, lowerBound?: number, upperBound?: number): number => {
//   if (values.length === 0) return 0;

//   // Sort the array
//   values.sort((a, b) => a - b);

//   // Calculate bounds indices
//   const start = lowerBound ? Math.floor(values.length * lowerBound) : 0;
//   const end = upperBound ? Math.ceil(values.length * upperBound) : values.length;

//   // Filter values within bounds
//   const filteredValues = values.slice(start, end);
//   if (filteredValues.length === 0) return 0;

//   // Calculate median position within filtered values
//   const mid = Math.round(filteredValues.length * midpoint);
//   return filteredValues[mid];
// };

/**
 * Calculates the mean value from an array of numbers within specified bounds.
 *
 * @param values - Array of numbers to calculate mean from
 * @param lowerBound - Optional lower bound (0.0 to 1.0) to filter values
 * @param upperBound - Optional upper bound (0.0 to 1.0) to filter values
 * @returns The mean value, or 0 if no values remain after filtering
 */
const mean = (values: number[], lowerBound?: number, upperBound?: number): number => {
  if (values.length === 0) return 0;

  // Sort the array
  values.sort((a, b) => a - b);

  // Calculate bounds indices
  const start = lowerBound ? Math.floor(values.length * lowerBound) : 0;
  const end = upperBound ? Math.ceil(values.length * upperBound) : values.length;

  // Filter values within bounds
  const filteredValues = values.slice(start, end);
  if (filteredValues.length === 0) return 0;

  // Calculate mean of filtered values
  const sum = filteredValues.reduce((acc, val) => acc + val, 0);
  return sum / filteredValues.length;
};

const convertBPStoWPM = (booksPerSecond: number[], wordCount: number): number => {
  //   const wordsPerMinute = wordCount * median(booksPerSecond, 0.5) * 60;
  const wordsPerMinute = wordCount * mean(booksPerSecond, 0.25, 0.75) * 60;
  return Math.round(wordsPerMinute);
};

const calculateBPS = (
  data: UpdateRecord[],
  lowerBoundTimestamp = 0,
  lowerBoundSeconds = 10,
  upperBoundSeconds = 5 * 60,
  upperBoundPropDiff = 0.05
): number[] => {
  let recentData = data;
  if (lowerBoundTimestamp) {
    recentData = data.filter(([time]) => time >= lowerBoundTimestamp);
  }
  if (recentData.length == 0) {
    return [];
  }

  recentData.sort((a, b) => a[0] - b[0]);

  const booksPerSecond: number[] = [];
  const seenProps = new Set<number>();

  for (let i = 1; i < recentData.length; i++) {
    const [prevTime, prevProp] = recentData[i - 1];
    while (i < recentData.length - 1 && seenProps.has(recentData[i][1])) {
      i++;
    }
    const [currentTime, currentProp] = recentData[i];

    const timeDiff = (currentTime - prevTime) / 1000;
    const propDiff = currentProp - prevProp;
    if (lowerBoundSeconds < timeDiff && timeDiff < upperBoundSeconds && 0 < propDiff && propDiff < upperBoundPropDiff) {
      seenProps.add(recentData[i][1]);
      booksPerSecond.push(propDiff / timeDiff);
    }
  }

  if (booksPerSecond.length < 20) {
    console.debug('booksPerSecond:', booksPerSecond);
  }
  return booksPerSecond;
};

export const calculateWPM = (
  data: UpdateRecord[],
  wordCount: number,
  hoursAgo?: number,
  _recentDataPoints: number = 10,
  savedTotalWPM?: number
): number | undefined => {
  if (savedTotalWPM && !hoursAgo) {
    return savedTotalWPM;
  }

  if (data.length < 1) {
    return undefined;
  }

  let totalWPM;
  if (!savedTotalWPM) {
    const totalBooksPerSecond = calculateBPS(data);
    totalWPM = convertBPStoWPM(totalBooksPerSecond, wordCount);
  } else {
    totalWPM = savedTotalWPM;
  }

  return totalWPM;
  if (!hoursAgo) {
    return totalWPM;
  }

  //   const recentBooksPerSecond = calculateBPS(data, Date.now() - 1000 * 60 * 60 * hoursAgo);

  //   const recentWPM = convertBPStoWPM(recentBooksPerSecond, wordCount);
  //   console.log('recentWPM:', recentWPM, 'totalWPM:', totalWPM);
  //   const weightedWPM = Math.ceil(
  //     (totalWPM * Math.max(recentDataPoints - recentBooksPerSecond.length, 0) +
  //       recentWPM * Math.min(recentBooksPerSecond.length, recentDataPoints)) /
  //       recentDataPoints
  //   );
  //   return weightedWPM;
};

export const getTotalWPM = async (book: BookMeta, lazy = false): Promise<number | undefined> => {
  if (
    book.wpm !== undefined &&
    book.settings.updates &&
    book.settings.updates.length > 1 &&
    (lazy ||
      (book.wpmTimestamp &&
        book.settings.updates[book.settings.updates.length - 1][0] - book.wpmTimestamp < MIN_UPDATE_INTERVAL))
  ) {
    return book.wpm;
  } else if (book.wordCount && book.settings.updates && book.settings.updates.length > 1) {
    const wpm = calculateWPM(book.settings.updates, book.wordCount, 0) ?? 0;
    // updateBook({ ...book, wpm, wpmTimestamp: Date.now() });
    return wpm;
  } else {
    return undefined;
  }
};

const countWords = (text: string): number => {
  return Math.ceil(text.length / 5.5);
};

export const getTotalWordCount = async (book: Book): Promise<[number, number[]]> => {
  let totalWords = 0;
  try {
    await book.ready;
    const chapterCountsWithIndex: [number, number][] = [];
    for (const entry of book.packaging.spine as any[]) {
      const section: any = await book.load(entry.href);
      if (section) {
        const text = section.body.textContent || '';
        const amount = countWords(text);

        chapterCountsWithIndex.push([entry.index, amount]);
        totalWords += amount;
      }
    }
    // Sort the chapter counts based on the index and extract only the counts
    const chapterCounts = chapterCountsWithIndex.sort((a, b) => a[0] - b[0]).map(([_, count]) => count);
    console.log('Total Word Count:', Math.round(totalWords));
    return [Math.round(totalWords), chapterCounts];
  } catch (error) {
    console.error('Error processing the ePub:', error);
    return [0, []];
  }
};

/**
 * Retrieves the anchor mappings for a given book.
 *
 * @param {Book} book - The EPUB.js Book instance.
 * @returns {Promise<{ [href: string]: { epubcfi: string; location: number } } | undefined>} - The anchor mappings or undefined if not found.
 */
export const getAnchorMappings = async (
  book: Book
): Promise<{ [href: string]: { epubcfi: string; location: number } } | undefined> => {
  const anchorsCacheKey = `anchors-${book.key()}`;
  return await loadObject(anchorsCacheKey);
};

/**
 * Loads a Book object given its BookMeta ID using Epub.js.
 *
 * @param {string} bookId - The unique identifier of the book to load.
 * @returns {Promise<Book | undefined>} - A promise that resolves to the loaded Book object, or undefined if the book or file does not exist.
 *
 * @description
 * This function retrieves the BookMeta object using the provided `bookId`,
 * loads the corresponding EPUB file from IndexedDB, and initializes an Epub.js Book instance.
 * It ensures the book is fully loaded by awaiting the `ready` state of the Book.
 *
 * @example
 * ```typescript
 * const book = await loadBook('book-id-123');
 * if (book) {
 *   // Use the book object
 * }
 * ```
 */
export const loadBook = async (bookId: string): Promise<Book | undefined> => {
  console.debug('loading book with id: ', bookId);
  try {
    // Retrieve the BookMeta object for the given bookId
    const bookMeta = getBook(bookId);
    if (!bookMeta) {
      console.error(`BookMeta not found for ID: ${bookId}`);
      return undefined;
    }

    // Load the corresponding EPUB file from IndexedDB
    const fileBlob = await loadFile(bookId);
    if (!fileBlob) {
      console.error(`File not found for BookMeta ID: ${bookId}`);
      return undefined;
    }

    // Convert the Blob to an ArrayBuffer
    const arrayBuffer = await fileBlob.arrayBuffer();

    // Initialize the Epub.js Book instance
    const book = Epub(arrayBuffer);

    // Wait until the book is fully loaded
    await book.ready;

    return book;
  } catch (error) {
    console.error(`Error loading book with ID ${bookId}:`, error);
    return undefined;
  }
};

/**
 * Retrieves the list of chapters and anchor locations for a given book.
 * Utilizes caching with IndexedDB to store and retrieve chapters and anchor locations.
 *
 * @param {BookMeta} meta - The metadata of the book.
 * @param {Book} [withBook] - An optional EPUB.js Book instance to use for loading the book.
 * @returns {Promise<ChaptersInfo | null>} - An object containing the chapters, locations, and anchors, or null if not found.
 */
export const getChapters = async (meta: BookMeta, withBook?: Book): Promise<ChaptersInfo> => {
  const chaptersCacheKey = `chapters-${meta.id}`;
  const anchorsCacheKey = `anchors-${meta.id}`;
  const chapterLocsCacheKey = `chapterLocs-${meta.id}`;

  // Check if chapters are already cached
  const cachedChapters = await chaptersStore.getItem<RichSection[]>(chaptersCacheKey);
  const cachedAnchors = await anchorsStore.getItem<AnchorLocations>(anchorsCacheKey);
  const cachedChapterLocs = await chapterLocsStore.getItem<RichLocation[]>(chapterLocsCacheKey);
  const cachedCss = await getCachedCss(meta.id, withBook);

  if (
    cachedChapters &&
    cachedAnchors &&
    cachedChapterLocs &&
    cachedCss &&
    cachedChapters[0].fontSize &&
    (meta.chapterHashes || []).length === cachedChapters.length
  ) {
    console.debug('Chapters, anchors, and chapter locations loaded from localForage cache.');
    return { sections: cachedChapters, locations: cachedChapterLocs, anchors: cachedAnchors };
  }

  const chapters: RichSection[] = [];
  const anchors: AnchorLocations = {};
  const chapterLocations: RichLocation[] = [];

  const book = withBook || (await loadBook(meta.id));
  if (!book) {
    console.error('Book not found for ID:', meta.id);
    return { sections: [], locations: [], anchors: {} };
  }
  try {
    await book.ready;
    let totalChars = 0;
    const css = cachedCss.join('\n\n').trim();

    for (const entry of book.packaging.spine as any[]) {
      const sectionDoc = (await book.load(entry.href)) as Document;
      const base = entry.cfiBase;

      if (sectionDoc) {
        const firstElementChild = sectionDoc.firstElementChild;
        if (firstElementChild) {
          const epubcfi = new EpubCFI(firstElementChild, base);
          const location = Math.floor(totalChars / locationSize);
          chapterLocations.push({ epubcfi: epubcfi.toString(), location });
        }

        let chapterTextLength = 0;

        const processNode = (node: Node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            chapterTextLength += (node.textContent || '').length;
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as Element;
            if (element.id) {
              const href = `${entry.href.split('/').slice(-1)[0]}#${element.id}`;
              const epubcfi = new EpubCFI(element, base);
              const location = Math.floor((totalChars + chapterTextLength) / locationSize);
              anchors[href] = { epubcfi: epubcfi.toString(), location };
            }

            for (const childNode of element.childNodes) {
              processNode(childNode);
            }
          }
        };

        processNode(sectionDoc.body);

        totalChars += chapterTextLength;
        const html = sectionDoc.documentElement.outerHTML;
        const fontSize = calculatePrimaryFontSize(html, css);
        chapters.push({
          base,
          href: entry.href,
          html: html,
          text: sectionDoc.documentElement.textContent || '',
          hash: await calculateHashFromString(sectionDoc.documentElement.textContent || ''),
          fontSize: fontSize,
        });
      }
    }

    // calculate the mode of font sizes
    let fontSizes = chapters.filter((chapter) => chapter.html.length > 1024).map((chapter) => chapter.fontSize);
    let modeFontSize = fontSizes[0] || 16;
    let maxFrequency = 1;
    const fontSizeFrequency: Record<number, number> = {};

    fontSizes.forEach((size) => {
      const roundedSize = Math.round(size * 10) / 10; // Round to nearest 0.1 for frequency counting
      fontSizeFrequency[roundedSize] = (fontSizeFrequency[roundedSize] || 0) + 1;
    });

    for (const [size, frequency] of Object.entries(fontSizeFrequency)) {
      const numericSize = parseFloat(size);
      if (frequency > maxFrequency) {
        maxFrequency = frequency;
        modeFontSize = numericSize;
      }
    }

    if (!meta.chapterHashes || meta.chapterHashes.length !== chapters.length) {
      const chapterHashes = chapters.map((chapter) => chapter.hash);
      await updateBook({ ...meta, chapterHashes });
    }

    if (modeFontSize !== meta.primaryFontSize) {
      console.log('detected primary font size', modeFontSize, 'for ', meta.title, 'id', meta.id);
      await updateBook({ ...meta, primaryFontSize: modeFontSize });
    }

    // Save chapters and anchor mappings to IndexedDB
    await chaptersStore.setItem(chaptersCacheKey, chapters);
    await anchorsStore.setItem(anchorsCacheKey, anchors);
    await chapterLocsStore.setItem(chapterLocsCacheKey, chapterLocations);
    console.debug('Chapters and anchors saved to localForage cache:', anchors);
  } catch (error) {
    console.error('Error loading chapters:', error);
  }

  return { sections: chapters, locations: chapterLocations, anchors: anchors };
};

export const pullBookRevs = async () => {
  try {
    const response = await fetch('/api/pull_revs', {
      credentials: 'include',
      method: 'GET',
    });

    if (response.ok) {
      const revs = await response.json();
      const books = getBooks();
      Object.keys(revs).forEach((id) => {
        if (books[id]) {
          books[id]._rev = revs[id];
        } else {
          console.warn(`Book with id ${id} not found in local storage`);
        }
      });
      updateBooksCache(books);
    } else {
      console.error('Failed to update book revs', response.status);
    }
  } catch (error) {
    console.error('Error updating book revs', error);
  }
};

export const scanBookForContext = async (
  book: Book,
  meta: BookMeta,
  searchTerm: string,
  progress: number,
  before: number = 250,
  after: number = 250,
  maxResults: number = 15
): Promise<string[]> => {
  const results: string[] = [];
  try {
    await book.ready;
    const { sections } = await getChapters(meta, book);
    const wholeBook = sections.map((section) => section.text).join('\n\n');
    const bookToDate = wholeBook.slice(0, Math.ceil(progress * wholeBook.length));
    const regex = new RegExp(`.{0,${before}}${searchTerm}.{0,${after}}`, 'g');

    let match;
    while ((match = regex.exec(bookToDate)) !== null) {
      results.push(match[0]);
    }

    if (results.length > maxResults) {
      const first = results[0];
      const last = results[results.length - 1];
      const middleResults = results.slice(1, -1);

      const selectedMiddleResults = middleResults.sort(() => 0.5 - Math.random()).slice(0, maxResults - 2);

      return [first, ...selectedMiddleResults, last];
    }

    return results;
  } catch (error) {
    console.error('Error searching in book:', error);
    return [];
  }
};

export const uploadLog = async (logMessages: string[], clearLog: () => void) => {
  if (logMessages.length === 0) {
    console.log('nothing in log to upload');
    return;
  }
  console.log('uploading log...');
  try {
    const response = await fetch('/api/upload_log', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ logs: logMessages }),
    });

    if (response.ok) {
      console.log('Log successfully sent to the server, clearing');
      clearLog();
      return;
    } else {
      console.error('Failed to send logs', response.status);
    }
  } catch (error) {
    console.error('Error sending logs to server', error);
  }
};

// Add this new function to books.ts

/**
 * Handles the back navigation functionality for the e-book reader.
 *
 * @param updates - An array of UpdateRecord objects containing timestamp and progress information.
 * @param currentUpdate - The current UpdateRecord to start the back navigation from.
 * @param setSnackbarMessage - A function to set a message in the snackbar UI component.
 * @param goToPercentage - A function to navigate to a specific percentage in the book.
 * @param setUsedBack - A function to set whether updates are allowed.
 * @param historyRef - A mutable ref object to store the navigation history timestamp.
 * @returns The new timestamp after navigating back.
 */
export const handleBackNavigation = (
  updates: UpdateRecord[],
  currentUpdate: UpdateRecord,
  setSnackbarMessage: (message: SnackbarMessage) => void,
  goToPercentage: (progress: number) => void,
  setUsedBack: (usedBack: boolean) => void,
  historyRef: React.MutableRefObject<UpdateRecord | undefined>
): number => {
  let timestamp = currentUpdate[0];
  let progress = currentUpdate[1];
  while (progress === -1 || progress === currentUpdate[1]) {
    [timestamp, progress] = getBack(updates, [timestamp, progress]);
  }
  const formattedDate = dayjs.utc(timestamp).local().format('YYYY-MM-DD HH:mm');
  setSnackbarMessage({ text: `Skipped back to ${formattedDate} at ${(100 * progress).toFixed(1)}%`, duration: 3000 });
  console.debug('back to', timestamp, progress);
  setUsedBack(true);
  historyRef.current = [timestamp, progress];
  goToPercentage(progress);
  return timestamp;
};

/**
 * Retrieves the most recent update record that occurred before a specified time threshold.
 *
 * @param updates - An array of UpdateRecord objects, each containing a timestamp and a value.
 * @param lastTimestamp - The reference timestamp to compare against (default: current timestamp).
 * @param minSeconds - The minimum number of seconds to look back from the lastTimestamp (default: 60 seconds).
 * @returns The most recent UpdateRecord that occurred before the specified time threshold, or [0,0] if no updates exist.
 */
export const getBack = (
  updates: UpdateRecord[],
  lastUpdate: UpdateRecord = [Date.now(), -1],
  minSeconds: number = 60,
  minProgress: number = 0.001
): UpdateRecord => {
  if (updates.length === 0) {
    return [0, 0];
  }
  const [lastTimestamp, lastProgress] = lastUpdate;
  const index = updates.findLastIndex(
    (update) => update[0] < lastTimestamp - minSeconds * 1000 && Math.abs(update[1] - lastProgress) > minProgress
  );
  if (index === -1) {
    return updates[updates.length - 1];
  }
  return updates[index];
};

export const guessCurrent = (updates?: UpdateRecord[], progress?: number): number => {
  console.log(updates);
  if (!updates || updates.length === 0 || !progress) {
    return 0;
  }

  updates.sort((a, b) => a[0] - b[0]);
  return (
    updates.findLast(
      (update, i) =>
        update[1] !== progress &&
        i > 1 &&
        updates[i][0] - updates[i - 1][0] > 10000 &&
        updates[i - 1][0] - updates[i - 2][0] > 10000 &&
        updates[i][1] > updates[i - 1][1] &&
        updates[i - 1][1] > updates[i - 2][1]
    )?.[1] ||
    updates.findLast((update, _) => update[1] !== progress)?.[1] ||
    progress
  );
};

/**
 * Adjusts the fullscreen class on the document body and the .epub-container element
 * based on the current fullscreen state.
 */
export const adjustFullscreenClass = () => {
  const doc = document.querySelector('.epub-container');
  const isFullscreen = checkIfFullscreen();
  if (doc) {
    console.log('setting fullscreen to ', isFullscreen);
    if (isFullscreen) {
      document.body.classList.add('fullscreen');
      doc.classList.add('fullscreen');
    } else {
      document.body.classList.remove('fullscreen');
      doc.classList.remove('fullscreen');
    }
  }
};

/**
 * Checks if the document is currently in fullscreen mode.
 *
 * @returns {boolean} - Returns true if the document is in fullscreen mode, otherwise false.
 */
export const checkIfFullscreen = (): boolean => {
  return document.fullscreenElement !== null;
};

/**
 * Toggles the fullscreen mode based on the desired state.
 *
 * @param {boolean | undefined} wantFullscreen - The desired fullscreen state. If undefined, the function will return without making any changes.
 */
export const changeFullscreen = (wantFullscreen: boolean | undefined) => {
  if ((isIOS && isMobileOnly) || isDesktop) {
    console.info('ios mobile or desktop, not changing fullscreen');
    return;
  }
  if (wantFullscreen === undefined) {
    return;
  }

  const isFullscreen = checkIfFullscreen();

  try {
    if (wantFullscreen && !isFullscreen) {
      document.documentElement.requestFullscreen({ navigationUI: 'hide' });
      setViewport();
      return true;
    } else if (!wantFullscreen && isFullscreen) {
      document.exitFullscreen();
      setViewport();
      return true;
    }
  } catch (error) {
    if (error instanceof DOMException && error.name === 'NotAllowedError') {
      console.info('Fullscreen request was denied. This may be due to lack of user interaction.');
      return;
    } else {
      console.error('Fullscreen error:', error);
      return;
    }
  }
  return false;
};

export const durationFormat = (duration: number): string => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  if (hours > 100) {
    return `${hours.toFixed(0)}h`;
  } else if (hours > 10) {
    return `${hours.toFixed(1)}h`;
  } else if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else {
    return `${minutes}m`;
  }
};

const updateMinutesReadInBook = async (oldBook: BookMeta) => {
  const updates = oldBook.settings.updates || [];
  const times = await getIntervalCounts(updates);
  const book = getBooks()[oldBook.id];
  book.minutesRead = times;
  book.lastUpdated = Date.now();
  console.debug('minutesPerDay: ', times, 'for ', book.title);
  updateBook(book);
  return book;
};

/**
 * Retrieves the total number of minutes read for a specific book.
 *
 * @param {string} id - The unique identifier of the book.
 * @returns {Promise<number>} A promise that resolves to the total number of minutes read.
 */
export const getMinutesReadInBook = async (id: string): Promise<number> => {
  // Retrieve the book from the local storage
  let book = getBooks()[id];

  // If the book doesn't exist, return 0 minutes
  if (!book) {
    return 0;
  }

  // Check if the book's reading time needs to be updated
  // This happens if the book has never been updated or if it has been more than a minute since the last update
  if (!book.lastUpdated || (book.timestamp && book.timestamp > book.lastUpdated + 60 * 1000)) {
    // Update the book's reading time
    console.debug('updating reading minutes for ', book.title, 'id = ', book.id);
    book = await updateMinutesReadInBook(book);
  }

  // Calculate the total minutes read
  // If minutesRead is undefined, use an empty object as fallback
  const total = Object.values(book.minutesRead || {}).reduce((acc, minutes) => acc + minutes, 0);

  // Return the total minutes read
  return total;
};

// const getLongestChapterHtml = async (meta: BookMeta, book?: Book) => {
//   const { sections } = (await getChapters(meta, book)) || {};
//   if (!sections) {
//     return '';
//   }

//   const longestChapter = sections.reduce(
//     (max, section) => (section.html.length > max.html.length ? section : max),
//     sections[0]
//   );
//   return longestChapter.html;
// };

export const findLongestChapterHref = async (book: Book): Promise<string> => {
  try {
    await book.ready;
    const chapters = book.packaging.spine as any[];
    let longestChapter = { href: '', length: 0 };

    for (const entry of chapters) {
      const section: any = await book.load(entry.href);
      if (section) {
        const chapterText = section.body.textContent || '';
        if (chapterText.length > longestChapter.length) {
          longestChapter = { href: entry.href, length: chapterText.length };
        }
      }
    }

    return longestChapter.href || (chapters[0] && chapters[0].href) || '';
  } catch (error) {
    console.error('Error finding first significant chapter:', error);
    return '';
  }
};

/**
 * Extracts all CSS content from the ePub book.
 *
 * @param {Book} book - The Epub.js Book instance.
 * @returns {Promise<string[]>} - An array of CSS strings.
 */
const getAllCss = async (book: Book): Promise<string[]> => {
  await book.ready;
  await book.loaded.resources;
  const replacements = await book.resources.replacements();

  const resources = book.resources;
  const cssIndices = resources.cssUrls.map((a: string) => resources.urls.findIndex((b: string) => a === b));
  const cssUrls = cssIndices.map((i: number) => replacements[i]);
  const cssPromises = cssUrls.map(async (href: string) => {
    try {
      const response = await fetch(href);
      if (response.ok) {
        return await response.text();
      } else {
        console.error(`Failed to fetch CSS at ${href}: ${response.status}`);
        return '';
      }
    } catch (error) {
      console.error(`Error fetching CSS at ${href}:`, error);
      return '';
    }
  });

  const cssContents = await Promise.all(cssPromises);
  return cssContents.filter((css: string) => css.trim() !== '');
};

/**
 * Retrieves the cached CSS for a given BookMeta.
 * If not available in cache, extracts CSS using getAllCss and caches the result.
 *
 * @param {string} id - The id of the book.
 * @param {Book} withBook - An optional Book instance to use for extracting CSS.
 * @returns {Promise<string[]>} - An array of CSS strings.
 */
export const getCachedCss = async (id: string, withBook?: Book): Promise<string[]> => {
  const cacheKey = `css-${id}`;

  // Attempt to retrieve CSS from cache
  let cachedCss = await cssStore.getItem<string[]>(cacheKey);
  if (cachedCss) {
    console.debug(`CSS loaded from cache for book ID: ${id}`);
    return cachedCss;
  }

  // If not cached, extract CSS from the book
  const book = withBook || (await loadBook(id));
  if (!book) {
    console.error(`Book not found for ID: ${id}`);
    return [];
  }

  const cssContents = await getAllCss(book);

  // Cache the extracted CSS
  await cssStore.setItem<string[]>(cacheKey, cssContents);
  console.info(`CSS extracted and cached for book ID: ${id}`);

  return cssContents;
};

/**
 * Calculates the mode font size of the most common text selector in a book section.
 *
 * @param {string} html - The HTML content of the section to calculate the scale factor for.
 * @param {string} css - Optional CSS to apply to the container.
 * @returns {number} - The mode font size in pixels.
 */
export const calculatePrimaryFontSize = (html: string, css = ''): number => {
  try {
    // Create a detached DOM container
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.visibility = 'hidden';
    container.style.display = 'block';
    container.innerHTML = html.replace('</head>', `<style>${css}</style></head>`);

    // Append to the document to ensure styles are applied
    document.body.appendChild(container);

    // Retrieve all text selectors
    const textSelectors = getAllTextSelectors(container);
    const mostCommonSelector = findMostCommonTextSelector(container, textSelectors);

    // Select all elements matching the most common selector
    const elements = container.querySelectorAll(mostCommonSelector);
    const fontSizes: number[] = [];

    elements.forEach((el: Element) => {
      const fontSize = parseFloat(window.getComputedStyle(el).fontSize);
      if (!isNaN(fontSize)) {
        fontSizes.push(fontSize);
      }
    });

    // Clean up the container
    document.body.removeChild(container);

    if (fontSizes.length === 0) {
      console.warn('No valid font sizes found. Defaulting to 1.');
      return defaultFontSize;
    }

    // Calculate the mode of the font sizes
    const fontSizeFrequency: Record<number, number> = {};
    fontSizes.forEach((size) => {
      const roundedSize = Math.round(size * 100) / 100; // Round to nearest 0.01 for frequency counting
      fontSizeFrequency[roundedSize] = (fontSizeFrequency[roundedSize] || 0) + 1;
    });

    let modeFontSize = fontSizes[0];
    let maxFrequency = 1;

    for (const [size, frequency] of Object.entries(fontSizeFrequency)) {
      const numericSize = parseFloat(size);
      if (frequency > maxFrequency) {
        maxFrequency = frequency;
        modeFontSize = numericSize;
      }
    }

    return modeFontSize;
  } catch (error) {
    console.error('Error calculating section scale factor:', error);
    return defaultFontSize;
  }
};

export const getAllTextSelectors = (
  doc: Document | DocumentFragment | HTMLElement,
  ignoreClass = 'epubjs-ignore'
): string[] => {
  const elements = doc.querySelectorAll('p, h1, h2, h3, h4, h5, h6, span, div, a, ol, ul, li');
  const selectorSet = new Set<string>();

  Array.from(elements).forEach((el) => {
    const tagName = el.tagName.toLowerCase();
    if (el.className && (!ignoreClass || !el.classList.contains(ignoreClass))) {
      selectorSet.add(
        `${tagName}${el.className
          .split(' ')
          .filter(Boolean)
          .sort()
          .map((c) => `.${c}`)
          .join('')}`
      );
    } else {
      selectorSet.add(tagName);
    }
  });
  return Array.from(selectorSet);
};

export const findMostCommonTextSelector = (
  doc: Document | DocumentFragment | HTMLElement,
  selectorList?: string[],
  ignoreClass?: string
): string => {
  let selectors = selectorList || getAllTextSelectors(doc, ignoreClass);
  let maxCount = 0;
  let mostCommonSelector = 'p';

  selectors.forEach((selector) => {
    if (selector.startsWith('p') || selector.startsWith('div')) {
      const count = doc.querySelectorAll(selector).length;
      if (count > maxCount) {
        maxCount = count;
        mostCommonSelector = selector;
      } else if (count === maxCount) {
        if (!mostCommonSelector.startsWith('p')) {
          mostCommonSelector = selector;
        } else if (selector.length > mostCommonSelector.length) {
          mostCommonSelector = selector;
        }
      }
    }
  });

  return `div.epubjs-ignore ${mostCommonSelector}`;
  //   return mostCommonSelector;
};

export const getScaleFactor = (element: Element, desiredSize: number): number => {
  const elementComputedStyle = window.getComputedStyle(element);
  const elementComputedSize = parseFloat(elementComputedStyle.fontSize);
  const scaleFactor = desiredSize / elementComputedSize;

  return scaleFactor;
};

/**
 * Finds the nth-highest non-unique percentage value from a list of update records.
 *
 * @param updates - An array of UpdateRecord objects containing timestamp and percentage information.
 * @returns The nth-highest non-unique percentage value, or undefined if there are fewer than n unique values.
 */
export const getNthHighestPercentage = (updates: UpdateRecord[], n: number = 3): number | undefined => {
  if (updates.length < n) {
    return undefined;
  }

  // Sort the updates by percentage in descending order
  const sortedPercentages = updates.map(([_, percentage]) => percentage).sort((a, b) => b - a);

  // Find the nth-highest non-unique value
  let count = 0;
  let lastValue = Infinity;

  for (const percentage of sortedPercentages) {
    if (percentage !== lastValue) {
      count++;
      if (count === n) {
        return percentage;
      }
      lastValue = percentage;
    }
  }

  // If there are fewer than n unique values, return undefined
  return undefined;
};

export const calculateEngagement = async (book: BookMeta): Promise<number> => {
  if (book.wordCount && book.settings.updates && book.settings.updates.length > 5 && book.settings.progress) {
    // const current = guessCurrent(book.settings.updates);
    // const current = book.settings.progress;
    const current = getNthHighestPercentage(book.settings.updates, 3);
    if (!current) {
      return 0;
    }
    const wordsRead = book.wordCount * current;
    const wpm = await getTotalWPM(book, true);
    if (!wpm) {
      return 0;
    }
    const minutesRead = await getMinutesReadInBook(book.id);
    const minutesProjected = wpm && book.wordCount && wordsRead / wpm;
    return (minutesRead && minutesProjected && minutesProjected / minutesRead) || 0;
  }
  return 0;
};

const getIntervalCounts = async (updates: UpdateRecord[] | undefined): Promise<Record<number, number>> => {
  if (!updates) {
    return {};
  }

  const minutesPerDay: Record<number, number> = {};

  updates
    .sort((a, b) => a[0] - b[0])
    .forEach(([timestamp, _], i, arr) => {
      if (i === 0) return; // Skip the first element

      // TODO: proper local time
      const offset = 1000 * 60 * 60 * 4;
      const prevTimestamp = arr[i - 1][0];
      const day = Math.floor((timestamp - offset) / (24 * 60 * 60 * 1000));
      const prevDay = Math.floor((prevTimestamp - offset) / (24 * 60 * 60 * 1000));

      if (day !== prevDay) {
        // If the day has changed, ensure the previous day is counted
        minutesPerDay[prevDay] =
          (minutesPerDay[prevDay] || 0) +
          countMinutesInInterval(arr, prevDay * 24 * 60 * 60 * 1000 + offset, day * 24 * 60 * 60 * 1000 + offset);
      }

      // Count the current day

      const lastMidnight = Math.floor((Date.now() - offset) / (24 * 60 * 60 * 1000)) * 1000 * 60 * 60 * 24 + offset;
      minutesPerDay[Math.floor((Date.now() - offset) / (24 * 60 * 60 * 1000))] = countMinutesInInterval(
        arr,
        lastMidnight,
        lastMidnight + 24 * 60 * 60 * 1000
      );
    });

  return minutesPerDay;
};

const countMinutesInInterval = (updates: UpdateRecord[] | undefined, start: number = 0, end: number = Infinity) => {
  if (!updates) {
    return 0;
  }
  const updatesInInterval = updates
    ?.filter(([timestamp, _]) => timestamp >= start && timestamp <= end)
    .sort((a, b) => a[0] - b[0]);
  // get list of differences between consecutive updates in both time and prop
  if (updatesInInterval.length < 2) {
    return 0;
  }
  let diffs = updatesInInterval.slice(1).map((current, i) => {
    const prev = updatesInInterval[i];
    return [current[0] - prev[0], current[1] - prev[1]] as UpdateRecord;
  });
  diffs = diffs.filter(([timeDiff, progDiff]) => timeDiff < 300000 && timeDiff > 0 && Math.abs(progDiff) < 1);
  const totalTime = diffs.reduce((acc, [timeDiff, _]) => acc + timeDiff, 0);
  return Math.ceil(totalTime / (60 * 1000));
};

const getStartEndOfDay = (daysAgo: number) => {
  const startOfDay = dayjs.utc().local().subtract(daysAgo, 'day').startOf('day').valueOf();
  const endOfDay = dayjs.utc().local().subtract(daysAgo, 'day').endOf('day').valueOf();
  return { startOfDay, endOfDay };
};
const getDayAbbreviation = (date: dayjs.Dayjs) => {
  const day = date.format('dd'); // 'dd' returns the two-letter day abbreviation
  return day.charAt(0).toUpperCase();
};

export const aggregateWeeklyTime = async (days: number = 7, label: 'dayOfWeek' | 'dayOfMonth' = 'dayOfWeek') => {
  //   const books = { ...getArchivedBooks(), ...getBooks() };
  const books = getBooks();

  const totalTimePerDay = Array.from({ length: days }, (_, i) => {
    const date = dayjs().subtract(i, 'day');
    const { startOfDay, endOfDay } = getStartEndOfDay(i);
    const totalTimeForDay = Object.values(books).reduce((acc, book) => {
      return acc + countMinutesInInterval(book.settings.updates || [], startOfDay, endOfDay);
    }, 0);
    let day = '';
    if (label === 'dayOfWeek') {
      day = getDayAbbreviation(date);
    } else {
      day = date.format('D');
    }
    return { label: day, value: totalTimeForDay };
  }).reverse();
  return totalTimePerDay;
};

export const replaceBookFile = async (id: string, file: File) => {
  console.log('replacing file for book with id: ', id, file);
  return new Promise((resolve, reject) => {
    const oldBook = getBooks()[id];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const arrayBuffer = event.target?.result;
      if (arrayBuffer && typeof arrayBuffer !== 'string') {
        const hash = await calculateFileHashFromArrayBuffer(arrayBuffer);
        try {
          const book = Epub(arrayBuffer);
          const metadata = await book.loaded.metadata;
          const title = metadata.title;
          const author = metadata.creator;
          let coverUrl = '';
          try {
            coverUrl = (await book.coverUrl()) ?? '';
          } catch (coverError) {
            console.error('Error extracting cover:', coverError);
          }
          const coverResponse = await fetch(coverUrl);
          const coverBlob = await coverResponse.blob();

          const saveFileResult = saveFile(id, file);
          const saveFileCoverResult = saveFile(`cover-${id}`, coverBlob);
          const deleteResult = deleteFile(`locations-${id}`, 'ePubObjects');
          console.debug('delete locations result: ', deleteResult);
          const [words, chapterWords] = await getTotalWordCount(book);
          console.log('total word count:', words);
          const newBook = {
            ...oldBook,
            title: title ?? file.name.replace(/\.[^/.]+$/, ''),
            hash: hash,
            timestamp: Date.now(),
            wordCount: words,
            languageCode: metadata.language,
            chapterWordCount: chapterWords,
            author: author,
            pubDate: metadata.pubdate,
            description: metadata.description,
          };
          const bookData = (await getBookData(newBook)) as BookMeta;
          console.debug('addBookFromFile, bookData = ', bookData, ' newBook = ', newBook);

          const mergedBook = mergeBookMeta(mergeBookMeta(defaultBookMeta, bookData), newBook);
          console.info('mergedBook = ', mergedBook);
          const updateBookResult = updateBook(mergedBook);
          await Promise.all([saveFileResult, saveFileCoverResult, updateBookResult]);
          resolve(title);
        } catch (error) {
          console.log('Error adding book:', error);
          resolve(''); // Resolve with an empty string on error to indicate failure
        }
      } else {
        resolve(''); // Resolve with an empty string if arrayBuffer is not valid
      }
    };

    reader.onerror = (error) => {
      console.log('Error reading file:', error);
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const createThumbnail = async (file: File | Blob): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target?.result as string;
    };

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (ctx) {
        canvas.width = 320;
        canvas.height = 480;
        ctx.drawImage(img, 0, 0, 320, 480);
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Thumbnail creation failed'));
          }
        }, 'image/jpeg');
      } else {
        reject(new Error('Canvas context not available'));
      }
    };

    img.onerror = (err) => {
      reject(err);
    };

    reader.readAsDataURL(file);
  });
};

/**
 * Deletes a book and updates any related translations.
 *
 * @param id - The ID of the book to delete
 * @returns Promise resolving to the updated BookCollection
 *
 * @description
 * This function:
 * 1. Deletes the book's files (main file, cover, locations)
 * 2. Updates the book's status to 'trash'
 * 3. Removes this book from the translations list of any related translated versions
 * 4. Returns the updated book collection
 */
export const deleteBook = async (id: string): Promise<BookCollection> => {
  const books = getBooks();
  if (id && books[id]) {
    console.log('deleting book with id: ', id);
    const bookToDelete = books[id];

    // Delete associated files
    if (bookToDelete.id) {
      deleteFile(bookToDelete.id);
      deleteFile(`cover-${bookToDelete.id}`);
      deleteFile(`locations-${bookToDelete.id}`, 'ePubObjects');
    }

    // Update translations for related books
    if (bookToDelete.translations) {
      // Get all translation IDs except the one being deleted
      const translationIds = Object.values(bookToDelete.translations).filter((tid) => tid !== id);

      // Update each related translation
      console.log('translationIds = ', translationIds);
      console.log('books = ', books);
      for (const translationId of translationIds) {
        console.log('translationId = ', translationId);
        if (books[translationId]) {
          const translatedBook = books[translationId];
          console.log('translatedBook = ', translatedBook);
          if (translatedBook.translations) {
            console.log('translatedBook.translations = ', translatedBook.translations);
            // Create new translations object filtering out the deleted book
            const updatedTranslations = Object.fromEntries(
              Object.entries(translatedBook.translations).filter(([_, bookId]) => bookId !== id)
            );
            console.log('updatedTranslations = ', updatedTranslations);
            console.log('translatedBook = ', translatedBook);

            // Update the translated book with modified translations list
            await updateBook(
              {
                ...translatedBook,
                translations: updatedTranslations,
              },
              true
            );

            console.log('updated translations for book with id:', translationId, 'to:', updatedTranslations);
          }
        }
      }
    }

    // Mark the book as deleted
    const bookDeleted = {
      ...bookToDelete,
      location: 'trash',
      archived: undefined,
      translations: undefined, // Remove translations list from deleted book
    };

    await updateBook(bookDeleted, true);
    pruneTrashEntries();
    console.log('deleted book with id: ', id, ' bookDeleted = ', bookDeleted);
    return getBooks();
    // console.log('books = ', books);
    // return { ...books, [id]: bookDeleted };
  }
  return books;
};

/**
 * Resets the book with the given ID by overwriting its data with default values.
 *
 * @param {string} id - The unique identifier of the book to reset.
 * @returns {Promise<BookMeta | null>} - A promise that resolves when the reset is complete.
 *
 * @description
 * This function resets the specified book's data to default values,
 * effectively removing any customizations or notes. It updates both
 * the local cache and the server to ensure consistency.
 */
export const resetBook = async (id: string): Promise<BookMeta | null> => {
  try {
    const books = getBooks();

    const existingBook = books[id];
    if (!existingBook) {
      console.warn(`resetBook: Book with id ${id} does not exist.`);
      return null;
    }

    const existingBookFile = (await loadFile(existingBook.id)) as File;
    if (!existingBookFile) {
      console.warn(`resetBook: Could not load book file with id ${id}.`);
      return null;
    }

    const oldBook = getBook(id);
    if (!oldBook) {
      console.warn(`resetBook: Failed to get book with id ${id}.`);
      return null;
    }
    const newId = await addBookFromFile(existingBookFile, true);
    if (!newId) {
      console.warn(`resetBook: Failed re-adding book file with id ${id}.`);
      return null;
    }

    const newBook = getBook(newId);
    if (!newBook) {
      console.warn(`resetBook: Failed to get book with id ${newId}.`);
      return null;
    }

    if (oldBook.id !== newBook.id) {
      await updateBook({ ...oldBook, location: 'trash' });
      pruneTrashEntries();
    }

    console.log(`resetBook: Finished resetting local data for book with id ${id}.`);

    // Push the reset data to the server
    const user = getCurrentUser();
    if (user) {
      const response = await fetch('/api/reset_book', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(oldBook),
      });

      if (response.ok) {
        console.log(`resetBook: Successfully reset book with id ${id} on server.`);
        return newBook;
      } else {
        console.error(`resetBook: Failed to reset book with id ${id} on server. Status: ${response.status}`);
        return null;
      }
    } else {
      console.warn('resetBook: No user found. Skipping server update.');
      return newBook;
    }
  } catch (error) {
    console.error(`resetBook: Error resetting book with id ${id}:`, error);
    return null;
  }
};

/**
 * Extracts the base CFI from a given CFI string.
 * @param cfi - The CFI string to extract the base from.
 * @returns The base CFI string, or an empty string if not found.
 */
export const extractCfiBase = (cfi?: string) => {
  const match = cfi?.match(/(?:epubcfi\()?\/(\d+\/\d+)(?:!|$)/);
  return match ? `/${match[1]}` : '';
};

/**
 * Adds a book from a given file.
 * Associates the book with the current user.
 * @param {File} file - The EPUB file to add.
 * @param {boolean} isReset - Whether the book is being reset.
 * @param {string} useId - The ID to use for the book, or a random UUID if not provided.
 * @returns {Promise<string>} - The title of the added book, or an empty string if failed.
 */

export const addBookFromFile = async (
  file: File,
  isReset: boolean = false,
  useId: string = '',
  parentId?: string
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const arrayBuffer = event.target?.result;
      if (arrayBuffer && typeof arrayBuffer !== 'string') {
        const hash = await calculateFileHashFromArrayBuffer(arrayBuffer);
        try {
          const useParentId = parentId || getPrefs()?.activeGroupId || 'root';
          const book = Epub(arrayBuffer);
          const metadata = await book.loaded.metadata;
          const title = metadata.title || file.name.replace(/\.[^/.]+$/, '');
          const author = metadata.creator || 'Unknown';
          let coverUrl = '';
          try {
            coverUrl = (await book.coverUrl()) ?? '';
          } catch (coverError) {
            console.error('Error extracting cover:', coverError);
          }

          // Use deterministic UUID based on current user email, and book author and title.
          const currentUser = getCurrentUser();
          const seed = `${currentUser?.email}-${author}-${title}`;
          const id =
            useId ||
            (currentUser?.email && metadata.title && metadata.creator && createDeterministicUUIDv4(seed)) ||
            crypto.randomUUID();

          const coverResponse = await fetch(coverUrl);
          const coverBlob = await coverResponse.blob();
          let thumbBlob: Blob | null = null;
          try {
            thumbBlob = await createThumbnail(coverBlob);
          } catch (error) {
            console.error('Error creating thumbnail:', error);
            thumbBlob = coverBlob;
          }
          //   await saveFile(`thumbnail-${file.name}`, coverBlob);

          const [words, chapterWords] = await getTotalWordCount(book);
          console.log('total word count:', words);
          const favorite = getPrefs()?.favorite;
          const newBook: BookMeta = {
            id,
            title,
            parentId: useParentId,
            settings: { ...defaultBookSettings, ...favorite },
            createdAt: Date.now(),
            notes: {},
            hash: hash,
            timestamp: Date.now(),
            wordCount: words,
            languageCode: metadata.language,
            chapterWordCount: chapterWords,
            visibleAt: {},
            author: author,
            publishedDate: metadata.pubdate,
            description: metadata.description,
          };
          const bookData = (await getBookData(newBook)) as BookMeta;
          if (!isReset) {
            mergeBookMeta(newBook, bookData);
            console.log('addBookFromFile, bookData = ', bookData, ' newBook = ', newBook);
          } else {
            newBook.notes = bookData.notes || {};
            console.log('resetBook, newBook = ', newBook);
          }
          const mergedBook = {
            ...mergeBookMeta(defaultBookMeta, newBook),
            location: getPrefs()?.activeGroupId || '',
            timestamp: Date.now(),
            archived: undefined,
          };
          const updateBookResult = updateBook(mergedBook, true);
          const saveFileResult = saveFile(mergedBook.id, file);
          const saveFileCoverResult = saveFile(`cover-${mergedBook.id}`, thumbBlob);
          await Promise.all([saveFileResult, saveFileCoverResult, updateBookResult]);

          resolve(id);
        } catch (error) {
          console.log('Error adding book:', error);
          resolve(''); // Resolve with an empty string on error to indicate failure
        }
      } else {
        resolve(''); // Resolve with an empty string if arrayBuffer is not valid
      }
    };

    reader.onerror = (error) => {
      console.log('Error reading file:', error);
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

/**
 * Adds a translated version of the book with the given ID.
 * @param id - The ID of the book to add the version to.
 * @param file - The file containing the version.
 * @returns The ID of the new book, or null if failed.
 */
export const addTranslatedVersion = async (id: string, file: File): Promise<string | null> => {
  console.debug('addTranslatedVersion: adding translated version with id: ', id);
  const newId = await addBookFromFile(file);
  if (newId) {
    const oldBook = getBook(id);
    const newBook = getBook(newId);
    console.debug('addTranslatedVersion: oldBook = ', oldBook, ' newBook = ', newBook);
    if (oldBook && newBook) {
      if (
        oldBook.languageCode &&
        newBook.languageCode &&
        oldBook.languageCode.toLowerCase() !== newBook.languageCode.toLowerCase()
      ) {
        const translations = {
          ...(oldBook.translations || {}),
          [oldBook.languageCode.toLowerCase().split('-')[0]]: id,
          [newBook.languageCode.toLowerCase().split('-')[0]]: newId,
        };
        await updateBook({ ...oldBook, translations: translations });
        await updateBook({ ...newBook, translations: translations });
        console.log('added translated version with id: ', newId);
        return newId;
      } else {
        console.warn('addTranslatedVersion: No language code for old or new book', id, newId);
        return null;
      }
    }
  }
  return null;
};

/**
 * Refreshes the annotations by updating them with the provided notes.
 *
 * @param {Annotations} annotations - The annotations object to update.
 * @param {NotesGroup} notes - The group of notes containing the updated annotation details.
 */
export const refreshAnnotations = async (
  annotations: Annotations,
  notes: NotesGroup,
  bookMeta: BookMeta,
  sectionStep: string | null,
  iframeDoc?: Document,
  onlyReveal?: boolean
) => {
  if (iframeDoc) {
    iframeDoc.normalize();
  }
  const encounteredRanges = new Set<string>();
  Object.values(notes)
    .filter((note) => !note.deleted && (!sectionStep || note.cfiRange.slice(8).startsWith(sectionStep)))
    .forEach((note) => {
      if (encounteredRanges.has(note.cfiRange)) {
        console.error('Duplicate cfiRange encountered: note = ', note);
      } else {
        encounteredRanges.add(note.cfiRange);
      }
      updateAnnotation(annotations, note, bookMeta, iframeDoc, onlyReveal);
    });
  console.debug('refreshAnnotations: updated ', Object.keys(notes).length, ' annotations');
};

/**
 * Adjusts the CFI range to account for epubjs-ignore elements.
 *
 * @param cfiRange - The original CFI range to adjust.
 * @param iframeDoc - The document of the iframe containing the ebook content.
 * @returns The adjusted CFI range, or the original if no adjustment was needed.
 */
export const adjustCfiRange = (cfiRange: string, iframeDoc: Document): string => {
  const cfi = new EpubCFI(cfiRange, 'epubjs-ignore');
  const range = cfi.toRange(iframeDoc, 'epubjs-ignore');
  if (!range) return cfiRange;

  const startContainer = range.startContainer;
  const endContainer = range.endContainer;

  //   if (startContainer.nodeType !== Node.TEXT_NODE || endContainer.nodeType !== Node.TEXT_NODE) {
  //     return cfiRange;
  //   }

  //   let startOffset = 0;
  //   let endOffset = 0;

  //   // Adjust start offset
  //   let node = startContainer.previousSibling;
  //   while (node) {
  //     if (node.nodeType === Node.ELEMENT_NODE && (node as Element).classList.contains('epubjs-ignore')) {
  //       startOffset += (node as Element).textContent?.length || 0;
  //     }
  //     node = node.previousSibling;
  //   }

  //   // Adjust end offset
  //   node = endContainer.previousSibling;
  //   while (node) {
  //     if (node.nodeType === Node.ELEMENT_NODE && (node as Element).classList.contains('epubjs-ignore')) {
  //       endOffset += (node as Element).textContent?.length || 0;
  //     }
  //     node = node.previousSibling;
  //   }

  // Create a new CFI with adjusted offsets
  const cfiParts = cfiRange.split(')')[0].split(',');
  const [sharedPart, startPart, endPart] = cfiParts;
  const [startNode, startCharLoc] = startPart.split(':');
  const [endNode, endCharLoc] = endPart.split(':');
  //   const adjustedStartOffset = parseInt(startCharLoc) + startOffset;
  //   const adjustedEndOffset = parseInt(endCharLoc) + endOffset;
  const adjustedStartOffset = EpubCFI.prototype.patchOffset(startContainer, parseInt(startCharLoc), 'epubjs-ignore');
  const adjustedEndOffset = EpubCFI.prototype.patchOffset(endContainer, parseInt(endCharLoc), 'epubjs-ignore');

  if (adjustedStartOffset !== parseInt(startCharLoc) || adjustedEndOffset !== parseInt(endCharLoc)) {
    return `${sharedPart},${startNode}:${adjustedStartOffset},${endNode}:${adjustedEndOffset})`;
  }

  return cfiRange;
};

/**
 * Updates an annotation in the given annotations object.
 *
 * @param {Annotations} annotations - The annotations object to update.
 * @param {Note} note - The note containing the updated annotation details.
 */
export const updateAnnotation = async (
  annotations: Annotations,
  note: Note,
  bookMeta: BookMeta | undefined,
  iframeDoc: Document | undefined,
  onlyReveal = false
) => {
  // Remove any existing annotation for this note.
  annotations.removeByData('id', note.id);
  const user = getCurrentUser();
  const noteStatus = getNoteStatus(note.id);

  // Always expose system-generated notes (e.g. from 'ai').
  //   if (noteStatus && noteStatus.hidden && note && note.owner === 'ai') {
  //     noteStatus.hidden = false;
  //   }

  // If the note is already marked as hidden, skip processing.
  //   if (noteStatus?.hidden) {
  //     logger.info('Note is already hidden, skipping update for note with id:', note.id);
  //     return;
  //   }

  // Retrieve the note visibility setting from the book metadata (default to "all").
  const noteVisibility = bookMeta?.settings.noteVisibility || 'all';

  // Check if the note has comments.
  const hasComments = note.comments && Object.keys(note.comments).length > 0;
  // For parity with previous behavior, check if any comment text includes "#show".
  const hasShowTag = Object.values(note.comments || {}).some((comment) => comment.text.toLowerCase().includes('#show'));

  // Parse the note's CFI and the user's current location.
  const userCfi = bookMeta?.settings.location || '';
  const noteCfi = EpubCFI.prototype.parse(note.cfiRange);
  let isPastCurrentPoint = false;
  try {
    // Determine if the user's location has passed the note's position.
    // (Assuming that if the note's CFI is less than or equal to the user's CFI, then the note has been passed.)
    isPastCurrentPoint = EpubCFI.prototype.compare(noteCfi, userCfi) <= 0;
  } catch (error) {
    logger.warn('Error comparing CFI ranges:', error, 'note =', note, 'userCfi =', userCfi, 'noteCfi =', noteCfi);
    const userProgress = bookMeta?.settings.progress || 0;
    if (userProgress && note.progress && note.progress > userProgress) {
      isPastCurrentPoint = true;
    } else {
      logger.error('Error comparing note progress as fallback:', error);
      isPastCurrentPoint = false;
    }
  }

  // Determine whether the note should be hidden based on the note visibility setting.
  let shouldHide = false;
  switch (noteVisibility) {
    case 'none':
      // Never show any highlights.
      shouldHide = true;
      break;
    case 'onlyMine':
      // Only show notes created by the current user.
      if (!user || note.owner !== user.email) {
        shouldHide = true;
      }
      break;
    case 'commentsUntilPassed':
      // For comments-only delayed reveal: require that the note has comments and that the user has passed the note.
      if ((!hasComments && !hasShowTag) || !isPastCurrentPoint) {
        shouldHide = true;
      }
      break;
    case 'comments':
      // Reveal notes with comments immediately; hide if no comments.
      if (!hasComments && !hasShowTag) {
        shouldHide = true;
      }
      break;
    case 'allUntilPassed':
      // For all-notes delayed reveal: only reveal when the user's location has passed.
      if (!isPastCurrentPoint) {
        shouldHide = true;
      }
      break;
    case 'all':
      // Reveal all notes immediately.
      shouldHide = false;
      break;
    default:
      // Fallback: reveal the note.
      shouldHide = false;
      break;
  }

  if (shouldHide && !onlyReveal && !note.read && !noteStatus?.hidden) {
    logger.info('Hiding note with id:', note.id);
    setNoteStatus(note.id, { read: false, hidden: true });
    return;
  }

  // Adjust the CFI range for terminal character ranges if necessary.
  let adjustedCfiRange = note.cfiRange;
  if (iframeDoc) {
    adjustedCfiRange = adjustCfiRange(note.cfiRange, iframeDoc);
    if (note.cfiRange !== adjustedCfiRange) {
      console.debug('adjustedCfiRange from', note.cfiRange, 'to', adjustedCfiRange);
    }
  }

  // Add the annotation.
  annotations.add(
    'highlight',
    adjustedCfiRange,
    note,
    undefined,
    countComments(note) > 0 ? (getNoteStatus(note.id)?.read ? 'wr-hlc' : 'wr-hlcu') : 'wr-hl',
    {
      stroke: note.color,
      fill: note.color,
      'fill-opacity': '0.3',
    }
  );

  applyReactions(note, bookMeta?.settings.fontSize);
};

const createSVG = (name: string) => {
  return document.createElementNS('http://www.w3.org/2000/svg', name);
};

const applyReactions = async (note: Note, fontSize?: number) => {
  const emojiSizeRatio = 0.5;
  const emojiSize =
    !fontSize || fontSize === defaultBookSettings.fontSize ? 16 * emojiSizeRatio : fontSize * emojiSizeRatio;
  const yOffset = -0.25 * emojiSize;
  if (isEmpty(note.reactions)) {
    // Remove existing reaction container if there are no reactions
    const existingContainer = document.querySelector(`svg > g[reaction-epubcfi="${note.cfiRange}"]`);
    if (existingContainer) {
      existingContainer.remove();
    }
    return;
  }

  const svg = document.querySelector('.epub-view > svg');
  if (!svg) {
    // logger.debug('SVG element not found');
    return;
  }

  let reactionContainer = svg.querySelector(`g[reaction-epubcfi="${note.cfiRange}"]`);
  if (!reactionContainer) {
    reactionContainer = createSVG('g');
    reactionContainer.setAttribute('reaction-epubcfi', note.cfiRange);
    svg.appendChild(reactionContainer);
  } else {
    // Clear existing reactions
    reactionContainer.innerHTML = '';
  }

  // TODO: filter to only CFIs in the current section
  const highlightRects = Array.from(
    svg.querySelectorAll(`g[data-epubcfi="${note.cfiRange}"] > rect`)
  ) as SVGRectElement[];
  if (highlightRects.length === 0) {
    // logger.warn('No highlight rects found for note with id: ', note.id, 'cfiRange = ', note.cfiRange);
    return;
  }

  // Sort rectangles: bottommost (largest y + height) first, then rightmost (largest x + width)
  highlightRects.sort((a: SVGRectElement, b: SVGRectElement) => {
    const aBottom = parseFloat(a.getAttribute('y') || '0') + parseFloat(a.getAttribute('height') || '0');
    const bBottom = parseFloat(b.getAttribute('y') || '0') + parseFloat(b.getAttribute('height') || '0');
    if (aBottom !== bBottom) {
      return bBottom - aBottom; // Descending order for y + height
    }
    const aRight = parseFloat(a.getAttribute('x') || '0') + parseFloat(a.getAttribute('width') || '0');
    const bRight = parseFloat(b.getAttribute('x') || '0') + parseFloat(b.getAttribute('width') || '0');
    return bRight - aRight; // Descending order for x + width
  });

  const lastRect = highlightRects[0];
  const bottomRightX =
    parseFloat(lastRect.getAttribute('x') || '0') + parseFloat(lastRect.getAttribute('width') || '0');
  const bottomRightY =
    parseFloat(lastRect.getAttribute('y') || '0') + parseFloat(lastRect.getAttribute('height') || '0');

  reactionContainer.setAttribute('transform', `translate(${bottomRightX}, ${bottomRightY + yOffset})`);

  let xOffset = 0;
  Object.entries(note.reactions).forEach(([_user, emoji]) => {
    const reactionGroup = createSVG('g');
    reactionGroup.setAttribute('transform', `translate(${xOffset}, 0)`);

    const reactionBg = createSVG('circle');
    reactionBg.setAttribute('r', `${emojiSize / 1.5}px`);
    reactionBg.setAttribute('fill', getTheme() === 'dark' ? '#333' : '#ccc');
    reactionBg.setAttribute('fill-opacity', '1');
    // reactionBg.setAttribute('stroke', getTheme() === 'dark' ? '#888' : '#888');
    // reactionBg.setAttribute('stroke-width', '1');

    const reactionText = createSVG('text');
    reactionText.textContent = emoji;
    reactionText.setAttribute('font-size', `${emojiSize}px`);
    reactionText.setAttribute('text-anchor', 'middle');
    reactionText.setAttribute('dominant-baseline', 'central');

    reactionGroup.appendChild(reactionBg);
    reactionGroup.appendChild(reactionText);

    // if (users.length > 1) {
    //   const countText = createSVG('text');
    //   countText.textContent = users.length.toString();
    //   countText.setAttribute('font-size', '8');
    //   countText.setAttribute('text-anchor', 'middle');
    //   countText.setAttribute('dominant-baseline', 'hanging');
    //   countText.setAttribute('y', '10');
    //   reactionGroup.appendChild(countText);
    // }

    reactionContainer.appendChild(reactionGroup);
    xOffset += 18; // Adjust spacing between reactions
  });
};

/**
 * Get the text of a book up to a given section number.
 * @param book - The BookMeta to get the text of.
 * @param format - The format of the text to get (text or html).
 * @param endSectionNum - The number of the section to end at.
 * @returns The text of the book up to the given section number, or the entire book if no endSectionNum is provided.
 */
export const getBookText = async (book: BookMeta, format: 'text' | 'html', endSectionNum?: number): Promise<string> => {
  const { sections } = await getChapters(book);
  const endSection = endSectionNum || sections.length - 1;
  const text = sections
    .slice(0, endSection + 1)
    .map((section) => (format === 'text' ? section.text : section.html))
    .join('\n\n');
  //   console.debug('getBookText book=', book, 'format=', format, 'endSectionNum=', endSectionNum, 'text=', text);
  return text;
};

const mediumDelay = 250;

export const getUpdates = debounce(getUpdatesBase, mediumDelay);
export const reportOpen = debounce(reportOpenBase, mediumDelay);
export const getBookProgress = debounce(getBookProgressBase, mediumDelay);
export const getStreaks = debounce(getStreaksBase, mediumDelay);
export const fetchFollowing = debounce(fetchFollowingBase, mediumDelay);

/**
 * Organizes books based on user-specified instructions by calling the backend API
 *
 * @param {string} instructions - User's instructions for book organization (e.g., "by genre then by author")
 * @param {string[]} [bookIds] - Optional array of book IDs to organize. If not provided, all books will be organized.
 * @returns {Promise<{ [groupName: string]: any } | null>} - A hierarchical JSON structure of organized books or null if failed
 */
export const organizeBooks = async (
  instructions: string,
  bookIds?: string[],
  timeoutMs: number = 300000 // 2 minutes timeout
): Promise<{ [groupName: string]: any } | null> => {
  const isOnline = useOnlineStore.getState().isOnline;
  if (!isOnline) {
    console.error('Cannot organize books: offline');
    return null;
  }

  try {
    const payload: { instructions: string; bookIds?: string[] } = {
      instructions,
    };

    if (bookIds && bookIds.length > 0) {
      payload.bookIds = bookIds;
    }

    // Set up AbortController for timeout
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeoutMs);

    const response = await fetch('/api/organize_books', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      signal: controller.signal,
    });

    // Clear the timeout if the fetch completes before the timeout
    clearTimeout(timeoutId);

    if (!response.ok) {
      console.error('Error organizing books:', response.statusText);
      return null;
    }

    const data = await response.json();
    return data.result;
  } catch (error) {
    if (error instanceof DOMException && error.name === 'AbortError') {
      console.error(`Request timed out after ${timeoutMs / 1000} seconds`);
    } else {
      console.error('Error organizing books:', error);
    }
    return null;
  }
};
