import React, { useState } from 'react';
import { AppBar, Toolbar, Box, Stack, Menu, MenuItem } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import type { BookMeta, BookSettings, Prefs, StatusBarBoxInfo } from '../types/book';
import { EpubView } from './EpubView';
import { type NavItem } from 'epubjs';
import { checkIfFullscreen, getTheme } from '../utils/books';
import StatusBarBox from './StatusBarBox';
import { useTranslation } from '../utils/i18n';

interface TopStatusBarProps {
  onToggle: () => void;
  meta: BookMeta;
  numLocations?: number;
  getReader: () => EpubView | null;
  wpm?: number;
  settings: BookSettings;
  chapterProgress?: number;
  prefs: Prefs;
  updatePrefs: (prefs: Prefs) => void;
}

const flattenTOC = (toc: NavItem[]): NavItem[] => {
  let flat: NavItem[] = [];
  toc.forEach((item: NavItem) => {
    flat.push({ ...item, href: item.href.split('#')[0] });
    if (item.subitems) {
      flat = flat.concat(flattenTOC(item.subitems));
    }
  });
  return flat;
};

const longEntries = ['chapter_name', 'book_name', 'author_name', 'epubcfi', 'book_progress_binary'];
const shortEntries = [
  'wpm',
  'chapter_progress',
  'chapter_time',
  'book_progress',
  'book_progress_quaternary',
  'book_location',
  'book_time',
  'book_elapsed',
  'none',
];

/**
 * Top status bar component.
 */
const TopStatusBar: React.FC<TopStatusBarProps> = ({
  onToggle,
  meta,
  getReader,
  wpm,
  settings,
  chapterProgress,
  prefs,
  updatePrefs,
  numLocations,
}) => {
  const t = useTranslation();
  const reader = getReader();
  const loc = reader?.rendition?.location;
  const page = loc?.start.displayed.page;
  const total = loc?.start.displayed.total;
  const flatToc = flattenTOC(reader?.state.toc || []);
  const wordsRemaining =
    (chapterProgress !== undefined &&
      loc?.start.index &&
      meta.chapterWordCount &&
      meta.chapterWordCount.length > loc.start.index &&
      (1 - chapterProgress) * meta.chapterWordCount[loc.start.index]) ||
    undefined;
  const minutesRemaining =
    (chapterProgress !== undefined && wordsRemaining !== undefined && wpm && wordsRemaining / wpm) || undefined;
  const wordsRemainingBook =
    (settings.progress !== undefined && meta.wordCount && (1 - settings.progress) * meta.wordCount) || undefined;
  const minutesRemainingBook =
    (settings.progress !== undefined && wordsRemainingBook !== undefined && wpm && wordsRemainingBook / wpm) ||
    undefined;
  const minutesElapsedBook =
    (settings.progress !== undefined &&
      wordsRemainingBook !== undefined &&
      wpm &&
      meta.wordCount &&
      (meta.wordCount - wordsRemainingBook) / wpm) ||
    undefined;
  const base10SigFigs = numLocations ? Math.max(0, Math.floor(Math.log10(numLocations) - 2)) : 0;
  const base2SigFigs = numLocations ? Math.max(0, Math.floor(Math.log2(numLocations))) : 0;
  const base4SigFigs = numLocations ? Math.max(0, Math.floor(Math.log(numLocations) / Math.log(4))) : 0;

  /**
   * Formats minutes into a human-readable string.
   * @param minutes - The number of minutes.
   * @param alt - Use colon format (e.g. 1:05) if true.
   * @returns The formatted string.
   */
  const formatMinutes = (minutes: number, alt = false) => {
    if (alt) {
      return `${Math.floor(minutes / 60)}:${Math.floor(minutes % 60)
        .toString()
        .padStart(2, '0')}`;
    } else if (minutes >= 60) {
      return `${Math.floor(minutes / 60)}h ${Math.floor(minutes % 60)}m`;
    } else {
      return `${Math.round(minutes)}m`;
    }
  };

  /**
   * Returns the content for a given box info key, using translations for fallback texts.
   * @param info - The info key.
   * @returns The display string.
   */
  const getBoxInfo = (info: string): string => {
    if (info === 'wpm') {
      return (wpm && `${Math.round(wpm)}w`) || t('FALLBACK_WPM');
    } else if (info === 'chapter_progress') {
      return settings.flow === 'paginated'
        ? `${page}/${total}`
        : chapterProgress === undefined
        ? t('FALLBACK_CHAPTER_PROGRESS')
        : `${(chapterProgress * 100).toFixed(0)}%`;
    } else if (info === 'chapter_time') {
      return (minutesRemaining !== undefined && formatMinutes(minutesRemaining)) || t('FALLBACK_CHAPTER_TIME');
    } else if (info === 'book_time') {
      return (minutesRemainingBook !== undefined && formatMinutes(minutesRemainingBook)) || t('FALLBACK_BOOK_TIME');
    } else if (info === 'book_elapsed') {
      return (
        (minutesElapsedBook !== undefined && formatMinutes(minutesElapsedBook, true)) || t('FALLBACK_BOOK_ELAPSED')
      );
    } else if (info === 'book_progress_binary') {
      const progress = meta.settings.progress ?? 0;
      const binaryStr = progress.toString(2).split('.');
      const fracPart = binaryStr[1] || '';
      const paddedFrac = fracPart.padEnd(base2SigFigs, '0').slice(0, base2SigFigs);
      const formattedFrac = paddedFrac.match(/.{1,4}/g)?.join(' ') || '';
      return `.${formattedFrac}₂`;
    } else if (info === 'book_progress_quaternary') {
      const progress = meta.settings.progress ?? 0;
      const base4Str = progress.toString(4).split('.');
      const fracPart = base4Str[1] || '';
      const paddedFrac = fracPart.padEnd(base4SigFigs, '0').slice(0, base4SigFigs);
      const formattedFrac = paddedFrac.match(/.{1,3}/g)?.join(' ') || '';
      return `.${formattedFrac}₄`;
    } else if (info === 'book_progress') {
      return `${((meta.settings.progress ?? 0) * 100.0).toFixed(
        base10SigFigs !== undefined && base10SigFigs > 0 ? Math.ceil(base10SigFigs) : 0
      )}%`;
    } else if (info === 'book_name') {
      return meta.title || t('FALLBACK_BOOK_NAME');
    } else if (info === 'book_location') {
      return `${getReader()?.rendition?.location?.start.location}`;
    } else if (info === 'author_name') {
      return meta.author || t('FALLBACK_AUTHOR_NAME');
    } else if (info === 'epubcfi') {
      return meta.settings.location || t('FALLBACK_EPUBCFI');
    } else if (info === 'chapter_name') {
      return chapter || t('FALLBACK_CHAPTER_NAME');
    } else if (info === 'chapter_none') {
      return t('FALLBACK_CHAPTER_NONE');
    } else if (info.includes('thus')) {
      return t('FALLBACK_THUS');
    } else if (info.includes('diamond')) {
      return t('FALLBACK_DIAMOND');
    } else {
      return '';
    }
  };

  const chapter =
    loc?.start.href &&
    flatToc.find((i) => loc.start.href.endsWith(i.href) || i.href.endsWith(loc.start.href))?.label.trim();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedBox, setSelectedBox] = useState<StatusBarBoxInfo | null>(null);

  const handleStatusBarBoxHold = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
    box: StatusBarBoxInfo
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedBox(box);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedBox(null);
  };

  const handleMenuItemClick = (newInfo: string) => {
    if (selectedBox) {
      const isCurrentlyLong = longEntries.includes(selectedBox.info);
      const isNewLong = longEntries.includes(newInfo);
      const isNewShort = shortEntries.includes(newInfo);

      let updatedBoxes = (prefs.statusBarBoxes || []).filter((b) => b.position * selectedBox.position < 0);

      if (isNewLong) {
        // If changing to a long entry, remove any entries with position ±2
        // updatedBoxes = updatedBoxes.filter((b) => b.position !== (selectedBox.position < 0 ? -2 : 2));

        // Ensure the long entry takes position ±1
        const newPosition = selectedBox.position < 0 ? -1 : 1;
        updatedBoxes.push({
          position: newPosition,
          info: newInfo,
        });
      } else if (isNewShort) {
        // Add the selected new info
        updatedBoxes.push({
          position: selectedBox.position,
          info: newInfo,
        });

        // If changing from long to short, add a "none" entry if needed
        if (isCurrentlyLong) {
          const otherPosition = selectedBox.position < 0 ? selectedBox.position + 1 : selectedBox.position - 1;
          const existingEntry = updatedBoxes.find((b) => b.position === otherPosition);
          updatedBoxes.push({
            position: otherPosition,
            info: existingEntry ? existingEntry.info : 'none',
          });
        }
      }

      updatePrefs({
        statusBarBoxes: updatedBoxes.sort((a, b) => a.position - b.position),
      });
    }
    handleMenuClose();
  };

  const allBoxOptions = [
    'chapter_name',
    'book_name',
    'author_name',
    'epubcfi',
    'wpm',
    'chapter_progress',
    'chapter_time',
    'book_progress',
    'book_progress_binary',
    'book_progress_quaternary',
    'book_location',
    'book_time',
    'book_elapsed',
    'none',
  ];

  /**
   * Returns a localized menu label for a given status box option.
   * @param option - The option string.
   * @returns The translated label.
   */
  const getMenuItemLabel = (option: string) => {
    return t(`STATUS_BAR_OPTION_${option.toUpperCase()}`);
  };

  const renderStatusBoxes = (side: 'left' | 'right') => {
    const boxes = (prefs.statusBarBoxes || [])
      .filter((box) => (side === 'left' && box.position < 0) || (side === 'right' && box.position > 0))
      .sort((a, b) => (side === 'left' ? b.position - a.position : a.position - b.position));

    if (boxes.length === 0) return null;

    const isLongEntry = longEntries.includes(boxes[0].info);

    return (
      <Stack direction='row' spacing={1} fontSize={{ xs: 'small', sm: 'medium', md: 'large' }} sx={{ flex: 1 }}>
        <StatusBarBox
          key={boxes[0].position}
          content={getBoxInfo(boxes[0].info)}
          onHold={onToggle}
          onClick={(e) => handleStatusBarBoxHold(e, boxes[0])}
          boxInfo={boxes[0]}
          sx={{
            pr: side === 'right' ? 1.5 : 0,
            flex: isLongEntry ? 1 : 0.5,
            textAlign: side === 'left' ? 'left' : 'right',
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
          }}
        />
        {!isLongEntry && boxes[1] && (
          <StatusBarBox
            key={boxes[1].position}
            content={getBoxInfo(boxes[1].info)}
            onHold={onToggle}
            onClick={(e) => handleStatusBarBoxHold(e, boxes[1])}
            boxInfo={boxes[1]}
            sx={{
              pr: side === 'right' ? 1.5 : 0,
              flex: 0.5,
              textAlign: side === 'left' ? 'left' : 'right',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          />
        )}
      </Stack>
    );
  };

  return (
    <AppBar
      position='fixed'
      elevation={checkIfFullscreen() ? 0 : 4}
      sx={{
        color: '#888',
        backgroundColor: Boolean(document.fullscreenElement)
          ? 'transparent'
          : getTheme(prefs.theme) === 'dark'
          ? '#111'
          : '#eee',
        backgroundImage: 'none',
      }}
    >
      <Toolbar disableGutters variant='dense' sx={{ alignItems: 'stretch' }}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box
            sx={{
              flex: 5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <MenuIcon sx={{ mx: 1.5 }} onClick={onToggle} />
            {renderStatusBoxes('left')}
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box
            sx={{
              flex: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {renderStatusBoxes('right')}
          </Box>
        </Box>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          dense: true,
        }}
      >
        {allBoxOptions.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleMenuItemClick(option)}
            sx={{
              py: 1,
            }}
          >
            {getMenuItemLabel(option)}
          </MenuItem>
        ))}
      </Menu>
    </AppBar>
  );
};

export default TopStatusBar;
