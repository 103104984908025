import React from 'react';
import { Button } from '@mui/material';
import type { ButtonProps } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import TutorialSelector from './TutorialSelector';
import useTutorials from '../hooks/useTutorials';
import { useTranslation } from '../utils/i18n';

/**
 * Props for the HelpTutorialButton component
 * @interface HelpTutorialButtonProps
 * @extends {ButtonProps} Extends Material-UI Button props
 */
interface HelpTutorialButtonProps extends ButtonProps {
  helpText?: string;
}

/**
 * A button that opens the tutorial selector when clicked
 *
 * @param {HelpTutorialButtonProps} props - Component props
 * @returns {JSX.Element} HelpTutorialButton component
 */
const HelpTutorialButton: React.FC<HelpTutorialButtonProps> = ({ helpText, ...props }) => {
  const t = useTranslation();
  const { isSelectorOpen, tutorials, openTutorialSelector, closeTutorialSelector } = useTutorials();

  return (
    <>
      <Button
        variant='outlined'
        startIcon={<HelpIcon />}
        onClick={openTutorialSelector}
        sx={{
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'primary.light',
          },
          ...props.sx,
        }}
        {...props}
      >
        {props.children || helpText || t('HELP_BUTTON')}
      </Button>

      <TutorialSelector open={isSelectorOpen} onClose={closeTutorialSelector} tutorials={tutorials} />
    </>
  );
};

export default HelpTutorialButton;
