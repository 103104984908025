import { useState, useCallback, useEffect, useRef } from 'react';
import { IconButton, Tooltip, Stack, Divider, Dialog, Paper, Grow, Box, useTheme } from '@mui/material';
import {
  AutoStories as AutoStoriesIcon,
  AutoFixOff as AutoFixOffIcon,
  AutoFixHigh as AutoFixHighIcon,
  AutoFixNormal as AutoFixNormalIcon,
  Assistant as AssistantIcon,
  //   RecentActors as RecentActorsIcon,
  Circle as CircleIcon,
  Translate as TranslateIcon,
  Title as TitleIcon,
  ArrowDropDown as ArrowDropDownIcon,
  //   Grass as GrassIcon,
} from '@mui/icons-material';
import { DictionaryView } from './DictionaryView';
import Geepers from './Geepers';
import { WiktionaryView } from './WiktionaryView';
import { RevoDictionaryView } from './RevoDictionaryView';
import type { Book } from 'epubjs';
import type { BookMeta, GeepersCommand, NoteCommentGroup } from '../types/book';
import { useOnlineStore } from '../store/onlineStore';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useTranslation } from '../utils/i18n';

interface ContextMenuProps {
  x: number;
  y: number;
  open: boolean;
  onClose: (e: MouseEvent) => void;
  onSelectColor: (color: string, text: string, cfiRange: string) => void;
  text: string;
  context: string;
  cfiRange: string;
  book: Book;
  progress: number;
  lastColor?: string;
  meta: BookMeta;
  addNote?: (cfiRange: string, color: string, text: string, comments?: NoteCommentGroup) => void;
  getContextFunction: (selection: Selection | null) => string;
  getTranslationFunction: (start: number, end: number, lang: string) => string;
  selection: Selection | null;
  selectStartParagraphIndex: number;
  selectEndParagraphIndex: number;
  languageCode?: string;
}

/**
 * Renders a context menu with various actions including highlighting, looking up dictionary entries,
 * and triggering AI commands. In particular, the magic button supports both short tap (triggering
 * the default 'magic' command) and long press (revealing extra AI commands); when the user releases
 * while within one of the visible buttons (magic or one of the extras) that command is triggered.
 *
 * The implementation uses pointer events and global event listeners to robustly manage the drag‐and‑release
 * behavior. When dragging over any extra button, we apply a conditional style so that the button shows
 * MUI's standard "glowy" (selected) effect. Releasing off any button hides the extras.
 */
export default function ContextMenu({
  y,
  onSelectColor,
  open,
  onClose,
  text,
  context,
  cfiRange,
  lastColor,
  meta,
  addNote,
  getContextFunction,
  getTranslationFunction,
  selection,
  selectStartParagraphIndex,
  selectEndParagraphIndex,
  languageCode,
}: ContextMenuProps) {
  const theme = useTheme();
  const t = useTranslation();
  const [dictionaryOpen, setDictionaryOpen] = useState(false);
  const [geepersOpen, setGeepersOpen] = useState(false);
  const [geepersCommand, setGeepersCommand] = useState<GeepersCommand>('magic');
  const buttonSize = 28; // Reduced size for a more modern look
  const buttonMargin = 0.5;
  const [wiktionaryOpen, setWiktionaryOpen] = useState(false);
  const [revoDictionaryOpen, setRevoDictionaryOpen] = useState(false);
  const [bookLanguage, setBookLanguage] = useState(meta.languageCode || 'en');

  // --- State & refs for handling long press on the magic button ---
  const [isLongPress, setIsLongPress] = useState(false);
  const [activeHover, setActiveHover] = useState<
    null | 'magic' | 'magic_brief' | 'magic_detailed' | 'magic_comprehensive'
  >(null);

  // A ref to track if a pointer press is active
  const pointerDownRef = useRef<boolean>(false);
  // A ref to store the pointerId for capture management
  const pointerIdRef = useRef<number | null>(null);
  // A ref holding the current hovered command (available synchronously)
  const activeHoverRef = useRef<null | 'magic' | 'magic_brief' | 'magic_detailed' | 'magic_comprehensive'>(null);
  // Ref for long press timer
  const longPressTimerRef = useRef<number | null>(null);
  // Ref that always holds the current long press state
  const isLongPressRef = useRef(false);
  const longPressDuration = 300; // milliseconds
  const foldRef = useRef<HTMLDivElement>(null);

  // Refs for all buttons involved in the magic gesture
  const magicButtonRef = useRef<HTMLButtonElement>(null);
  const extraButtonRefMagicBrief = useRef<HTMLButtonElement>(null);
  const extraButtonRefMagicDetailed = useRef<HTMLButtonElement>(null);
  const extraButtonRefMagicComprehensive = useRef<HTMLButtonElement>(null);

  // Reset magic state when the context menu closes.
  useEffect(() => {
    if (!open) {
      console.log('context menu closed, resetting magic state');
      cleanupPointerListeners();
    }
  }, [open]);

  useEffect(() => {
    document.addEventListener('pointermove', handleMagicPointerMove);
    document.addEventListener('pointerup', handleMagicPointerUp); // Or pointercancel
    console.log('added pointermove and pointerup listeners');
    return () => {
      document.removeEventListener('pointermove', handleMagicPointerMove);
      document.removeEventListener('pointerup', handleMagicPointerUp);
      console.log('removed pointermove and pointerup listeners');
    };
  }, []);

  // Update book language state when meta.languageCode becomes available.
  useEffect(() => {
    if (meta.languageCode) {
      setBookLanguage(
        meta.languageCode.slice(0, 2).toLowerCase() || navigator.language.slice(0, 2).toLowerCase() || 'en'
      );
    }
  }, [meta.languageCode]);

  const isOnline = useOnlineStore((state) => state.isOnline);

  /**
   * Determines whether the pointer coordinates (x, y) are within an element's bounding rectangle.
   *
   * @param element - The target HTML element.
   * @param x - The x-coordinate of the pointer.
   * @param y - The y-coordinate of the pointer.
   * @returns True if the pointer is inside the element, false otherwise.
   */
  const isPointerInside = (element: HTMLElement, x: number, y: number): boolean => {
    const rect = element.getBoundingClientRect();
    const inside = x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
    return inside;
  };

  /**
   * Handles pointer down on the magic button. Sets pointer capture and starts the long press timer.
   */
  const handleMagicPointerDown = (event: React.PointerEvent<HTMLButtonElement>) => {
    pointerDownRef.current = true;

    // Set pointer capture so we continue receiving events if the pointer leaves the element
    magicButtonRef.current?.setPointerCapture(event.pointerId);
    pointerIdRef.current = event.pointerId;
    setActiveHover('magic');
    activeHoverRef.current = 'magic';

    // Start long press timer
    longPressTimerRef.current = window.setTimeout(() => {
      isLongPressRef.current = true;
      setIsLongPress(true);
    }, longPressDuration);
  };

  /**
   * Handles pointer move while captured by the magic button.
   */
  const handleMagicPointerMove = (event: PointerEvent) => {
    if (!pointerDownRef.current || !isLongPressRef.current) return;

    const { clientX: x, clientY: y } = event;
    let newHover: null | 'magic' | 'magic_brief' | 'magic_detailed' | 'magic_comprehensive' = null;

    if (isLongPressRef.current) {
      // Check each button's bounds
      if (extraButtonRefMagicBrief.current && isPointerInside(extraButtonRefMagicBrief.current, x, y)) {
        newHover = 'magic_brief';
      } else if (extraButtonRefMagicDetailed.current && isPointerInside(extraButtonRefMagicDetailed.current, x, y)) {
        newHover = 'magic_detailed';
      } else if (
        extraButtonRefMagicComprehensive.current &&
        isPointerInside(extraButtonRefMagicComprehensive.current, x, y)
      ) {
        newHover = 'magic_comprehensive';
      } else if (magicButtonRef.current && isPointerInside(magicButtonRef.current, x, y)) {
        newHover = 'magic';
      }
    } else if (magicButtonRef.current && isPointerInside(magicButtonRef.current, x, y)) {
      newHover = 'magic';
    }

    setActiveHover(newHover);
    activeHoverRef.current = newHover;
  };

  /**
   * Handles pointer up while captured by the magic button.
   */
  const handleMagicPointerUp = (event: PointerEvent) => {
    const cmd = activeHoverRef.current;
    const pdr = pointerDownRef.current;
    const wasLongPress = isLongPressRef.current;
    console.log('handleMagicPointerUp:', { cmd, pdr, wasLongPress });

    // Cleanup first to prevent any race conditions
    cleanupPointerListeners(magicButtonRef.current ?? undefined, event.pointerId);

    // Only trigger if we're still over the magic button (for short press)
    // or if we're over any of the command buttons (for long press)
    if (pdr && cmd) {
      // For short press, only trigger if it wasn't a long press
      if (!wasLongPress && cmd === 'magic') {
        // Small delay to ensure cleanup is complete
        setTimeout(() => {
          console.log('Triggering short press command:', cmd);
          handleGeepersOpen(cmd);
        }, 0);
      } else if (wasLongPress) {
        // For long press, trigger immediately for any valid command
        console.log('Triggering long press command:', cmd);
        handleGeepersOpen(cmd);
      }
    }
  };

  const cleanupPointerListeners = (element?: HTMLElement, pointerId?: number) => {
    pointerDownRef.current = false;
    console.log('cleanupPointerListeners:', { element, pointerId });

    // Only try to release capture if we have both the element and pointerId,
    // and the element still has capture
    // if (pointerId && element && element.hasPointerCapture(pointerId)) {
    //   try {
    //     element.releasePointerCapture(pointerId);
    //   } catch (err) {
    //     console.warn('Error releasing pointer capture:', err);
    //   }
    // } else {
    //   console.log('No capture to release or element/pointerId missing');
    // }
    pointerIdRef.current = null;

    if (longPressTimerRef.current !== null) {
      clearTimeout(longPressTimerRef.current);
      longPressTimerRef.current = null;
      console.log('cleared long press timer');
    }

    isLongPressRef.current = false;
    setIsLongPress(false);
    setActiveHover(null);
    activeHoverRef.current = null;
    console.log('reset long press state');
  };

  /**
   * Handles short tap on the highlight button.
   *
   * @param e - The click event.
   */
  const handleColorSelect = useCallback(
    (e: MouseEvent) => {
      console.log('handleColorSelect:', { color: lastColor || '#ffff00', text, cfiRange });
      onSelectColor(lastColor || '#ffff00', text, cfiRange);
      onClose(e as MouseEvent);
    },
    [text, cfiRange, lastColor]
  );

  const handleDictionaryOpen = () => {
    console.log('handleDictionaryOpen:', { bookLanguage, text });
    if (text) {
      if (bookLanguage.toLowerCase().startsWith('en')) {
        setDictionaryOpen(true);
      } else {
        console.debug('Opening Wiktionary view');
        setWiktionaryOpen(true);
      }
    }
  };

  const handleRevoDictionaryOpen = () => {
    console.log('handleRevoDictionaryOpen:', { bookLanguage, text });
    if (text) {
      console.debug('Opening RevoDictionary view');
      setRevoDictionaryOpen(true);
    }
  };

  const handleDictionaryClose = () => {
    setDictionaryOpen(false);
  };

  const handleWiktionaryClose = () => {
    setWiktionaryOpen(false);
  };

  const handleRevoDictionaryClose = () => {
    setRevoDictionaryOpen(false);
  };

  /**
   * Opens the Geepers component with the specified AI command.
   *
   * @param command - The command to execute (e.g., "magic", "magic_brief", etc.).
   */
  const handleGeepersOpen = (command: GeepersCommand = 'magic') => {
    console.log('handleGeepersOpen:', { command });
    setGeepersCommand(command);
    setGeepersOpen(true);
  };

  const handleGeepersClose = () => {
    setGeepersOpen(false);
  };

  // Common button styling
  const buttonSx = {
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      transform: 'scale(1.05)',
    },
    '&:active': {
      backgroundColor: theme.palette.action.selected,
      transform: 'scale(0.95)',
    },
  };

  // Magic button hover/active styling
  const getMagicButtonSx = (buttonType: string) => ({
    ...buttonSx,
    ...(activeHover === buttonType && {
      backgroundColor: theme.palette.action.selected,
      transform: 'scale(1.05)',
    }),
  });

  // Standard icon styling to ensure consistency
  const standardIconSx = {
    fontSize: buttonSize,
    m: buttonMargin,
    color: theme.palette.action.active, // Use action.active for standard icons
  };

  // Magic icon styling
  const magicIconSx = {
    fontSize: buttonSize,
    m: buttonMargin,
    color: theme.palette.primary.main, // Keep primary color for magic button
  };

  // Dropdown icon styling with increased visibility
  const dropdownIconSx = {
    fontSize: buttonSize,
    m: buttonMargin,
    p: 0,
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.light // Lighter shade in dark mode for better visibility
        : theme.palette.action.active,
  };

  return (
    <>
      <Dialog
        hideBackdrop
        id='context-menu'
        open={open}
        onClose={onClose}
        disableScrollLock={true}
        disableEscapeKeyDown={true}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        PaperProps={{
          elevation: 8, // Increased shadow for more depth
          style: {
            position: 'fixed', // Changed from absolute to fixed to prevent scrolling issues
            top: y,
            width: 'auto',
            height: 'auto',
            pointerEvents: 'auto',
            overflow: 'visible',
            borderRadius: 12, // Slightly rounded corners
          },
          sx: {
            background: theme.palette.background.paper,
            backdropFilter: 'blur(10px)', // Adds a subtle blur effect for glass-like appearance
            border: `1px solid ${theme.palette.divider}`,
          },
        }}
        TransitionProps={{
          timeout: {
            enter: 300,
            exit: 300,
          },
          // Keep the element in DOM briefly after closing for smooth exit animation
          addEndListener: (node, done) => {
            node.addEventListener('transitionend', done, false);
          },
        }}
        style={{
          pointerEvents: open ? 'auto' : 'none',
          transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out', // Smoother transition
          opacity: open ? 1 : 0,
          transform: open ? 'scale(1)' : 'scale(0.95)', // Subtle scaling effect
        }}
      >
        <Stack
          direction='row'
          spacing={0}
          sx={{
            '& .MuiButtonBase-root': {
              width: '56px', // Slightly smaller than before
              height: '56px',
              padding: 1,
            },
            borderRadius: 3,
            // overflow: 'hidden', // Keep content within rounded corners
          }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{
                mx: 0,
                opacity: 0.6, // Subtler divider
              }}
            />
          }
        >
          <Tooltip title={t('CONTEXT_MENU_HIGHLIGHT_OR_ADD_NOTE')}>
            <IconButton onClick={(e: any) => handleColorSelect(e)} aria-label='highlight' sx={buttonSx}>
              <CircleIcon
                style={{
                  margin: buttonMargin,
                  color: lastColor || '#ffff00',
                  fontSize: buttonSize,
                  filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.2))', // Subtle shadow
                }}
              />
            </IconButton>
          </Tooltip>
          {isOnline && (
            <Tooltip title={t('CONTEXT_MENU_LOOKUP_DICTIONARY')}>
              <IconButton onClick={handleDictionaryOpen} aria-label='dictionary' sx={buttonSx}>
                <AutoStoriesIcon sx={standardIconSx} />
              </IconButton>
            </Tooltip>
          )}
          {isOnline && bookLanguage.toLowerCase().startsWith('eo') && (
            <Tooltip title={t('CONTEXT_MENU_LOOKUP_REVO')}>
              <IconButton onClick={handleRevoDictionaryOpen} aria-label='revo' sx={buttonSx}>
                <TitleIcon sx={standardIconSx} />
              </IconButton>
            </Tooltip>
          )}
          {meta.languageCode?.toLowerCase().slice(0, 2) !== languageCode && isOnline && (
            <Tooltip title={t('CONTEXT_MENU_AI_TRANSLATE')}>
              <IconButton onClick={() => handleGeepersOpen('translate')} aria-label='translate' sx={buttonSx}>
                <TranslateIcon sx={standardIconSx} />
              </IconButton>
            </Tooltip>
          )}
          {isOnline && (bookLanguage.startsWith('fr') || bookLanguage.startsWith('eo')) && (
            <Tooltip title={t('CONTEXT_MENU_AI_EXPLAIN_DIFFERENCE')}>
              <IconButton
                onClick={() =>
                  handleGeepersOpen(
                    bookLanguage.startsWith('fr') ? 'french' : bookLanguage.startsWith('eo') ? 'esperanto' : 'magic'
                  )
                }
                aria-label='difference'
                sx={buttonSx}
              >
                <CompareArrowsIcon sx={standardIconSx} />
              </IconButton>
            </Tooltip>
          )}
          <div ref={foldRef} style={{ position: 'relative' }}>
            <IconButton
              ref={magicButtonRef}
              aria-label='magic'
              style={{ touchAction: 'none' }}
              disableRipple={true}
              sx={getMagicButtonSx('magic')}
              onPointerDown={handleMagicPointerDown}
            >
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <AssistantIcon sx={magicIconSx} />
                <ArrowDropDownIcon
                  sx={{
                    position: 'absolute',
                    bottom: -8,
                    right: -8,
                    fontSize: 16,
                    color: theme.palette.primary.main,
                    opacity: theme.palette.mode === 'dark' ? 0.9 : 0.8, // Slightly more visible in dark mode
                  }}
                />
              </Box>
            </IconButton>
            <Grow in={isLongPress} mountOnEnter unmountOnExit timeout={250}>
              <Paper
                elevation={8} // Higher elevation for drop-down menu
                sx={{
                  position: 'absolute',
                  top: 'calc(100% - 4px)', // Slight overlap for visual connection
                  left: 0,
                  zIndex: 1, // behind the magic button so it appears to roll out from behind it
                  borderRadius: 3,
                  backdropFilter: 'blur(10px)', // Matching glass effect
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'rgba(30, 30, 30, 0.95)' // Slightly translucent darker background in dark mode
                      : theme.palette.background.paper,
                  border: `1px solid ${
                    theme.palette.mode === 'dark'
                      ? theme.palette.divider // Normal divider in light mode
                      : theme.palette.divider
                  }`,
                  width: '56px', // Match button width
                  boxShadow:
                    theme.palette.mode === 'dark'
                      ? '0px 5px 15px rgba(0, 0, 0, 0.5)' // Stronger shadow in dark mode
                      : theme.shadows[8],
                  transform: 'translateZ(0)', // Force hardware acceleration
                  animation: isLongPress ? 'fadeIn 0.25s ease-out' : 'none',
                  '@keyframes fadeIn': {
                    '0%': {
                      opacity: 0,
                      transform: 'translateY(-8px)',
                    },
                    '100%': {
                      opacity: 1,
                      transform: 'translateY(0)',
                    },
                  },
                }}
              >
                <Stack
                  direction='column'
                  spacing={0}
                  sx={{
                    p: 0,
                    '& .MuiIconButton-root': {
                      borderRadius: 0, // Square buttons for menu items
                      height: '56px',
                      width: '56px',
                    },
                  }}
                >
                  <Tooltip title={t('CONTEXT_MENU_AI_MAGIC_BRIEF')}>
                    <IconButton
                      ref={extraButtonRefMagicBrief}
                      aria-label='magic_brief'
                      sx={{
                        ...getMagicButtonSx('magic_brief'),
                        borderTopLeftRadius: '12px',
                        borderTopRightRadius: '12px',
                      }}
                    >
                      <AutoFixOffIcon
                        sx={{
                          ...dropdownIconSx,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Divider sx={{ opacity: theme.palette.mode === 'dark' ? 0.3 : 0.6 }} />
                  <Tooltip title={t('CONTEXT_MENU_AI_MAGIC_DETAILED')}>
                    <IconButton
                      ref={extraButtonRefMagicDetailed}
                      aria-label='magic_detailed'
                      sx={getMagicButtonSx('magic_detailed')}
                    >
                      <AutoFixNormalIcon
                        sx={{
                          ...dropdownIconSx,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Divider sx={{ opacity: theme.palette.mode === 'dark' ? 0.3 : 0.6 }} />
                  <Tooltip title={t('CONTEXT_MENU_AI_MAGIC_COMPREHENSIVE')}>
                    <IconButton
                      ref={extraButtonRefMagicComprehensive}
                      aria-label='magic_comprehensive'
                      sx={{
                        ...getMagicButtonSx('magic_comprehensive'),
                        borderBottomLeftRadius: '12px',
                        borderBottomRightRadius: '12px',
                      }}
                    >
                      <AutoFixHighIcon
                        sx={{
                          ...dropdownIconSx,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Paper>
            </Grow>
          </div>
        </Stack>
      </Dialog>
      <DictionaryView open={dictionaryOpen} onClose={handleDictionaryClose} word={text} />
      <WiktionaryView open={wiktionaryOpen} onClose={handleWiktionaryClose} word={text} languageCode={bookLanguage} />
      <RevoDictionaryView open={revoDictionaryOpen} onClose={handleRevoDictionaryClose} word={text} />
      <Geepers
        open={geepersOpen}
        onClose={handleGeepersClose}
        text={text}
        context={context}
        command={geepersCommand}
        meta={meta}
        cfiRange={cfiRange}
        addNote={addNote}
        getContextFunction={getContextFunction}
        getTranslationFunction={getTranslationFunction}
        selection={selection}
        selectStartParagraphIndex={selectStartParagraphIndex}
        selectEndParagraphIndex={selectEndParagraphIndex}
        languageCode={languageCode || navigator.language.slice(0, 2).toLowerCase() || 'en'}
      />
    </>
  );
}
