import type { CSSProperties } from 'react';

export interface IReaderStyle {
  [key: string]: CSSProperties;
}

const baseStyle: IReaderStyle = {
  container: {
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
  },
  readerArea: {
    position: 'relative',
    zIndex: 1,
    height: '100%',
    width: '100%',
    transition: 'all .3s ease',
  },

  titleArea: {
    position: 'absolute',
    top: 0,
    left: 50,
    right: 50,
    textAlign: 'center',
    color: '#999',
  },
  reader: {
    position: 'absolute',
    top: '0px',
    left: 0,
    bottom: '0px',
    right: 0,
  },
  prev: {
    left: 1,
  },
  next: {
    right: 1,
  },
  arrow: {
    outline: 'none',
    border: 'none',
    background: 'none',
    position: 'absolute',
    top: '50%',
    marginTop: -32,
    fontSize: 64,
    padding: '0 10px',
    cursor: 'pointer',
    userSelect: 'none',
    appearance: 'none',
    fontWeight: 'normal',
  },
  arrowHover: {
    color: '#777',
  },
  tocArea: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 0,
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    padding: '10px 0',
  },
  tocAreaButton: {
    userSelect: 'none',
    appearance: 'none',
    background: 'none',
    border: 'none',
    display: 'block',
    fontFamily: 'sans-serif',
    width: '100%',
    fontSize: '.9em',
    textAlign: 'left',
    padding: '.9em 1em',
    boxSizing: 'border-box',
    outline: 'none',
    cursor: 'pointer',
  },
  tocButton: {
    background: 'none',
    border: 'none',
    width: 32,
    height: 32,
    position: 'absolute',
    top: 10,
    left: 10,
    borderRadius: 2,
    outline: 'none',
    cursor: 'pointer',
  },
  tocButtonExpanded: {},
  tocButtonBar: {
    position: 'absolute',
    height: 2,
    left: '50%',
    // margin: "-1px -30%",
    top: '50%',
    transition: 'all .5s ease',
  },
  tocButtonBarTop: {
    top: '35%',
  },
  tocButtonBottom: {
    top: '66%',
  },
  loadingView: {
    position: 'absolute',
    top: '300px',
    left: '100px',
    textAlign: 'center',
    // color: "#999",
    // backgroundColor: "#333",
    fontSize: '5em',
    zIndex: 1000,
    // marginTop: "-.5em",
  },
  drawer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    transform: 'translateX(-100%)',
    transition: 'transform 0.3s ease-in-out',
    zIndex: 50,
  },
  drawerExpanded: {
    transform: 'translateX(0)',
  },
  readerAreaExpanded: {
    transform: 'translateX(20rem)',
  },
};

const defaultStyles: { [name: string]: IReaderStyle } = {
  light: {
    ...baseStyle,
    readerArea: {
      ...baseStyle.readerArea,
      backgroundColor: '#fff',
      color: '#000',
    },
    tocArea: {
      ...baseStyle.tocArea,
      background: '#f2f2f2',
    },
    tocAreaButton: {
      ...baseStyle.tocAreaButton,
      //   borderBottom: "1px solid #ddd",
      color: '#aaa',
    },
    tocButtonExpanded: {
      ...baseStyle.tocButtonExpanded,
      background: '#f2f2f2',
    },
    tocButtonBar: {
      ...baseStyle.tocButtonBar,
      background: '#ccc',
    },
    loadingView: {
      ...baseStyle.loadingView,
      color: '#333',
      backgroundColor: '#bbb',
    },
    arrow: {
      ...baseStyle.arrow,
      color: '#E2E2E2',
    },
  },
  dark: {
    ...baseStyle,
    // ".wr-hl": { mixBlendMode: "lighten" },
    readerArea: {
      ...baseStyle.readerArea,
      backgroundColor: '#000000',
      //   transition: "transform 0.3s ease",
      color: '#ddd',
    },
    tocArea: {
      ...baseStyle.tocArea,
      background: '#111',
    },
    tocAreaButton: {
      ...baseStyle.tocAreaButton,
      //   borderBottom: "1px solid #222",
      color: '#555',
    },
    tocButtonExpanded: {
      ...baseStyle.tocButtonExpanded,
      background: '#111',
    },
    tocButtonBar: {
      ...baseStyle.tocButtonBar,
      background: '#666',
    },
    loadingView: {
      ...baseStyle.loadingView,
      display: 'block',
      position: 'absolute',
      color: '#bbb',
      fontSize: '36px',
      textAlign: 'center',
      width: '100%',
      top: '50%',
      backgroundColor: '#333',
    },
    arrow: {
      ...baseStyle.arrow,
      color: '#111',
    },
  },
};

export { defaultStyles };
